import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Link as RouteLink,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { ScrollDownIndicator } from "react-landing-page";
import Iframe from "react-iframe";

import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Typing from "react-typing-animation";
import { Container, Row, Col } from "react-bootstrap";
import CookieConsent, { Cookies } from "react-cookie-consent";

const bytsColor = "#E55D3D";
const bytsTextColor = "#fadfd9";
const semiDarkBytsColor = "#f1ad93";

const Landing = ({ login, isAuthenticated }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("useEffect Landing Page");
  }, []);
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const scrollTo = (id) => (e) => {
    e.preventDefault();
    console.log("id", id);
    scroll.scrollTo({
      duration: 1500,
      delay: 100,
      smooth: "easeInOutQuint",
      containerId: id,
    });
  };

  return (
    <div id="home">
      <CookieConsent
        cookieName="cookieConsentAlert"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        acceptOnScroll={true}
        onAccept={({ acceptedByScrolling }) => { }}
      >
        This website uses cookies to enhance your experience on our site. To
        find out more, read our updated{" "}
        <a href="https://byts.co.in/privacypolicy">Privacy Policy</a> here.
      </CookieConsent>

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="#home">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Col style={{ paddingLeft: "0px" }}>
              <Nav className="justify-content-center">
                <Nav.Link href="#courses">Courses</Nav.Link>
                <Nav.Link href="#practise">Practise</Nav.Link>
              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <RouteLink className="btn btn-outline-dark m-1" to="/login">
                  Login
                </RouteLink>
                <RouteLink className="btn btn-outline-dark m-1" to="/register">
                  Register
                </RouteLink>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>

      <div
        className=""
        style={{
          display: "inline-block",
          paddingTop: "0px",
          backgroundColor: bytsColor,
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        <div className="container bodyz">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <p
                className="display-1 h1-text"
                style={{
                  color: "#fadfd9",
                  fontFamily: "Courier",
                  letterSpacing: "-2px",
                }}
              >
                We don't just Teach You Coding!
              </p>
              <p
                className="display-3 h3-text"
                style={{
                  color: "#000000",
                  fontSize: "30px",
                }}
              >
                We will help you learn it in a better way and make you win your
                dream career.
              </p>
            </div>
            <div className="col">
              <div className="row-12 row-sm-12 row-md-12">
                <img
                  style={{}}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing1.png"
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        style={{
          display: "inline-block",
          backgroundColor: "#F5F5F5",
          paddingTop: "25px",
          paddingBottom: "30px",
          width: "100%",
        }}
      >
        <div
          className="container bodyz"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div className="row align-items-center">
            <div className="col-2" xs="auto">
              <p style={{ color: "#A8A8AA" }} className="text-center brackets">
                {"{"}
              </p>
            </div>
            <div className="col-8" xs="auto" style={{ paddingTop: "5px" }}>
              <h1
                className="brackets-text text-center"
                style={{ color: bytsColor }}
              >
                World's first platform where Learning & Practise Module comes
                together
              </h1>
            </div>{" "}
            <div className="col-2 text-center" xs="auto">
              <p style={{ color: "#A8A8AA" }} className="text-center brackets">
                {"}"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container bodyz pb-5"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
        data-aos-duration="200"
      >
        <div
          style={{
            display: "inline-block",
            paddingTop: "25px",
            paddingBottom: "30px",
            width: "100%",
          }}
        >
          <div
            className="container bodyz align-items-center"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-sine"
            data-aos-once="true"
            data-aos-duration="200"
          >
            <div className="row align-items-center">
              <div className="col-12 align-items-center">
                <div className="row align-items-center border border-dark">
                  <div className="col-3 text-right" xs="auto">
                    <p className="ang-brackets " style={{ color: bytsColor }}>
                      {"<"}
                    </p>
                  </div>
                  <div className="col-6" xs="auto">
                    <h1 className="ang-text text-center">
                      Why we are different from others?
                    </h1>
                  </div>{" "}
                  <div className="col-3 text-left" xs="auto">
                    <p className="ang-brackets" style={{ color: bytsColor }}>
                      {">"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="col align-items-center">
            <div className="pb-2 row align-items-center">
              <div className="m-auto col-12 col-sm-12 col-md-5 col-lg-5 align-items-center order-md-1 order-3">
                <p
                  className="ang-text"
                  style={{
                    color: bytsColor,
                  }}
                >
                  Learn from scratch
                </p>
                <p
                  className="fun-text font-weight-normal"
                  style={{
                    color: "#000000",
                  }}
                >
                  Real programmers don't just learn programming. They will make
                  it as their DNA.
                </p>
              </div>
              <div className="col-10 col-sm-6 m-auto col-md-4 pt-4 pt-md-0 col-sm-pull-12 order-md-2 order-1">
                <img
                  //style={{ paddingTop: "200px" }}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing2.png"
                />
              </div>
              <div
                className="m-auto order-md-3 order-2"
                style={{
                  width: "80%",
                  height: "10px",
                  backgroundColor: semiDarkBytsColor,
                }}
              ></div>
            </div>

            <div className="pb-2 row align-items-center">
              <div className="col-10 col-sm-6 m-auto col-md-4 pt-4 pt-md-0 col-sm-pull-12 order-md-1 order-1">
                <img
                  //style={{ paddingTop: "200px" }}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing2.png"
                />
              </div>
              <div className="m-auto col-12 col-sm-12 col-md-5 col-lg-5 align-items-center order-md-2 order-3">
                <p
                  className="ang-text"
                  style={{
                    color: bytsColor,
                  }}
                >
                  Objective based Learning
                </p>
                <p
                  className="fun-text font-weight-normal"
                  style={{
                    color: "#000000",
                  }}
                >
                  Real programmers don't just learn programming. They will make
                  it as their DNA.
                </p>
              </div>

              <div
                className="m-auto order-md-3 order-2"
                style={{
                  width: "80%",
                  height: "10px",
                  backgroundColor: semiDarkBytsColor,
                }}
              ></div>
            </div>
            <div className="pb-2 row align-items-center">
              <div className="m-auto col-12 col-sm-12 col-md-5 col-lg-5 align-items-center order-md-1 order-3">
                <p
                  className="ang-text"
                  style={{
                    color: bytsColor,
                  }}
                >
                  Code while you learn
                </p>
                <p
                  className="fun-text font-weight-normal"
                  style={{
                    color: "#000000",
                  }}
                >
                  Real programmers don't just learn programming. They will make
                  it as their DNA.
                </p>
              </div>
              <div className="col-10 col-sm-6 m-auto col-md-4 pt-4 pt-md-0 col-sm-pull-12 order-md-2 order-1">
                <img
                  //style={{ paddingTop: "200px" }}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing2.png"
                />
              </div>
              <div
                className="m-auto order-md-3 order-2"
                style={{
                  width: "80%",
                  height: "10px",
                  backgroundColor: semiDarkBytsColor,
                }}
              ></div>
            </div>

            <div className="pb-2 row align-items-center">
              <div className="col-10 col-sm-6 m-auto col-md-4 pt-4 pt-md-0 col-sm-pull-12 order-md-1 order-1">
                <img
                  // style={{ paddingTop: "200px" }}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing2.png"
                />
              </div>
              <div className="m-auto col-12 col-sm-12 col-md-5 col-lg-5 align-items-center order-md-2 order-3">
                <p
                  className="ang-text "
                  style={{
                    color: bytsColor,
                  }}
                >
                  Real Time Examples (Usecases)
                </p>
                <p
                  className="fun-text font-weight-normal"
                  style={{
                    color: "#000000",
                  }}
                >
                  Real programmers don't just learn programming. They will make
                  it as their DNA.
                </p>
              </div>

              <div
                className="m-auto order-md-3 order-2"
                style={{
                  width: "80%",
                  height: "10px",
                  backgroundColor: semiDarkBytsColor,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className=""
        style={{
          display: "inline-block",
          paddingTop: "80px",
          backgroundColor: bytsColor,
          width: "100%",
          paddingBottom: "80px",
        }}
      >
        <div className="container bodyz">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <p
                className="display-1 h1-text"
                style={{
                  color: "#fadfd9",
                }}
              >
                Curriculum that wins you Jobs
              </p>
              <p
                className="display-3 h3-text"
                style={{
                  color: "#000000",
                  fontSize: "30px",
                }}
              >
                We will help you learn it in a better way and make you win your
                dream career.
              </p>
            </div>
            <div className="col">
              <div className="row-12 row-sm-12 row-md-12">
                <img
                  //style={{ paddingTop: "300px" }}
                  alt="image"
                  className="img-fluid rounded-0"
                  src="./imgs/landing/landing3.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="fdb-block py-0 bodyz"
        style={{
          backgroundColor: "#f2f6fd",
        }}
      >
        <div
          className="container"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
          style={{
            paddingBottom: "80px",
            paddingTop: "40px",
          }}
        >
          <div className="row text-left mt-5">
            <div
              className="col-12 col-md-4"
              data-aos="zoom-in-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px" }}
                    alt="image"
                    className="rounded-0 align-items-center mx-auto"
                    src="./imgs/landing/landing8.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>
                    <strong>CUSTOMIZED LEARNING</strong>
                  </h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0"
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px", paddingLeft: "10px" }}
                    alt="image"
                    className="img-fluid rounded-0"
                    src="./imgs/landing/landing4.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>
                    <strong>CUSTOMIZED LEARNING</strong>
                  </h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0"
              data-aos="zoom-in-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px" }}
                    alt="image"
                    className="img-fluid rounded-0"
                    src="./imgs/landing/landing8.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>
                    <strong>CUSTOMIZED LEARNING</strong>
                  </h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-left pt-3 pt-sm-4 pt-md-5">
            <div
              className="col-12 col-md-4"
              data-aos="zoom-in"
              data-aos-offset="210"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px" }}
                    alt="image"
                    className="img-fluid rounded-0"
                    src="./imgs/landing/landing6.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>
                    <strong>CUSTOMIZED LEARNING</strong>
                  </h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0"
              data-aos="zoom-in"
              data-aos-offset="210"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px" }}
                    alt="image"
                    className="img-fluid rounded-0"
                    src="./imgs/landing/landing4.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>
                    <strong>CUSTOMIZED LEARNING</strong>
                  </h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0"
              data-aos="zoom-in"
              data-aos-offset="210"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col">
                <div className="row-9 text-center pt-3">
                  <img
                    style={{ paddingTop: "0px", width: "auto", height: "100px" }}
                    alt="image"
                    className="img-fluid rounded-0"
                    src="./imgs/landing/landing7.png"
                  />
                </div>
                <div className="row-9 text-center pt-3">
                  <h3>CUSTOMIZED LEARNING</h3>
                  <p className="font-weight-normal">
                    Detailed videos ranging from Programming to Algorithms and
                    Data Structures
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        style={{
          display: "inline-block",
          backgroundColor: bytsColor,
          paddingTop: "25px",
          paddingBottom: "30px",
          width: "100%",
        }}
      >
        <div
          id="courses"
          className="container bodyz"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div className="row align-items-center">
            <div
              className="col-12 m-auto"
              xs="auto"
              style={{ paddingTop: "5px" }}
            >
              <h1
                className="brackets-text text-center"
                style={{ color: semiDarkBytsColor }}
              >
                Real Programmers don't just Learn Programming.
                <br />
                They will make it as their DNA.
              </h1>
            </div>{" "}
          </div>
        </div>
      </div>

      <section

        className="fdb-block py-0 bodyz"
        style={{ paddingTop: "0px", backgroundColor: "#f0f0f0" }}
      >
        <div
          className="container"
          style={{
            paddingTop: "0px",
            marginTop: "0px",
          }}
          data-aos="fade-in"
          data-aos-offset="290"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div
            style={{
              display: "inline-block",
              paddingTop: "25px",
              paddingBottom: "30px",
              width: "100%",
            }}
          >
            <div
              className="container bodyz align-items-center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="row align-items-center">
                <div className="col-12 align-items-center">
                  <div className="row align-items-center ">
                    <div className="col-3 text-right" xs="auto">
                      <p className="ang-brackets ">{"<"}</p>
                    </div>
                    <div className="col-6" xs="auto">
                      <h1
                        className="ang-text text-center"
                        style={{ color: bytsColor }}
                      >
                        What can you learn with us?
                      </h1>
                    </div>{" "}
                    <div className="col-3 text-left" xs="auto">
                      <p className="ang-brackets">{">"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row card-font">
            <div class="col-md-3 pb-5 ">
              <div class="card mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2 shadow">
                <img
                  class="card-img-top"
                  src="./imgs/landing/landing11.png"
                  alt="Card image cap"
                />
                <div class="card-block">
                  <div className="block" style={{ backgroundColor: bytsColor }}>
                    <div className="px-2 py-1 container align-items-center">
                      <div class="d-flex row-hl card-brackets">
                        <div class="mr-auto px-2 item-hl">{"<"}</div>
                        <div class="px-2 item-hl">{">"}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-text">
                    <p className="pt-3 font-weight-normal mx-4">C</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">C++</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Java</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Python</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4 pb-3">DSA</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-4 ">
              <div class="card mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2 shadow">
                <img
                  class="card-img-top"
                  src="./imgs/landing/landing12.png"
                  alt="Card image cap"
                />
                <div class="card-block">
                  <div className="block" style={{ backgroundColor: bytsColor }}>
                    <div className="px-2 py-1 container align-items-center">
                      <div class="d-flex row-hl card-brackets">
                        <div class="mr-auto px-2 item-hl">{"<"}</div>
                        <div class="px-2 item-hl">{">"}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-text">
                    <p className="pt-3 font-weight-normal mx-4">Operating Systems</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Computer Networks</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Computer Organisation</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Computer Architecture</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4 pb-3">DBMS</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-4">
              <div class="card mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2 shadow">
                <img
                  class="card-img-top"
                  src="./imgs/landing/landing13.png"
                  alt="Card image cap"
                />
                <div class="card-block">
                  <div className="block" style={{ backgroundColor: bytsColor }}>
                    <div className="px-2 py-1 container align-items-center">
                      <div class="d-flex row-hl card-brackets">
                        <div class="mr-auto px-2 item-hl">{"<"}</div>
                        <div class="px-2 item-hl">{">"}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-text">
                    <p className="pt-3 font-weight-normal mx-4">Analog Electronics</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Digital Electronics</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Network Analysis</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Analog Circuits</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4 pb-3">MPMC</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 pb-4 ">
              <div class="card mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2 shadow">
                <img
                  class="card-img-top"
                  src="./imgs/landing/landing14.png"
                  alt="Card image cap"
                />
                <div class="card-block">
                  <div className="block" style={{ backgroundColor: bytsColor }}>
                    <div className="px-2 py-1 container align-items-center">
                      <div class="d-flex row-hl card-brackets">
                        <div class="mr-auto px-2 item-hl">{"<"}</div>
                        <div class="px-2 item-hl">{">"}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-text">
                    <p className="pt-3 font-weight-normal mx-4">HTML 5/CSS3</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">Javascript</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">AngularJS</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4">NodeJS</p>
                    <hr
                      className="p-0 mx-3 my-0"
                      style={{ borderColor: bytsColor }}
                    />
                    <p className="pt-3 font-weight-normal mx-4 pb-3">ReactJS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fdb-block py-0 bodyz" style={{ paddingTop: "0px" }}>
        <div
          className="container"
          style={{
            paddingTop: "0px",
            marginTop: "0px",
          }}
          data-aos="fade-in"
          data-aos-offset="290"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div
            style={{
              display: "inline-block",
              paddingTop: "25px",
              paddingBottom: "15px",
              width: "100%",
            }}
          >
            <div
              className="container bodyz align-items-center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-once="true"
              data-aos-duration="200"
            >
              <div className="col align-items-center">
                <div className="row-12" xs="auto">
                  <h1 className="end-text text-center">
                    Our students say the nicest things!
                  </h1>
                </div>{" "}
                <div className="row-12" xs="auto">
                  <h1 className="end-text-light text-center">
                    Here's what our happy students have to say about us.
                  </h1>
                </div>{" "}
                <hr
                  className="mt-4 mb-0 mx-3 "
                  style={{ borderColor: "#e0e0e0" }}
                />
              </div>
            </div>
          </div>

          <div class="row card-font">
            <div class="col-md-3 pb-5 ">
              <div class=" mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2">
                <div class="">
                  <div class="align-items-center text-center">
                    <p className="pt-3 mx-auto text-center font-weight-normal">
                      Sir. Your materials helped me to get a job at Deshaw. Thank you.
                    </p>

                    <b className="pt-3 mx-auto text-center">- Purushothaman R</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-5 ">
              <div class=" mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2">
                <div class="">
                  <div class="align-items-center text-center">
                    <p className="pt-3 mx-auto text-center font-weight-normal">
                      All the problems helped me to crack the coding rounds at TCS, Infosys and Cognizant. The course contents were in a structured manner Sir.
                    </p>

                    <b className="pt-3 mx-auto text-center">- Meghna S</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-5 ">
              <div class=" mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2">
                <div class="">
                  <div class="align-items-center text-center">
                    <p className="pt-3 mx-auto text-center font-weight-normal">
                      Thanks for the course Sir. It has helped me to land at Google India.
                    </p>

                    <b className="pt-3 mx-auto text-center">- Rajasundaram K</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 pb-5 ">
              <div class=" mx-lg-2 mx-xs-5 mx-sm-1 mx-md-2">
                <div class="">
                  <div class="align-items-center text-center">
                    <p className="pt-3 mx-auto text-center font-weight-normal">
                      This helped me to master my Python skills. Thank You Sir.
                    </p>

                    <b className="pt-3 mx-auto text-center">- Premkumar B</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="fdb-block footer-large bg-dark bodyz">
        <div className="container">
          <div className="row align-items-top text-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 text-sm-left">
              <h3>
                <strong>Quicklinks</strong>
              </h3>
              <nav className="nav flex-column">
                <a className="nav-link active" href="">
                  Home
                </a>
                <a className="nav-link" href="">
                  Features
                </a>
                <a className="nav-link" href="">
                  Pricing
                </a>
                <a className="nav-link" href="">
                  Team
                </a>
                <a className="nav-link" href="">
                  Contact Us
                </a>
              </nav>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-5 mt-sm-0 text-sm-left">
              <h3>
                <strong>Quicklinks</strong>
              </h3>
              <nav className="nav flex-column">
                <a className="nav-link active" href="/privacypolicy">
                  Privacy Policy
                </a>
                <a className="nav-link" href="">
                  Terms
                </a>
                <a className="nav-link" href="">
                  FAQ
                </a>
                <a className="nav-link" href="">
                  Support
                </a>
              </nav>
            </div>

            <div className="col-12 col-md-4 col-lg-3 text-md-left mt-5 mt-md-0">
              <h3>
                <strong>About Us</strong>
              </h3>
              <p className="font-weight-normal" style={{ color: "#ffffff" }}>
                We are a passionate group of Tech Enthusiasts with the motto of
                making Programming and Logical Thinking accessible to interested
                students all over the world.
              </p>
            </div>

            <div className="col-12 col-lg-2 ml-auto text-lg-left mt-4 mt-lg-0">
              <h3>
                <strong>Follow Us</strong>
              </h3>
              <h3>
                <a href="https://www.facebook.com/bytsindia/" className="mx-2">
                  <i className="fab fa-facebook" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.instagram.com/byts_india/"
                  className="mx-2"
                >
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </h3>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col text-center">
              © 2020 Byts. All Rights Reserved
            </div>
          </div>
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h2>
                  Made with <i className="fas fa-heart text-danger"></i> by Byts
                </h2>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

Landing.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Landing);
