export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_DONE = "LOGOUT_DONE";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const GSIGNIN_SUCCESS = "GSIGNIN_SUCCESS";
export const GSIGNIN_FAIL = "GSIGNIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";

export const GET_SCHEMA = "GET_SCHEMA";
export const SCHEMA_ERROR = "SCHEMA_ERROR";
export const GET_TOPIC = "GET_TOPIC";
export const TOPIC_ERROR = "TOPIC_ERROR";
export const GET_PROBLEMS = "GET_PROBLEMS";
export const GET_PROBLEMS_ERROR = "GET_PROBLEMS_ERROR";
export const PROBLEMS_TAGS = "PROBLEMS_TAGS";
export const GET_SUBTOPIC = "GET_SUBTOPIC";
export const SUBTOPIC_ERROR = "SUBTOPIC_ERROR";

export const GET_PROBLEM = "GET_PROBLEM";
export const GET_PROBLEM_ERROR = "GET_PROBLEM_ERROR";
export const UPDATE_PROBLEM = "UPDATE_PROBLEM";
export const UPDATE_PROBLEM_ERROR = "UPDATE_PROBLEM_ERROR";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const UPDATE_PROGRESS_ERROR = "UPDATE_PROGRESS_ERROR";
export const CLEAR_PROGRESS = "CLEAR_PROGRESS";

export const UPDATE_INPROGRESS = "UPDATE_INPROGRESS";
export const UPDATE_INPROGRESS_ERROR = "UPDATE_INPROGRESS_ERROR";

export const GET_PROGRESS = "GET_PROGRESS";
export const GET_PROGRESS_ERROR = "GET_PROGRESS_ERROR";

export const SAVE_TOPIC = "SAVE_TOPIC";
export const SAVE_SUBTOPIC = "SAVE_SUBTOPIC";
export const SAVE_ASSET = "SAVE_ASSET";

export const START_LOADER = "START_LOADER";
export const END_LOADER = "END_LOADER";

export const RESEND_MAIL = "RESEND_MAIL";
export const RESEND_MAIL_ERROR = "RESEND_MAIL_ERROR";

export const FP_MAIL = "FP_MAIL";
export const FP_MAIL_ERROR = "FP_MAIL_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

// settings related alerts
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SETTINGS_ERROR = "SET_SETTINGS_ERROR";

export const CLEAR_EXECUTE_CODE = "CLEAR_EXECUTE_CODE";
export const EXECUTE_CODE = "EXECUTE_CODE";
export const EXECUTE_CODE_ERROR = "EXECUTE_CODE_ERROR";

export const RESET_CODE = "RESET_CODE";
export const RESET_CODE_ERROR = "RESET_CODE_ERROR";

// anaytics 
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const ADD_FEEDBACK_ERROR = "ADD_FEEDBACK_ERROR";

export const GET_PRICINGPLANS = "GET_PRICINGPLANS";
export const GET_PRICINGPLANS_ERROR = "GET_PRICINGPLANS_ERROR";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR";

export const SET_ALERT_PLAIN = "SET_ALERT_PLAIN";
export const REMOVE_ALERT_PLAIN = "REMOVE_ALERT_PLAIN";