import uuid from 'uuid';
import { SET_ALERT_PLAIN, REMOVE_ALERT_PLAIN } from './types';

export const setAlert = (msg, alertType, timeout = 2000) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT_PLAIN,
    payload: { msg, alertType, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT_PLAIN, payload: id }), timeout);
};
