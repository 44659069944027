import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { loadUser } from "../../actions/auth";
import { getOrderId } from "../../actions/payment";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import { Nav, NavDropdown, Navbar, Container, Row, Col, Badge } from "react-bootstrap";
import { setAlert } from "../../actions/alert";
import { Dropdown, Divider, Header, Icon, Table } from 'semantic-ui-react'

import Avatar from 'react-avatar';

//const BYTS_URL = "http://192.168.0.107:5001";
const BYTS_URL = "https://byts.co.in";

const bytsColor = "#E55D3D";


const PlanDetailsTable = ({ price, validity, contents }) => (
  <React.Fragment>
    <Divider horizontal>
      <Header as='h4'>
        <Icon name='tag' />
        Plan Details
      </Header>
    </Divider>



    <Table definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={2}>Price</Table.Cell>
          <Table.Cell>₹{price}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Validity Duration</Table.Cell>
          <Table.Cell>{validity}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Course Contents</Table.Cell>
          <Table.Cell>{contents && contents.length > 0 && contents.map((content, key) => {
            return <Fragment>{content}{key != (contents.length - 1) ? ", " : ""}</Fragment>;
          })}</Table.Cell>
        </Table.Row>

      </Table.Body>
    </Table>
  </React.Fragment>
)

const PaymentPage = ({
  deleteAccount,
  auth: { user },
  payment: { order_id },
  schema: { schema, loading },
  payment: { order, loadingOrder },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  loadUser,
  getOrderId,
  match,
  setAlert,
  history
}) => {


  const [formData, setFormData] = useState({
    payment_amount: 0,
    refund_id: "",
    selectedPlan: -1,
    activePlan: 0,
    paymentSucceeded: false

  });

  const { payment_amount, refund_id, selectedPlan,
    activePlan, paymentSucceeded } = formData;

  useEffect(() => {
    loadUser();
    getOrderId({ type: match.params.type, userId: user._id });


  }, [getOrderId]);

  const handleBasicClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 1 });
  }

  const handleIntensiveClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 2 });

  }


  const paymentHandler = (e) => {
    e.preventDefault();


    var planId = (order && order.planInfo && order.planInfo.planId ? order.planInfo.planId : null);
    var price = (order && order.planInfo && order.planInfo.price ? order.planInfo.price : null);
    var planTitle = (order && order.planInfo && order.planInfo.title ? order.planInfo.title : null);
    var planContentsArray = order && order.planInfo && order.planInfo.contents;
    var planContents = "";

    planContentsArray.forEach((planContent, idx) => {
      planContents = planContents + planContent;
      if (idx != planContentsArray.length - 1) {
        planContents = planContents + ", "
      }
    });


    if (!planId || !price || !planTitle || !planContents) {
      setAlert(
        "Unable to get the necessary IDs. If the problem persists, please contact Support!",
        "error"
      );
      return;
    }

    const options = {
      key: "rzp_test_k3NHLDeuaZHqiK",
      amount: price * 100,
      name: planTitle,
      description: planContents,
      image:
        "https://www.byts.co.in/imgs/bytsLogo.png",
      order_id: order.id,
      handler(response) {
        const paymentId = response.razorpay_payment_id;
        const razorpayOrderId = response.razorpay_order_id;
        const razorpaySignature = response.razorpay_signature;
        const url =
          BYTS_URL +
          "/api/payment/capture/" + planId + "/" +
          paymentId +
          "/200/" + razorpayOrderId + "/" + razorpaySignature + "/" + user._id;
        // Using my server endpoints to capture the payment
        fetch(url, {
          method: "get",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          }
        })
          .then(resp => resp.json())
          .then(function (data) {
            console.log("Request succeeded with JSON response", data);

            if (data["err"]) {
              setAlert("There was an issue with your Payment. Please contact Support! CODE: 1 ERROR: " + data["err"], "error");

            } else if (data["msg"]) {
              setAlert(data["msg"], "success");

              setFormData({
                ...formData,
                refund_id: response.razorpay_payment_id,
                paymentSucceeded: true
              });
            } else {
              setAlert("There was an issue with your Payment. Please contact Support! CODE: 2", "success");
            }
          })
          .catch(function (error) {
            console.log("Request failed", error);
            setAlert("There was an issue with your Payment. Please contact Support! ERROR: " + error, "error");

          });
      },

      prefill: {
        name: user.name,
        email: user.email
      },

      theme: {
        color: "#E55D3D"
      }
    };
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  }

  const refundHandler = (e) => {
    e.preventDefault();

    const { refund_id } = this.state;
    const url = process.env.URL + "/api/v1/rzp_refunds/" + refund_id;

    // Using my server endpoints to initiate the refund
    fetch(url, {
      method: "get",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    })
      .then(resp => resp.json())
      .then(function (data) {
        console.log("Request succeeded with JSON response", data);
        alert("Refund Succeeded");
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  }

  const onClickMyAccount = async e => {
    e.preventDefault();
    console.log("onClickMyAccount!");
  };

  const onClickPractise = async e => {
    e.preventDefault();
    console.log("onClickPractise!");
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  if (paymentSucceeded) {
    return <Redirect to='/paymentsucceeded' s={"sad"} />
  }

  var errored = false;
  if (!loadingOrder && order) {
    if (order["err"]) {
      errored = true;
    }
  }

  var owned = false;
  var expiryDate = null;
  if (user && user.activePlans) {
    user.activePlans.forEach(activePlan => {
      if (activePlan.planId == (order && order.planInfo && order.planInfo.planId ? order.planInfo.planId : null)) {
        owned = true;
        if (expiryDate != null) {
          if (Date.parse(activePlan.expiry) > Date.parse(expiryDate)) {
            expiryDate = activePlan.expiry;
          }
        } else {
          expiryDate = activePlan.expiry;
        }
      }
    });
  }

  return loadingOrder || order == null ? (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">

                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={e => {
                    e.preventDefault();
                    console.log("onClickBack!");
                    history.push("/payment")
                  }} className="far fa-arrow-alt-circle-left fa-2x"
                >

                </i>
              </Navbar.Collapse>


            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <br />
      <div className="bodyz">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="h2">Checkout</p>
              </div>
            </div>
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  ) : (
      <div>

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">

                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={e => {
                      e.preventDefault();
                      console.log("onClickBack!");
                      history.push("/payment")
                    }} className="far fa-arrow-alt-circle-left fa-2x"
                  >

                  </i>
                </Navbar.Collapse>


              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" >Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >

        <br />
        <br />

        <div className="bodyz">
          <div class="">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="h2">Checkout</p>
                </div>
              </div>
              <section className="fdb-block" style={{ paddingTop: "20px" }}>
                {owned ? <Container>
                  <Row className="row align-items-center text-left">

                    <p className="lead">{errored ? ("Invalid Checkout. Please refresh.") : ("You have already purchased the following plan")}</p>
                  </Row>
                  <Row style={{ height: "50px" }} className="row align-items-center text-left">
                    <h2>{errored ? ("Invalid Checkout. Please refresh.") : (order && order.planInfo && order.planInfo.title)}</h2>
                  </Row>
                  <PlanDetailsTable price={order && order.planInfo && order.planInfo.price} validity={order && order.planInfo && order.planInfo.validityString} contents={order && order.planInfo && order.planInfo.contents} />




                </Container> : <Container>
                    <Row className="row align-items-center text-left">

                      <p className="lead" >{errored ? ("Invalid Checkout. Please refresh.") : ("You have selected the following plan")}</p>
                    </Row>
                    <Row style={{ height: "50px" }} className="row align-items-center text-left">
                      <h2>{errored ? ("Invalid Checkout. Please refresh.") : (order && order.planInfo && order.planInfo.title)}</h2>
                    </Row>
                    <PlanDetailsTable price={order && order.planInfo && order.planInfo.price} validity={order && order.planInfo && order.planInfo.validityString} contents={order && order.planInfo && order.planInfo.contents} />
                    <Row>
                      <div className="col-12 col-md-3 ml-auto text-left text-md-right">
                        {errored ? <Fragment></Fragment> :
                          <RouteLink className="btn btn-outline-secondary" onClick={paymentHandler}>
                            <b>Proceed to Pay</b>
                          </RouteLink>}
                      </div>
                    </Row>



                  </Container>}
              </section> </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div >
    );
};

PaymentPage.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  getOrderId: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
  payment: state.payment
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  loadUser,
  getOrderId,
  setAlert,
  logout
})(PaymentPage);
