import axios from "axios";
import { setAlert } from "./alert";
import { SAVE_TOPIC, SAVE_SUBTOPIC, SAVE_ASSET } from "./types";
import setAuthToken from "../utils/setAuthToken";

export const saveTopic = topic => async dispatch => {
  dispatch({
    type: SAVE_TOPIC,
    payload: topic
  });
};

export const saveSubtopic = subtopic => async dispatch => {
  dispatch({
    type: SAVE_SUBTOPIC,
    payload: subtopic
  });
};

export const saveAsset = asset => async dispatch => {
  dispatch({
    type: SAVE_ASSET,
    payload: asset
  });
};
