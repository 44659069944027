import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail, getNotifications } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { addFeedback } from "../../actions/analytics";
import { setAlert } from "../../actions/alert";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import { Accordion, Card } from "react-bootstrap";
import { Container, Row, Col, Badge } from "react-bootstrap";
import Moment from "react-moment";
var compareAsc = require('date-fns/compareAsc')

const bytsColor = "#E55D3D";

const NotificationsPage = ({
  deleteAccount,
  auth: { user, notif, loadingNotif },
  schema: { schema, loading },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  addFeedback,
  setAlert,
  compareAsc,
  getNotifications

}) => {
  const [formData, setFormData] = useState({
    showFeedbackModal: false,
    feedback: "",
    testimonial: "",
    request: "",
    key: "0"
  });

  const { showFeedbackModal, feedback, testimonial, request, key } = formData;

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  return loadingNotif || notif == null ? (
    <div>


      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support" >Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px", color: bytsColor }} href="/notifications">
                  Notifications
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <Spinner />
    </div>
  ) : (
      <div>


        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" >Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support" >Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px", color: bytsColor }} href="/notifications">
                    Notifications
                </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >



        <br />
        <br />
        <div className="bodyz">
          <div class="">
            <div className="container" >
              <div className="row" >
                <div className="col-12 text-center">
                  <p className="h2">Notifications</p>
                </div>
              </div>
              <div className="fdb-block" style={{ paddingTop: "30px" }}>
                <div className="container" style={{ paddingRight: "30px" }} >

                  {notif && notif.notifs && notif.notifs.length > 0 ? notif.notifs.map(notif => (
                    <Card.Body style={{ paddingTop: "0px" }}>
                      {notif && notif.url ?
                        <RouteLink
                          onClick={() => {

                            localStorage.setItem("notifAssetId", JSON.stringify(notif.assetId));
                            localStorage.setItem("notifDate", (new Date()).toString());

                          }}
                          to={notif.url}
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          <div className="rounded shadow" style={{ backgroundColor: "#ff000011" }}>
                            <div className="row" style={{
                              paddingTop: "10px",
                              paddingLeft: "10px",
                              paddingBottom: "10px",
                              paddingRight: "10px"
                            }}>
                              <div className="col">
                                <h3 className="justify">
                                  {!notif.new ? <Fragment></Fragment> : <Badge style={{ marginRight: "7px" }} variant="danger">New</Badge>}
                                  {notif.text}
                                </h3>

                                <Moment fromNow date={notif.at} />
                              </div>
                            </div>
                          </div>
                        </RouteLink>
                        : <div className="rounded shadow" style={{ backgroundColor: "#ff000011" }}>
                          <div className="row" style={{
                            paddingTop: "10px",
                            paddingLeft: "10px",
                            paddingBottom: "10px",
                            paddingRight: "10px"
                          }}>
                            <div className="col">
                              <h3 className="justify">
                                {!notif.new ? <Fragment></Fragment> : <Badge style={{ marginRight: "7px" }} variant="danger">New</Badge>}
                                {notif.text}
                              </h3>

                              <Moment fromNow date={notif.at} />
                            </div>
                          </div>
                        </div>
                      }

                    </Card.Body>
                  )
                  ) : <h3>You have not received any notifications.</h3>}


                </div>


              </div>




            </div>
            <br />
            <br />
            <br />
          </div>
        </div>

      </div >

    );
};

NotificationsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  compareAsc: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  addFeedback,
  setAlert,
  logout,
  getNotifications,
  compareAsc
})(NotificationsPage);
