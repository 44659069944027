import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_FEEDBACK,
  ADD_FEEDBACK_ERROR
} from "./types";
import setAuthToken from "../utils/setAuthToken";

import { startLoader, stopLoader } from "./loader";

// Register User
export const addFeedback = ({ feedback, mode }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ feedback, mode });

  try {
    const res = await axios.post("/api/analytics/addfeedback", body, config);

    console.log("Res: " + res);
    if (mode == 1) {
      dispatch(setAlert("We have received your feedback. We will get back to you soon.", "success"));
    } else if (mode == 2) {
      dispatch(setAlert("We have received your new content request. We will update you soon.", "success"));
    } else if (mode == 3) {
      dispatch(setAlert("We have received your testimonial. Thank you for using Byts.", "success"));
    }

    dispatch({
      type: ADD_FEEDBACK,
    });



  } catch (err) {
    const errors = err.response.data.errors;

    if (mode == 1) {
      dispatch(setAlert("Thanks for your feedback. We will get back to you soon.", "success"));
    } else if (mode == 2) {
      dispatch(setAlert("Thanks for your new content request. We will get back to you soon.", "success"));
    } else if (mode == 3) {
      dispatch(setAlert("Thanks for your testimonial. Greetings from Team Byts.", "success"));
    }

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    //dispatch(setAlert("", "error"))

    dispatch({
      type: ADD_FEEDBACK_ERROR
    });
  }
};
