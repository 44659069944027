import axios from "axios";
import { setAlert } from "./alert";
import {
  EXECUTE_CODE,
  EXECUTE_CODE_ERROR,
  CLEAR_EXECUTE_CODE
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { startLoader, stopLoader } from "./loader";
import { isFirstDayOfMonth } from "date-fns/esm";

// var executeCodeTimeStamp = null;
// const EXECUTION_SPAMMING_TIMEOUT = 5;  // 5 seconds
var executeCodeRunning = false;

console.log("Reloading actions!");

// Execute Code
export const executeCode = ({ code, language, ctestcase, assetId, mode }) => async dispatch => {
  if (executeCodeRunning) {
    return;
  }
  executeCodeRunning = true;

  dispatch(startLoader());


  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  var showNotifs = assetId && assetId == "trycode" ? false : true;

  var modeString = "run";
  if (mode) {
    modeString = mode;
  }

  const body = JSON.stringify({ code, language, ctestcase, assetId, mode: modeString });


  try {

    const res = await axios.post("/api/runner/execute", body, config);

    executeCodeRunning = false;

    if (showNotifs && res.data.notif) {
      if (res.data.notifType == "silent") {
        // silently sink all the NOTIFICATIONS
        return;
      }
      dispatch(setAlert(res.data.notif, res.data.notifType ? res.data.notifType : "info"));
    }

    dispatch(stopLoader());


    dispatch({
      type: EXECUTE_CODE,
      payload: res.data
    });

  } catch (err) {
    dispatch(setAlert("Unable to run your code. Please retry again.", "error"));

    executeCodeRunning = false;

    dispatch(stopLoader());


    dispatch({
      type: EXECUTE_CODE_ERROR
    });
  }
};


export const clearRunner = () => async dispatch => {

  dispatch({
    type: CLEAR_EXECUTE_CODE,
  });


};
