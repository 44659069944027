import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { Progress } from 'reactstrap';
import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";

const CompletedRibbon = () => (
  <RightCornerLargeRibbon backgroundColor="#51a423" color="#ffffff">

    Completed
  </RightCornerLargeRibbon >);

const TopicItem = ({ topic }) => (
  <div
    className="containerxx"
    style={{
      paddingBottom: "50px",
      marginRight: "50px",
      marginLeft: "30px",
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "100%",
      alignItems: "center",
      height: "100%"
    }}
  >

    {topic && topic.progressCount == 100 ? <CompletedRibbon />
      : <Fragment></Fragment>}
    <RouteLink
      style={{ textDecoration: "none", color: "#000000" }}
      to={{
        pathname: "/learn/" + topic.topicId
      }}
    >
      <div
        className="fdb-box fdb-touch"
        style={{ height: "100%", paddingTop: "30px", paddingBottom: "45px", display: "flex", flexDirection: "column", justifyContent: "space-between", borderTop: "solid 0.3125rem #E55D3D" }}
      >

        <div
          style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
          {topic && topic.img ? <img
            alt="image"
            className="center"
            src={"/imgs/img/" + topic.img}
            style={{ height: "100px", width: "100px" }}
          /> : <Fragment></Fragment>}
        </div>

        <h2 style={{ marginTop: "5px" }}>{topic.title}</h2>
        <div>
          <div style={{ height: "10px" }}></div>
          {topic && topic.subtopics != 0 ? (
            <h3>{topic.subtopics + " Subtopics"}</h3>
          ) : (
              <Fragment></Fragment>
            )}
          {topic && topic.total != 0 ? (
            <h3>{topic.total + " Assets"}</h3>
          ) : (
              <Fragment></Fragment>
            )}
          {topic ? (
            <Progress color="danger" value={Math.floor(topic.progressCount)}></Progress>

          ) : (
              <Fragment></Fragment>
            )}
        </div>
      </div>
    </RouteLink>
  </div>
);

TopicItem.defaultProps = {
  showActions: true
};

TopicItem.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema
});

export default connect(mapStateToProps, {})(TopicItem);
