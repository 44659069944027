import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_ORDER,
  GET_ORDER_ERROR,
  GET_PRICINGPLANS,
  GET_PRICINGPLANS_ERROR
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { startLoader, stopLoader } from "./loader";

// Execute Code
export const getOrderId = ({ type, userId }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ type, userId });

  try {
    const res = await axios.post("/api/payment/createorder", body, config);

    if (res && res.data && res.data["err"]) {
      dispatch(setAlert(res.data["err"], "error"));
      dispatch({
        type: GET_ORDER,
        payload: res.data

      });
    } else {
      dispatch({
        type: GET_ORDER,
        payload: res.data
      });
    }


  } catch (err) {
    dispatch(setAlert("Unable to create an Order. Please refresh the page.", "error"));


    dispatch({
      type: GET_ORDER_ERROR
    });
  }
};

export const getPricingPlans = () => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get("/api/payment/pricingplans", config);

    dispatch({
      type: GET_PRICINGPLANS,
      payload: res.data
    });

  } catch (err) {
    dispatch(setAlert("Unable to get the Pricing Plans. Please refresh the page.", "error"));


    dispatch({
      type: GET_PRICINGPLANS_ERROR
    });
  }
};

