import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getProblemContent, updateProblemContent, resetCode } from "../../actions/schema";
import "./css/dsl.css";
import { useHistory } from "react-router-dom";
import Sidebar from "react-sidebar";
import MaterialTitlePanel from "./material_title_panel";
import {
    Link as RouteLink,
    Route,
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";
import "codemirror/lib/codemirror.css";
import ReactPlayer from "react-player";
import Parser from "html-react-parser";
import { executeCode, clearRunner } from "../../actions/runner";
import Avatar from 'react-avatar';

// themes
import "codemirror/theme/material.css";
import "codemirror/theme/3024-day.css";
import "codemirror/theme/ambiance.css";
import "codemirror/theme/cobalt.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/theme/eclipse.css";
import "codemirror/theme/erlang-dark.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/ttcn.css";

// addons
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/matchbrackets";

import { Controlled as CodeMirror } from "react-codemirror2";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Modal from "react-bootstrap/Modal";

import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";
import { makeStyles } from "@material-ui/core/styles";

import ReactDOM from "react-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Loader from "../layout/Loader";
import NewLoader from "../layout/NewLoader";

import { startLoader, stopLoader } from "../../actions/loader";
import { getSettings, setSettings } from "../../actions/settings";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import CommentsModel from "../support/CommentsModel";
import { clearComments } from "../../actions/comment";
import { Container, Row, Col, Badge } from "react-bootstrap";

// languages
require("codemirror/mode/clike/clike");
require("codemirror/mode/python/python");

var lastUpdatedTime = 0;
const UPDATE_INTERVAL = 1000; // every 30 second once update


const DifficultyLabel = ({ level }) => {
    const items = [];

    var color = "#000000";
    if (level == 1) {
        color = "#80c904";
    }
    else if (level == 2) {
        color = "#fee12b";
    } else if (level == 3) {
        color = "#ff2400";
    } else {
        color = "#000000";
    }

    for (var i = 0; i < level; i++) {
        items.push(<i className="fas fa-circle" style={{ color, paddingRight: "5px" }}></i>);
    }

    return (
        <Fragment>
            {items}
        </Fragment>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        height: 224
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`
    },
    tabContent: {
        flexGrow: 1,
        display: "flex"
    }
}));


function CongratsModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Congrats! You have successfully solved this problem!
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Go back and continue solving other problems.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function HintModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.hint}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function HintVideoModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className="player-wrapper loader"
                    style={{ backgroundColor: "#444444" }}
                >
                    <ReactPlayer
                        className="react-player"
                        url={props.hintURL}
                        width="100%"
                        height="100%"
                        playing={true}
                        controls={true}
                        loop={false}
                        config={{
                            youtube: {
                                playerVars: { loop: 0 }
                            },
                            vimeo: {
                                loop: "false"
                            }
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const styles = {
    sidebar: {
        width: "400px",
        height: "100%"
    },
    sidebarLink: {
        display: "block",
        padding: "16px 0px",
        color: "#757575",
        textDecoration: "none",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    divider: {
        margin: "8px 0",
        height: 1,
        backgroundColor: "#757575"
    },
    content: {
        padding: "16px",
        height: "100%",
        fontSize: "15px",
        backgroundColor: "white"
    }
};

const listOfThemes = [
    "default",
    "3024-day",
    "duotone-light",
    "eclipse",
    "ttcn",
    "material",
    "ambiance",
    "erlang-dark"
];
const listOfThemeNames = [
    "Default",
    "3024",
    "Duotone",
    "Eclipse",
    "TTCN ",
    "Material Dark",
    "Ambiance ",
    "Erlang "
];

const listOfLanguages = [
    "text/x-csrc",
    "text/x-c++src",
    "text/x-c++src",
    "text/x-c++src",
    "text/x-java",
    "text/x-python"
];
const listOfLanguageNames = [
    "C ",
    "C++11 ",
    "C++14 ",
    "C++17 ",
    "Java ",
    "Python "
];

const listOfLanguageValues = ["c", "cpp", "cpp", "cpp", "java", "py"];

var setRandom = "";

const TryCode = ({
    deleteAccount,
    auth: { user },
    settings: { settings, loadingSettings },
    runner: { runner, loadingExecute },
    location,
    storage,
    startLoader,
    stopLoader,
    getSettings,
    executeCode,
    setSettings,
    match,
    history,
    clearRunner,
    clearComments
}) => {

    const [formData, setFormData] = useState({
        value: "",
        themeIndex: 0,
        languageIndex: 0,
    });

    const {
        value,
        themeIndex,
        languageIndex,
    } = formData;

    useEffect(() => {
        clearRunner();

        var value = "";
        var languageIndex = 0;
        if (localStorage) {

            // if the value template is null, refresh it
            if (localStorage.getItem("tryC") == null) {
                localStorage.setItem("tryC", "#include<stdio.h>\nint main() {\n\n}");
            }
            if (localStorage.getItem("tryCpp") == null) {
                localStorage.setItem("tryCpp", "#include<iostream>\nusing namespace std;\n\nint main() {\n\n}");
            }
            if (localStorage.getItem("tryJava") == null) {
                localStorage.setItem("tryJava", "class Program {\n\tpublic static void main(String[] args) {\n\n\t}\n}");
            }
            if (localStorage.getItem("tryPy") == null) {
                localStorage.setItem("tryPy", "");
            }

            if (localStorage.getItem("favLang")) {
                languageIndex = localStorage.getItem("favLang");
            }

            if (languageIndex == 0) {
                if (localStorage.getItem("tryC") == null) {
                    localStorage.setItem("tryC", "#include<stdio.h>\nint main() {\n\n}");
                    value = "#include<stdio.h>\nint main() {\n\n}";
                } else {
                    value = localStorage.getItem("tryC")
                }
            } else if (languageIndex > 0 && languageIndex <= 3) {
                if (localStorage.getItem("tryCpp") == null) {
                    localStorage.setItem("tryCpp", "#include<iostream>\nusing namespace std;\n\nint main() {\n\n}");
                    value = "#include<stdio.h>\nint main() {\n\n}";
                } else {
                    value = localStorage.getItem("tryCpp")
                }
            } else if (languageIndex == 4) {
                if (localStorage.getItem("tryJava") == null) {
                    localStorage.setItem("tryJava", "class Program {\n\tpublic static void main(String[] args) {\n\n\t}\n}");
                    value = "#include<stdio.h>\nint main() {\n\n}";
                } else {
                    value = localStorage.getItem("tryJava")
                }
            } else if (languageIndex == 5) {
                if (localStorage.getItem("tryPy") == null) {
                    localStorage.setItem("tryPy", "");
                    value = "#include<stdio.h>\nint main() {\n\n}";
                } else {
                    value = localStorage.getItem("tryPy")
                }
            }
        }

        setFormData({ ...formData, value, languageIndex });

        window.scrollTo(0, 0);
    }, []);

    const onClickRunCustomTestCase = async e => {
        e.preventDefault();
        console.log("onClickRunCustomTestCase!");

        executeCode({
            assetId: "trycode",
            code: "",
            language: languageIndex,
            ctestcase: value,
            mode: "ctestcase"
        });
    };

    const style = styles.sidebar;


    return (
        <div>
            <div
                style={{
                    paddingLeft: "15px",
                    marginLeft: "10px",
                    marginTop: "10px",
                    zIndex: 0
                }}
                className="row justify-content-end"
            >
                <div className="row">

                    <div style={{ paddingRight: "10px" }}>
                        <NewLoader style={{ paddingLeft: "10px" }} />{" "}
                    </div>

                    <Dropdown style={{ paddingRight: "10px", zIndex: 1 }}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Language: {listOfLanguageNames[languageIndex]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {listOfLanguageNames &&
                                listOfLanguageNames.map((langName, index) => (
                                    <Dropdown.Item
                                        onClick={() => {
                                            var value = "";
                                            if (localStorage) {

                                                localStorage.setItem("favLang", index);

                                                if (index == 0) {
                                                    value = localStorage.getItem("tryC") ? localStorage.getItem("tryC") : "";
                                                } else if (index > 0 && index <= 3) {
                                                    value = localStorage.getItem("tryCpp") ? localStorage.getItem("tryCpp") : "";
                                                } else if (index == 4) {
                                                    value = localStorage.getItem("tryJava") ? localStorage.getItem("tryJava") : "";
                                                } else if (index == 5) {
                                                    value = localStorage.getItem("tryPy") ? localStorage.getItem("tryPy") : "";
                                                }
                                            }
                                            setFormData({
                                                ...formData,
                                                languageIndex: index,
                                                value
                                            });
                                        }}
                                    >
                                        {langName}
                                    </Dropdown.Item>
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div style={{ paddingRight: "30px" }}>
                        <Button
                            onClick={e => {
                                onClickRunCustomTestCase(e);
                            }}
                            variant="outline-secondary"
                        >
                            Run
                  </Button>
                    </div>
                </div>
            </div>

            <h3 style={{ fontSize: "22px" }}>Code Area</h3>

            <div
                className="shadow"
                style={{
                    paddingTop: "0px",
                    paddingLeft: "0px",
                    position: "relative",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}
            >
                <CodeMirror
                    value={value}
                    options={{
                        mode: listOfLanguages[languageIndex],
                        theme: listOfThemes[themeIndex],
                        lineNumbers: true,
                        autoCloseBrackets: true,
                        matchBrackets: true,
                        indentUnit: 4,
                    }}
                    editorDidMount={(editor, value) => {
                        console.log("Mounted");
                        editor.setSize("100%", 300);
                    }}
                    onBeforeChange={(editor, data, value) => {
                        value = value;

                        if (localStorage) {
                            if (languageIndex == 0) {
                                // c
                                localStorage.setItem("tryC", value);
                            } else if (languageIndex > 0 && languageIndex <= 3) {
                                // cpp
                                localStorage.setItem("tryCpp", value);
                            } else if (languageIndex == 4) {
                                // java
                                localStorage.setItem("tryJava", value);
                            } else if (languageIndex == 5) {
                                // py
                                localStorage.setItem("tryPy", value);
                            }
                        }

                        setFormData({
                            ...formData,
                            value,
                        });
                    }}

                />


            </div>
            <br />

            <h3 style={{ fontSize: "22px" }}>Output</h3>

            <div>

                <div
                    style={{
                        paddingTop: "0px",
                        paddingLeft: "0px",
                        position: "relative",
                        zIndex: 0,
                        top: 1,
                        left: 0,
                        right: 0,
                        bottom: 0
                    }}
                >
                    <CodeMirror
                        value={runner && runner.co ? (runner.co) : (runner && runner.msg) ? (runner.msg) : ("Please RUN the code to get the Raw Output")}
                        options={{
                            mode: listOfLanguages[languageIndex],
                            theme: listOfThemes[themeIndex],
                            lineNumbers: true,
                            autoCloseBrackets: true,
                            matchBrackets: true,
                            readOnly: true,
                            indentUnit: 4,
                        }}
                        editorDidMount={(editor, value) => {
                            console.log("Mounted");
                            editor.setSize("100%", 200);
                            editor.focus();
                        }}
                        onBeforeChange={(editor, data, value) => {
                        }}
                        onChange={(editor, data, value) => { }}
                    />


                </div>



            </div>
            <div>


            </div>
        </div>

    );
};

TryCode.propTypes = {
    auth: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    topic: PropTypes.object,
    runner: PropTypes.object,
    storage: PropTypes.object.isRequired,
    getProblemContent: PropTypes.func.isRequired,
    updateProblemContent: PropTypes.func.isRequired,
    startLoader: PropTypes.func.isRequired,
    stopLoader: PropTypes.func.isRequired,
    setSettings: PropTypes.func.isRequired,
    getSettings: PropTypes.func.isRequired,
    executeCode: PropTypes.func.isRequired,
    resetCode: PropTypes.func.isRequired,
    clearRunner: PropTypes.func.isRequired,
    clearComments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    schema: state.schema,
    storage: state.storage,
    settings: state.settings,
    runner: state.runner
});

export default connect(mapStateToProps, {
    getProblemContent,
    updateProblemContent,
    startLoader,
    stopLoader,
    getSettings,
    setSettings,
    executeCode,
    resetCode,
    clearRunner,
    clearComments,

})(TryCode);
