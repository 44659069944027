import uuid from "uuid";
import { START_LOADER, END_LOADER } from "./types";

export const startLoader = () => dispatch => {
  dispatch({
    type: START_LOADER,
    payload: { animating: true }
  });
};

export const stopLoader = () => dispatch => {
  dispatch({
    type: END_LOADER,
    payload: { animating: false }
  });
};
