import { Link, animateScroll as scroll } from "react-scroll";

import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, sendPasswordRecoveryMail } from "../../actions/auth";
import Loader from "../layout/Loader";

import {
  Link as RouteLink,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions,
} from "react-alert";
import { Nav, NavDropdown, Navbar, Row, Col } from "react-bootstrap";

const Login = ({ login, isAuthenticated, sendPasswordRecoveryMail }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const alert = useAlert();

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Processing!");
    if (!email || email.length == 0) {
      alert.show("Please enter a valid email address", { type: "error" });
    } else {
      sendPasswordRecoveryMail(email);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <RouteLink className="btn btn-outline-dark m-1" to="/login">
                  Go Back
                </RouteLink>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>

      <div
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
        data-aos-duration="200"
      >
        <br />
        <br />
        <section className="fdb-block py-0">
          <div
            className="container py-5 my-5 bodyz"
            style={{ backgroundImage: "url(imgs/shapes/4.svg)" }}
          >
            <div className=" row justify-content-end">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5 text-left">
                <div className="fdb-box">
                  <div className="row">
                    <div className="col">
                      <h1>Forgot Password</h1>
                      <p className="lead font-weight-normal">
                        Please enter your Byts Email Account. A password
                        recovery mail will be sent to this email.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mt-4">
                      <input
                        type="text"
                        className="form-control input"
                        style={{ fontWeight: "normal" }}
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>

                  <div
                    className="row align-items-center mt-4"
                    style={{ paddingLeft: "15px" }}
                  >
                    <button
                      onClick={e => onSubmit(e)}
                      className="col-8 btn btn-outline-secondary"
                      type="button"
                    >
                      <h4>Send Recovery Mail</h4>
                    </button>

                    <div className="col-2">
                      <Loader style={{ paddingLeft: "15px" }} />
                    </div>
                  </div>

                  <div style={{ paddingTop: "10px" }}>
                    <RouteLink to="/login">Go Back</RouteLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  sendPasswordRecoveryMail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, sendPasswordRecoveryMail })(
  Login
);
