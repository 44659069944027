import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_SCHEMA,
  SCHEMA_ERROR,
  GET_TOPIC,
  TOPIC_ERROR,
  GET_SUBTOPIC,
  SUBTOPIC_ERROR,
  UPDATE_PROGRESS,
  UPDATE_PROGRESS_ERROR,
  UPDATE_INPROGRESS,
  UPDATE_INPROGRESS_ERROR,
  GET_PROGRESS,
  GET_PROGRESS_ERROR,
  GET_PROBLEM,
  GET_PROBLEM_ERROR,
  GET_PROBLEMS,
  GET_PROBLEMS_ERROR,
  PROBLEMS_TAGS,
  UPDATE_PROBLEM,
  UPDATE_PROBLEM_ERROR,
  RESET_CODE,
  RESET_CODE_ERROR
} from "./types";
import { startLoader, stopLoader } from "./loader";
import { loadUser } from "./auth";

var listOfProgressUpdatedAssets = [];

export const getCurrentSchema = () => async dispatch => {
  try {
    const res = await axios.get("/api/content");

    dispatch({
      type: GET_SCHEMA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SCHEMA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getTopicDetails = topicId => async dispatch => {
  try {
    const res = await axios.get("/api/content/topic/" + topicId);

    dispatch({
      type: GET_TOPIC,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TOPIC_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


export const getProblems = ({ tags, sortOptions }) => async dispatch => {
  try {
    const res = await axios.get("/api/content/problems/");

    dispatch({
      type: GET_PROBLEMS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_PROBLEMS_ERROR,
    });
  }
};


export const setTags = ({ tags }) => async dispatch => {
  dispatch({
    type: PROBLEMS_TAGS,
    payload: tags
  });
};

export const getSubtopicDetails = subtopicId => async dispatch => {
  try {
    const res = await axios.get("/api/content/subtopic/" + subtopicId);

    dispatch({
      type: GET_SUBTOPIC,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SUBTOPIC_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const updateProgress = (
  topicId,
  subtopicId,
  assetId
) => async dispatch => {

  if (listOfProgressUpdatedAssets.includes(assetId)) {
    return;
  }

  listOfProgressUpdatedAssets.push(assetId);

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ assetId, topicId, subtopicId });

  try {
    const res = await axios.post("/api/content/updateprogress", body, config);

    dispatch(setAlert("This item has been marked as completed", "success"));

    dispatch({
      type: UPDATE_PROGRESS,
      payload: { assetId: assetId }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PROGRESS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const inProgress = ({
  practiseLink,
  learnLink,
  topicTitle,
  subtopicTitle,
  assetId,
  problemTitle
}) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({
    practiseLink,
    learnLink,
    topicTitle,
    subtopicTitle,
    assetId,
    problemTitle
  });

  try {
    const res = await axios.post("/api/content/inprogress", body, config);

    dispatch({
      type: UPDATE_INPROGRESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_INPROGRESS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Login User
export const getUserProgress = () => async dispatch => {
  console.log("getUserProgress");

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/content/getprogress", config);

    dispatch({
      type: GET_PROGRESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_PROGRESS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Login User
export const getProblemContent = ({ assetId }) => async dispatch => {
  console.log("getProblemContent: " + assetId);

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.post("/api/content/getproblem/" + assetId, config);

    dispatch({
      type: GET_PROBLEM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_PROBLEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Execute Code
export const resetCode = ({ assetId, language }) => async dispatch => {
  var languageString = "C";
  switch (language) {
    case 1:
    case 2:
    case 3:
      languageString = "C++";
      break;
    case 4:
      languageString = "Java";
      break;
    case 5:
      languageString = "Python";
      break;
  }
  if (window.confirm('Are you sure? This will reset your ' + languageString + " Code to the default template!")) {

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    dispatch(startLoader());

    const body = JSON.stringify({ assetId, language });

    try {
      const res = await axios.post("/api/content/resetcode", body, config);

      dispatch(stopLoader());
      if (res.data.notif) {
        dispatch(setAlert("Successfully reset your code to the default template"));
      }

      dispatch({
        type: RESET_CODE,
        payload: res.data

      });


    } catch (err) {
      dispatch(setAlert("Unable to reset your code. Please retry again.", "error"));

      dispatch({
        type: RESET_CODE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }

      });
    }

  }

};

// Login User
export const updateProblemContent = ({ assetId, code }) => async dispatch => {
  console.log("updateProblemContent: " + assetId);
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({
    code,
    assetId
  });

  try {
    const res = await axios.post(
      "/api/content/updateproblem/" + assetId,
      body,
      config
    );


    dispatch({
      type: UPDATE_PROBLEM,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PROBLEM_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
