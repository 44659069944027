import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import schema from "./schema";
import storage from "./storage";
import loader from "./loader";
import settings from "./settings";
import payment from "./payment";
import runner from "./runner";
import comment from "./comment";
import alertplain from "./alertplain";

export default combineReducers({
  alert,
  auth,
  schema,
  storage,
  loader,
  settings,
  payment,
  runner,
  comment,
  alertplain
});
