import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { Progress } from 'reactstrap';
import uuid from "uuid";
import { Badge } from "react-bootstrap";
import { inProgress } from "../../actions/schema";

import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";
import Modal from "react-bootstrap/Modal";
import { Nav, NavDropdown, Navbar, Container, Row, Column } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const SolvedRibbon = () => (
  <RightCornerLargeRibbon backgroundColor="#51a423" color="#ffffff">
    Solved
  </RightCornerLargeRibbon>
);


const TryItRibbon = () => (
  <RightCornerLargeRibbon backgroundColor="#E55D3D" color="#ffffff">
    Try This
  </RightCornerLargeRibbon>
);


function StatusModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.content}</p>
        {props.expiryDate ?
          <Fragment>
            <Row style={{ paddingLeft: "15px" }}>
              <p style={{ paddingTop: "1px", marginBottom: "0px", paddingRight: "5px" }}>Purchased on </p>

            </Row>
            <Row style={{ paddingLeft: "15px" }}>

              <p style={{ paddingTop: "1px", marginBottom: "0px", paddingRight: "5px" }}>This course will expire on </p>
            </Row> </Fragment> : <Fragment></Fragment>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal >
  );
}

const DifficultyLabel = ({ premium, level }) => {
  const items = [];

  var color = "#000000";
  if (level == 1) {
    color = "#80c904";
  }
  else if (level == 2) {
    color = "#fee12b";
  } else if (level == 3) {
    color = "#ff2400";
  } else {
    color = "#000000";
  }

  if (premium) {
    color = "#808080";
  }

  for (var i = 0; i < level; i++) {
    items.push(<i className="fas fa-circle" style={{ color, paddingRight: "5px" }}></i>);
  }

  return (
    <Fragment>
      {items}
    </Fragment>
  );
}


const TagsLabel = ({ tags }) => {
  const items = [];

  if (!tags) {
    return <Fragment></Fragment>;
  }

  for (var i = 0; i < tags.length; i++) {
    if (i == 10) {
      items.push(
        <Badge variant="secondary">+{tags.length - (i)} more</Badge>
      );
      break;
    }
    items.push(
      <Badge variant="secondary" style={{ marginRight: "5px" }}>{tags[i]}</Badge>
    );

  }

  return (
    <h3 style={{ fontSize: "25px", textTransform: "capitalize" }}>
      {items}
    </h3>
  );
}

const ProblemItem = ({ inProgress, done, problem, schema: { problems }, selectedTags }) => {


  const [formData, setFormData] = useState({
    showPractisePurschase: false
  });

  const {
    showPractisePurschase
  } = formData;


  useEffect(() => {
    console.log("REFRESH");

  }, []);

  if (problems && problems.selectedTags) {
    selectedTags = problems.selectedTags;
  }

  var showThis = false;


  var problemTags = problem.tags;

  var diffMatch = false;
  var actualTagsCount = 0;
  var diffTagsCount = 0;
  var checkSuccessCount = 0;
  var checkDiffSuccessCount = 0;
  var solvedUnsolvedTagsCount = 0;

  if (problemTags && problemTags.length > 0 && selectedTags && selectedTags.length > 0) {
    var processedLength = selectedTags.length;

    for (var i = 0; i < selectedTags.length; i++) {

      if (selectedTags[i] == "Solved" || selectedTags[i] == "Unsolved") {
        processedLength--;
        solvedUnsolvedTagsCount++;

      } else if (selectedTags[i] == "Easy" || selectedTags[i] == "Medium" || selectedTags[i] == "Hard") {
        diffTagsCount++;
        if (problemTags.includes(selectedTags[i])) {
          diffMatch = true;
          checkDiffSuccessCount++;
        }

      } else if (problemTags.includes(selectedTags[i])) {
        checkSuccessCount++;
      }
    }

    if (
      ((processedLength == diffTagsCount) && (checkDiffSuccessCount > 0))
      ||
      ((checkSuccessCount > 0) && (!(diffTagsCount > 0) || diffMatch))
      ||
      ((selectedTags.length == solvedUnsolvedTagsCount))
    ) {
      if (selectedTags.includes("Solved")) {
        if (done) {
          showThis = true;
        } else if (selectedTags.includes("Unsolved")) {
          if (!done) {
            showThis = true;
          }
        }
      } else if (selectedTags.includes("Unsolved")) {
        if (!done) {
          showThis = true;
        }
      } else {
        showThis = true;
      }

    }

  }

  if (showThis || !selectedTags || selectedTags.length == 0) {

    if (problem && problem.premium == true) {
      return (
        <div
          className="containerxx"
          style={{
            paddingBottom: "30px",
            marginRight: "50px",
            marginLeft: "30px",
            paddingLeft: "0px",
            paddingRight: "0px"
          }}
        >
          <StatusModal
            title="Purchase Needed"
            content="Please purchase a valid course to get access to all the problems."
            show={showPractisePurschase
            }
            onHide={() => setFormData({
              ...formData, showPractisePurschase
                : false
            })}
          />
          {done ? <SolvedRibbon /> : problem && problem.tryIt ? <TryItRibbon /> : <Fragment></Fragment>}
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}

            onClick={() => setFormData({
              ...formData,
              showPractisePurschase: true
            })
            }

          >
            <div
              className="fdb-box"
              style={{ paddingTop: "10px", paddingBottom: "20px", borderTop: ".3125rem solid #808080", zIndex: 2 }}
            >
              <h2 style={{ float: "right" }}><i
                style={{
                  color: "#808080",
                  alignSelf: "center",
                  paddingTop: "5px",
                  paddingRight: "44px",
                  width: "1px",
                  height: "auto"
                }}
                class="fas fa-lock"
              ></i></h2>


              <h2>{problem.title}</h2>
              <p>{problem.desc}</p>
              <div style={{ float: "left" }}>{problem && problem.tags ? <div><br /><TagsLabel tags={problem.tags} /></div> : <Fragment></Fragment>}</div>

              <div style={{ float: "right", paddingTop: "20px" }}>{problem && problem.difficulty ? <div>Difficulty<br /><DifficultyLabel premium={problem.premium} level={problem.difficulty} /></div> : <Fragment></Fragment>}</div>
            </div>


          </RouteLink>
        </div>);
    } else {
      return (
        <div
          className="containerxx"
          style={{
            paddingBottom: "30px",
            marginRight: "50px",
            marginLeft: "30px",
            paddingLeft: "0px",
            paddingRight: "0px"
          }}
        >
          {done ? <SolvedRibbon /> : problem && problem.tryIt ? <TryItRibbon /> : <Fragment></Fragment>}
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}
            to={{
              pathname: "/practise/1/" + problem.assetId,
            }}
            onClick={() => {
              inProgress({
                practiseLink:
                  "/practise/1/" + problem.assetId,

                assetId: problem.assetId,
                problemTitle: problem.title
              });
            }}
          >
            <div
              className="fdb-box fdb-touch"
              style={{ paddingTop: "10px", paddingBottom: "20px", borderTop: "solid 0.3125rem #E55D3D" }}
            >
              <h2>{problem.title}</h2>
              <p>{problem.desc}</p>
              <div style={{ float: "left" }}>{problem && problem.tags ? <div><br /><TagsLabel tags={problem.tags} /></div> : <Fragment></Fragment>}</div>

              <div style={{ float: "right", paddingTop: "20px" }}>{problem && problem.difficulty ? <div>Difficulty<br /><DifficultyLabel level={problem.difficulty} /></div> : <Fragment></Fragment>}</div>
            </div>


          </RouteLink>
        </div>)
    }
  }

  return (
    <Fragment></Fragment>
  )
};

ProblemItem.propTypes = {
  selectedTags: PropTypes.array.isRequired,
  problem: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  done: PropTypes.bool.isRequired,
  inProgress: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  schema: state.schema,
});

export default connect(mapStateToProps, { inProgress })(ProblemItem);
