import {
  EXECUTE_CODE,
  EXECUTE_CODE_ERROR,
  CLEAR_EXECUTE_CODE
} from "../actions/types";

const initialState = {
  runner: {},
  loadingExecute: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EXECUTE_CODE:
      if (payload.denied) {
        // no need to change the entire payload
        // just change the notif and the notifType
        return {
          loadingExecute: false,
          runner: { ...state.runner, notif: payload.notif, notifType: payload.notifType }
        }
      } else {
        return {
          loadingExecute: false,
          runner: payload
        };
      }
    case EXECUTE_CODE_ERROR:
      return {
        loadingExecute: false,
      };
    case CLEAR_EXECUTE_CODE:
      return {
        loadingExecute: false,
        runner: {}
      }
    default:
      return state;
  }
}
