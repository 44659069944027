import React, { Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";
import "./css/ds.css";
import { useHistory } from "react-router-dom";
import { getUserProgress } from "../../actions/schema";
import Button from "react-bootstrap/Button";
import { setAlert } from "../../actions/alert";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Avatar from 'react-avatar';
import { Dropdown } from 'semantic-ui-react'
import { Container, Row, Col, Badge } from "react-bootstrap";

import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router
} from "react-router-dom";

const bytsColor = "#E55D3D";
const semiDarkBytsColor = "#f1ad93";

const DashboardSelection = ({
  getUserProgress,
  deleteAccount,
  auth: { user, loading },
  schema: { progress, loadingProgress },
  setAlert,
  logout
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserProgress();
  }, [user]);

  const onClickLearn = async e => {
    e.preventDefault();
    console.log("onClickLearn!");
  };

  const onClickPractise = async e => {
    e.preventDefault();
    console.log("onClickPractise!");
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";
  return loading && user === null ? (
    <div>

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>


            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" style={{ color: bytsColor }}>Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notification
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <Spinner />
    </div>
  ) : (
      <div>

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>


              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" style={{ color: bytsColor }}>Home</Nav.Link>
                  <Nav.Link href="/learn">Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >

        <br />

        <div className="bodyz">
          <div className="" style={{ paddingBottom: "50px", paddingTop: "0px" }}>
            <div className="container">
              <div
                className="row align-items-center"
                style={{ paddingTop: "0px" }}
              >
                <div className="col-12 col-md-8 col-lg-7">
                  {progress && progress.meta != null && progress != null ? (
                    <Fragment>
                      {" "}
                      <h2>Pick up where you left off!</h2>
                      <p className="lead">{progress.meta}</p>
                      <RouteLink
                        to={progress.activeLink}
                        onClick={() => {
                          console.log("CLICKED");

                          setAlert(
                            "Successfully restored data from your last session.",
                            "success"
                          );
                        }}
                      >
                        <Button variant="outline-secondary">
                          Continue last session
                      </Button>{" "}
                      </RouteLink>
                    </Fragment>
                  ) : (
                      <Fragment>
                        {" "}
                        <h2>Are you ready?</h2>
                        <p className="lead">
                          This is your first time using the Byts tool. We suggest
                          you to start learning with our expert made content and
                          then start practising problems.
                    </p>
                      </Fragment>
                    )}
                </div>

                <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                  <img
                    alt="image"
                    className="img-fluid"
                    src="./imgs/draws/git.svg"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12 text-center">
                  <h2>Do you want to learn or practise?</h2>
                  <p className="lead">
                    Choose a stream to continue. You can switch between them later
                    too.
                </p>
                </div>
              </div>

              <div className="wrapperBody d-none d-lg-block"
              >
                <RouteLink to="/practise" className="containerx" style={{
                  textDecoration: "none",
                  background: "#ffffff",
                  boxShadow: "0px 20px 50px #555",
                  left: "50%",
                  zIndex: "0",
                  top: "5%",
                  width: "100%",
                  height: "100%",
                  perspective: "900px",
                  boxShadow: "0px 20px 50px #555",
                  borderRadius: "30px",
                  borderColor: bytsColor,
                  borderWidth: "1px",
                  borderStyle: "solid"

                }}>
                  <div className="col-10 col-md-4 pt-4 pt-md-0">
                    <img
                      alt="image"
                      style={{ height: "165px", paddingTop: "30px", paddingLeft: "8px" }}
                      src="./imgs/icons/practise.svg"
                    />
                  </div>
                  <div className="" id="s2x" style={{ paddingTop: "30px" }}>
                    <h3 className="h3x">Practise</h3>
                    <p className="px">
                      {" "}
                      Test your programming expertise with our curated list of
                      problems that simulates all levels of FAANG Programming
                      Rounds.
                    </p>
                  </div>
                </RouteLink>

                <RouteLink to="/learn" className="containerx" style={{
                  textDecoration: "none",
                  background: "#ffffff",
                  boxShadow: "0px 20px 50px #555",
                  left: "-50%",
                  zIndex: "0",
                  top: "5%",
                  width: "100%",
                  height: "100%",
                  perspective: "900px",
                  boxShadow: "0px 20px 50px #555",
                  borderRadius: "30px",
                  borderColor: bytsColor,
                  borderWidth: "1px",
                  borderStyle: "solid"

                }}>
                  <div className="col-10 col-md-4 pt-4 pt-md-0">
                    <img
                      alt="image"
                      style={{ height: "170px", paddingTop: "10px", paddingLeft: "27px" }}
                      src="./imgs/icons/learn.svg"
                    />
                  </div>
                  <div className="" id="s2x" style={{ paddingTop: "20px" }}>
                    <h3 className="h3x">Learn</h3>
                    <p className="px">
                      {" "}
                      Master Programming Languages like C, C++, Java and Python.
                      Learn about Data Structures and Algorithms with expert led
                      courses at your own pace.
                    </p>
                  </div>
                </RouteLink>

              </div>


              <div className="row d-lg-none">
                <div className="col-10 text-center mx-auto" style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  marginTop: "30px",
                  boxShadow: "0px 20px 50px #555",
                  borderRadius: "30px",
                  borderColor: bytsColor,
                  borderWidth: "1px",
                  borderStyle: "solid"
                }}>


                  <RouteLink to="/learn" className="" style={{
                    textDecoration: "none",
                    background: "#ffffff",

                    zIndex: "0",

                    width: "100%",
                    height: "100%",



                  }}>

                    <div className="" id="s2x" style={{ paddingTop: "15px" }}>
                      <img
                        alt="image"
                        style={{ height: "170px", paddingBottom: "5px" }}
                        src="./imgs/icons/learn.svg"
                      />
                      <h3 className="h3x">Learn</h3>
                      <p className="px">
                        {" "}
Master Programming Languages like C, C++, Java and Python.
Learn about Data Structures and Algorithms with expert led
courses at your own pace.
</p>
                    </div>
                  </RouteLink>





                </div>

                <div className="col-10 text-center mx-auto" style={{
                  boxShadow: "0px 20px 50px #555",
                  borderRadius: "30px",
                  borderColor: bytsColor,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  marginTop: "40px",
                }}>

                  <RouteLink to="/practise" className="" style={{
                    textDecoration: "none",
                    background: "#ffffff",

                    zIndex: "0",

                    width: "100%",
                    height: "100%",



                  }}>

                    <div className="" id="s2x" style={{ paddingTop: "20px" }}>
                      <img
                        alt="image"
                        style={{ height: "165px", paddingBottom: "10px" }}
                        src="./imgs/icons/practise.svg"
                      />
                      <h3 className="h3x">Practise</h3>
                      <p className="px">
                        {" "}
                      Test your programming expertise with our curated list of
                      problems that simulates all levels of FAANG Programming
                      Rounds.
                    </p>
                    </div>
                  </RouteLink>




                </div>
              </div>


            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div >
    );
};

DashboardSelection.propTypes = {
  auth: PropTypes.object.isRequired,
  getUserProgress: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema
});

export default connect(mapStateToProps, {
  getUserProgress,
  setAlert,
  logout
})(withRouter(DashboardSelection));
