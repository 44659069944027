import axios from "axios";
import {
  CLEAR_COMMENTS,
  UPDATE_COMMENT,
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  GET_COMMENTS,
  GET_COMMENTS_ERROR
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { startLoader, stopLoader } from "./loader";
import { isFirstDayOfMonth } from "date-fns/esm";
import { setAlert } from "../actions/alertplain";

/*
export const updateComment = ({ comment }) => async dispatch => {
  dispatch({
    type: UPDATE_COMMENT,
    payload: { commentString: comment }
  });
};
*/


export const clearComments = () => async dispatch => {
  console.log("clearComments");

  setTimeout(() => dispatch({ type: CLEAR_COMMENTS, payload: {} }), 500)
};


export const addComment = ({ comment, assetId, url }) => async dispatch => {

  if (!comment) {
    dispatch(setAlert("The comment field cannot be empty", "error"));
    return;
  }


  console.log("addComment");

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ comment, assetId, url });

  try {

    const res = await axios.post("/api/comments/addcomment", body, config);

    dispatch({
      type: ADD_COMMENT,
      payload: res.data
    });

    dispatch(setAlert("Your comment has been added successfully", "error"));

  } catch (err) {
    dispatch(setAlert("Unable to add your comment. Please retry again.", "error"));

    dispatch({
      type: ADD_COMMENT_ERROR
    });
  }
};

export const getComments = ({ assetId }) => async dispatch => {


  console.log("getComments: " + assetId);


  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };


  try {

    const res = await axios.get("/api/comments/comments/" + assetId, config);

    dispatch({
      type: GET_COMMENTS,
      payload: res.data
    });

  } catch (err) {

    dispatch(setAlert("Unable to fetch comments. Please retry again.", "error"));

    dispatch({
      type: GET_COMMENTS_ERROR
    });
  }
};