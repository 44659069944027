import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { Progress } from 'reactstrap';
import Modal from "react-bootstrap/Modal";
import { Button, Comment, Form, Header, TextArea } from 'semantic-ui-react'
import { addComment } from "../../actions/comment";
import Avatar from 'react-avatar';
import AlertPlain from "../layout/AlertPlain";

const CommentsModelFormTextArea = ({ show, assetId, addComment, alerts },) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("useEffect CommentsModelFormTextArea: " + assetId);
  }, [show]);


  const [formData, setFormData] = useState({
    comment: ""
  });

  const { comment } = formData;

  const onChange = e =>
    setFormData({ ...formData, comment: e });

  var alert = "";
  var alertId = "";
  var alertType = "";
  var msg = "";

  if (alerts !== null &&
    alerts.length > 0) {
    alert = alerts[0];
    alertId = alert.id;
    alertType = alert.alertType;

    if (alertType == "error") {
      msg = alert.msg;
    } else {
      setFormData({ ...formData, comment: "" });
    }
  }

  return (
    <Form style={{ marginLeft: "3px" }}>
      <Form.TextArea rows={3} value={comment} onChange={(e) => { setFormData({ ...formData, comment: e.target.value }) }} />

      <div className="row" style={{ paddingLeft: "14px" }}>
        <Button content='Add a Comment' labelPosition='left' icon='edit' color='red' inverted onClick={(e) => {
          e.preventDefault();
          addComment({ assetId, comment, url: window.location.href });
          setFormData({ ...formData, comment: "" });
        }} /> <p style={{ paddingTop: "7px", paddingLeft: "5px" }}>{msg}</p> </div>
    </Form>
  );
};

CommentsModelFormTextArea.defaultProps = {
  showActions: true
};

CommentsModelFormTextArea.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  addComment: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired

};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema,
  comment: state.comment,
  alerts: state.alertplain

});

export default connect(mapStateToProps, { addComment })(CommentsModelFormTextArea);
