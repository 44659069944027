import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";
import "./css/dsl.css";
import { useHistory, withRouter } from "react-router-dom";
import SubtopicItem from "./SubtopicItem";
import { getTopicDetails } from "../../actions/schema";
import { Nav, NavDropdown, Navbar, Button } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Avatar from "react-avatar";
import { Container, Row, Col, Badge } from "react-bootstrap";

import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

const bytsColor = "#E55D3D";

const LearnTopic = ({
  getTopicDetails,
  storage,
  auth: { user },
  schema: { topic, loadingTopic },
  match,
  logout,
  history,
}) => {
  useEffect(() => {
    console.log("Match: " + match.params.topicId);
    getTopicDetails(match.params.topicId);

    window.scrollTo(0, 0);
  }, []);
  //   }, [match, loadingTopic, getTopicDetails]);

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  if (!loadingTopic && topic === null) {
    return <Redirect to="/notfound" />;
  }

  return topic === null && loadingTopic ? (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("onClickBack!");

                    history.push("/learn");
                  }}
                  className="far fa-arrow-alt-circle-left fa-2x"
                ></i>
              </Navbar.Collapse>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Col style={{ paddingLeft: "0px" }}>
              <Nav className="justify-content-center">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/learn" style={{ color: bytsColor }}>
                  Learn
                </Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>
              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav style={{ paddingTop: "0px" }}>
                <Nav.Link
                  style={{ marginRight: "5px", paddingTop: "4px" }}
                  href="/notifications"
                >
                  Notifications
                </Nav.Link>

                <Avatar
                  name={userName}
                  color={userColour}
                  round={true}
                  size={30}
                  style={{ marginRight: "10px" }}
                />

                <Dropdown
                  style={{ marginRight: "0px", paddingTop: "4px" }}
                  direction="left"
                  text={userName}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/myaccount"
                      style={{ textDecoration: "none" }}
                    >
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div>
        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="light"
            variant="light"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Col>
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("onClickBack!");

                      history.push("/learn");
                    }}
                    className="far fa-arrow-alt-circle-left fa-2x"
                  ></i>
                </Navbar.Collapse>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Col style={{ paddingLeft: "0px" }}>
                <Nav className="justify-content-center">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/learn" style={{ color: bytsColor }}>
                    Learn
                </Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>
                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav style={{ paddingTop: "0px" }}>
                  <Nav.Link
                    style={{ marginRight: "5px", paddingTop: "4px" }}
                    href="/notifications"
                  >
                    Notifications
                  {user && user.notif ? (
                      <Badge style={{ marginLeft: "5px" }} variant="danger">
                        New
                      </Badge>
                    ) : (
                        <Fragment></Fragment>
                      )}
                  </Nav.Link>

                  <Avatar
                    name={userName}
                    color={userColour}
                    round={true}
                    size={30}
                    style={{ marginRight: "10px" }}
                  />

                  <Dropdown
                    style={{ marginRight: "0px", paddingTop: "4px" }}
                    direction="left"
                    text={userName}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="/myaccount"
                        style={{ textDecoration: "none" }}
                      >
                        My Account
                    </Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle
              style={{ marginRight: "0px" }}
              aria-controls="responsive-navbar-nav"
            />
          </Navbar>
        </section>

        <br />
        <br />

        <div className="bodyz">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  {topic && topic.img ? (
                    <img
                      alt="image"
                      className="fdb-icon"
                      src={"/imgs/img/" + topic.img}
                      style={{ height: "100px", width: "auto" }}
                    />
                  ) : (
                      <Fragment></Fragment>
                    )}

                  <h1>{topic.topic}</h1>
                  <p className="h3">{topic.desc}</p>
                  <br />

                  <p className="h2">Select a subtopic to continue learning</p>
                </div>
              </div>
            </div>
            <br />
          </div>
          <section className="fdb-block" style={{ paddingTop: "20px" }}>
            <div className="col text-center">
              <Container fluid>
                <Row style={{ paddingRight: "65px", paddingLeft: "0px" }}>
                  {topic &&
                    topic.subtopics &&
                    topic.subtopics.map((subtopic) => (
                      <Fragment>
                        <Col className="justify-content-md-center">
                          <SubtopicItem
                            key={subtopic.subtopicId}
                            subtopic={subtopic}
                          />
                        </Col>
                      </Fragment>
                    ))}
                </Row>
              </Container>
            </div>
          </section>
        </div>
      </div>
    );
};

LearnTopic.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  topic: PropTypes.object,
  storage: PropTypes.object.isRequired,
  getTopicDetails: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
  storage: state.storage,
});

export default connect(mapStateToProps, {
  getTopicDetails,
  logout,
})(withRouter(LearnTopic));
