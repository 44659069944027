import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { loadUser } from "../../actions/auth";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import { Card, ListGroup, Container, Row, Col, Badge } from "react-bootstrap";
import { Divider, Header, Icon, Table } from 'semantic-ui-react'
import Moment from "react-moment";
import moment from 'moment';

const bytsColor = "#E55D3D";

const MyAccount = ({
  deleteAccount,
  auth: { user },
  schema: { schema, loading },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  loadUser
}) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const onClickMyAccount = async e => {
    e.preventDefault();
    console.log("onClickMyAccount!");
  };

  const onClickPractise = async e => {
    e.preventDefault();
    console.log("onClickPractise!");
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  return user == null ? (
    <div id="home">

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                    <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                    : <Fragment></Fragment>
                  }
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div id="home">

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" >Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >
        <br />
        <br />

        <div className="bodyz">
          <div class="">
            <div className="container" fluid>
              <div className="row">
                <div className="col-12 text-center">
                  <p className="h2">My Account</p>
                </div>
              </div>
              <br />
              <div className="row align-items-center" style={{ paddingLeft: "20px" }}>
                <div className="col-12 col-md-8" style={{ paddingTop: "20px" }}>
                  <Fragment>
                    <Row>

                      <h2 style={{ direction: "left", fontWeight: "bold" }}>
                        {user.name} ({user.bytsId})
      </h2>
                    </Row>
                    <Row>
                      <p className="lead">{user.email}</p>
                    </Row>

                    <Row>
                      <p className="lead">{user.course}</p>
                    </Row>
                    <Row>
                      <p className="lead">{user.college}</p>
                    </Row>



                  </Fragment>
                </div>

                <div className="col-8 col-md-4 row justify-content-end" style={{ paddingTop: "20px", marginRight: "0px", paddingRight: "0px" }}
                >
                  <Avatar name={user.name} color={user.userColor} round={true} size={150} />
                </div>
              </div>

              <Row style={{ paddingTop: "8px", marginLeft: "5px" }}>
                {user && user.confirmed ? (
                  <div></div>
                ) : (
                    <h3>{"Your email has not been verified yet!"}</h3>
                  )}


              </Row>
              <Row style={{ paddingTop: "4px", marginLeft: "5px" }}>
                {user && user.confirmed ? (
                  <div></div>
                ) : (
                    <div
                      className="row align-items-center"
                      style={{ paddingLeft: "15px" }}
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          resendMail();
                        }}
                      >
                        {"Resend Confirmation Mail"}

                        <i
                          style={{ paddingLeft: "10px" }}
                          class="fas fa-retweet"
                        ></i>
                      </Button>
                      <div className="col-2">
                        <Loader style={{ paddingLeft: "15px" }} />
                      </div>
                    </div>
                  )}
              </Row>

              {/* <Row style={{ paddingTop: "14px", marginLeft: "5px" }}>
                <button className="btn btn-outline-primary" onClick={logout}>
                  {"Edit Profile"}
                  <i
                    style={{ paddingLeft: "10px" }}
                    class="fas fa-user-edit"
                  ></i>
                </button>
              </Row> */}

              {user && user.rSD == true ?
                <Row style={{ paddingTop: "14px", marginLeft: "5px" }}>
                  <RouteLink

                    to={"/changepassword"}
                  >
                    <Button variant="outline-secondary">
                      {"Change Password"}
                      <i
                        style={{ paddingLeft: "10px" }}
                        class="fas fa-key"
                      ></i>
                    </Button>{" "}
                  </RouteLink>

                </Row> : <Fragment></Fragment>}



              <Row style={{ paddingTop: "4px", marginLeft: "5px" }}>
                <button className="btn btn-outline-secondary" onClick={logout}>
                  {"Logout"}
                  <i
                    style={{ paddingLeft: "10px" }}
                    class="fas fa-sign-out-alt"
                  ></i>
                </button>
              </Row>

              <Divider horizontal>
                <Header as='h4'>
                  Active Subscriptions
      </Header>
              </Divider>

              <Row style={{ paddingLeft: "20px", paddingRight: '20px' }}>
                {user && user.activePlans && user.activePlans.length > 0 ? user.activePlans.map(activePlan => (
                  <div>
                    <div style={{ height: "10px" }}></div>

                    <Card style={{ width: "auto", paddingBottom: "0px", marginRight: "20px" }}>
                      <Card.Header>{activePlan.planTitle}</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>  <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                          <p style={{ paddingTop: "1px", marginBottom: "0px", paddingRight: "5px" }}>Valid till</p>
                          <Moment format="DD/MM/YYYY">{moment.utc(activePlan.expiry)}</Moment>
                        </Row></ListGroup.Item>

                      </ListGroup>
                    </Card>
                  </div>
                )) : <Col xs={10}><h3>{"No active course subscriptions."}</h3></Col>}
              </Row>

              <div style={{ paddingTop: "20px" }}>
                <RouteLink

                  to={"/payment"}
                >
                  <Button variant="outline-secondary" style={{ marginLeft: "5px" }}>
                    Manage Course Subscriptions
                    </Button>{" "}
                </RouteLink>

              </div>

            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div >
    );
};

MyAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  loadUser,
  logout
})(MyAccount);

/*
 */

/*
<br />
  <br />
  <Row>

  </Row>
  <br />
*/