import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import Parser from "html-react-parser";

import ReactPlayer from "react-player";
import { saveSubtopic, saveAsset, saveTopic } from "../../actions/storage";
import { updateProgress, inProgress } from "../../actions/schema";
import CommentsModel from "../support/CommentsModel";
import { clearComments } from "../../actions/comment";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import TryCode from "./TryCode";
import { Card, Button } from "react-bootstrap";
import { Accordion, Icon } from "semantic-ui-react";
import moment from "moment";

const bytsColor = "#E55D3D";

const AssetPane = ({
  auth,
  showActions,
  topic,
  schema: { subtopic },
  asset,
  saveSubtopic,
  saveAsset,
  storage,
  updateProgress,
  inProgress,
  clearComments,
}) => {
  const selectedStyle = {
    id: "1",
    backgroundColor: "#2d96f1",
    color: "#ffffff",
  };

  const [open, setOpen] = React.useState(false);

  const correctStyle = {
    id: "2",
    backgroundColor: "#00ff0033",
  };

  const wrongStyle = {
    id: "3",
    backgroundColor: "#ff000033",
  };

  const dummyStyle = { id: "0" };

  const [formData, setFormData] = useState({
    answeredArrays: [],
    mcqs: [],
    check: false,
    corrects: 0,
    totals: 0,
    videoFinished: false,
    activeIndex: 0,
  });

  const {
    answeredArrays,
    mcqs,
    check,
    corrects,
    totals,
    videoFinished,
    activeIndex,
  } = formData;
  useEffect(() => {
    console.log("useEffect Asset Pane");

    if (localStorage) {
      if (
        localStorage.getItem("notifAssetId") &&
        localStorage.getItem("notifDate")
      ) {
        if (
          new Date() - Date.parse(localStorage.getItem("notifDate")) <
          10000
        ) {
          setOpen(true);
          localStorage.removeItem("notifDate");
          localStorage.removeItem("notifAssetId");
        } else {
          localStorage.removeItem("notifDate");
          localStorage.removeItem("notifAssetId");
        }
      }
    }

    if (!storage && !storage.subtopic) {
      console.log("saveSubtopic");
      saveSubtopic(topic);
    }

    if (!storage && !storage.asset) {
      console.log("saveAsset");

      saveAsset(asset);
    }

    if (!(mcqs && mcqs.length > 0)) {
      asset &&
        asset.assetData &&
        asset.assetData.mcq &&
        asset.assetData.mcq.forEach((mcq) => {
          var array = mcq.chC.concat(mcq.chW);
          var cOrwMapping = [];
          var styleMap = [];
          mcq.chC.forEach((item) => {
            cOrwMapping.push("c");
            styleMap.push(dummyStyle);
          });
          mcq.chW.forEach((item) => {
            cOrwMapping.push("w");
            styleMap.push(dummyStyle);
          });
          var ctr = array.length,
            temp,
            tempMap,
            tempCorW,
            index;

          var correct;
          while (ctr > 0) {
            index = Math.floor(Math.random() * ctr);
            ctr--;
            temp = array[ctr];
            tempCorW = cOrwMapping[ctr];
            array[ctr] = array[index];
            cOrwMapping[ctr] = cOrwMapping[index];

            array[index] = temp;
            cOrwMapping[index] = tempCorW;
          }

          array = array;

          mcqs.push({
            ...mcq,
            cOrwMapping,
            mapping: array,
            correct: correct,
            styleMap,
            markerMap: [],
          });
        });
    }

    window.scrollTo(0, 0);
    setFormData({ answeredArrays, mcqs, check });
  }, [saveAsset, saveSubtopic]);

  const onClickMarkComplete = (topicId, subtopicId, assetId) => {
    console.log("onClickMarkComplete " + assetId);

    updateProgress(topicId, subtopicId, assetId);
  };

  const onClickAnswer = (mcqid, ch) => {
    console.log("onClickAnswer " + mcqid + " : " + ch);

    if (check) {
      return;
    }
    var tempMcqs = [];
    mcqs.forEach((mcq) => {
      if (mcq.ref == mcqid) {
        var tempSel = mcq.styleMap;
        if (tempSel[ch] && tempSel[ch].id === "1") {
          tempSel[ch] = dummyStyle;
        } else {
          tempSel[ch] = selectedStyle;
        }
        tempMcqs.push({
          ...mcq,
          styleMap: tempSel,
        });
      } else {
        tempMcqs.push(mcq);
      }
    });
    setFormData({ ...formData, mcqs: tempMcqs });
  };

  const onClickSubmit = (e) => {
    console.log("onClickSubmit " + e);

    if (!asset.finished) {
      updateProgress(asset.topicId, asset.subtopicId, asset.assetId);
    }

    var tempMcqs = [];

    var tempTotals = 0,
      tempCorrects = 0;

    mcqs.forEach((mcq) => {
      tempTotals++;

      // show the tick icon for all the correct options
      // be it selected or not
      var markerMap = [];
      var correct = true;
      mcq.cOrwMapping.map((ref, idx) => {
        if (ref == "c") {
          markerMap.push("c");
          if (mcq.styleMap[idx].id !== "1") {
            // but the user has not selected this
            correct = false;
          }
        } else if (mcq.styleMap[idx].id === "1" && ref == "w") {
          // this was selected by the user. WORNG ANSWER
          markerMap.push("w");
          correct = false;
        } else {
          markerMap.push("");
        }
      });

      if (correct) {
        tempCorrects++;
      }
      tempMcqs.push({
        ...mcq,
        markerMap,
      });
    });

    setFormData({
      ...formData,
      mcqs: tempMcqs,
      check: true,
      totals: tempTotals,
      corrects: tempCorrects,
    });
  };

  const onClickReset = (e) => {
    console.log("onClickReset " + e);
    var mcqAll = [];

    asset &&
      asset.assetData &&
      asset.assetData.mcq &&
      asset.assetData.mcq.forEach((mcq) => {
        var array = mcq.chC.concat(mcq.chW);
        var cOrwMapping = [];
        var styleMap = [];
        mcq.chC.forEach((item) => {
          cOrwMapping.push("c");
          styleMap.push(dummyStyle);
        });
        mcq.chW.forEach((item) => {
          cOrwMapping.push("w");
          styleMap.push(dummyStyle);
        });
        var ctr = array.length,
          temp,
          tempMap,
          tempCorW,
          index;

        var correct;
        while (ctr > 0) {
          index = Math.floor(Math.random() * ctr);
          ctr--;
          temp = array[ctr];
          tempCorW = cOrwMapping[ctr];
          array[ctr] = array[index];
          cOrwMapping[ctr] = cOrwMapping[index];

          array[index] = temp;
          cOrwMapping[index] = tempCorW;
        }

        array = array;

        mcqAll.push({
          ...mcq,
          cOrwMapping,
          mapping: array,
          correct: correct,
          styleMap,
          markerMap: [],
        });
      });

    setFormData({ answeredArrays, mcqs: mcqAll, check: false });
  };

  if (asset._id == "") {
    return (
      <div className="bodyz1">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />

                <p className="h2">Select an item on the Sidebar to continue</p>
              </div>
            </div>

            <div className="row align-items-center pt-2 pt-lg-5">
              <div className="col-12 col-md-8 col-lg-7">
                <h2>{topic ? topic.title : storage.subtopic.title}</h2>
                <p className="h4">
                  This subtopic has
                  <ul>
                    {(topic ? topic.nv : storage.subtopic.nv) &&
                      (topic ? topic.nv : storage.subtopic.nv) > 0 ? (
                        <li>{topic ? topic.nv : storage.topic.nv} Videos</li>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    {(topic ? topic.nt : storage.subtopic.nv) &&
                      (topic ? topic.nt : storage.subtopic.nt) > 0 ? (
                        <li>
                          {topic ? topic.nt : storage.subtopic.nt} Textual Content
                        </li>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    {(topic ? topic.np : storage.subtopic.np) &&
                      (topic ? topic.np : storage.subtopic.np) > 0 ? (
                        <li>{topic ? topic.np : storage.subtopic.np} Problems</li>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    {(topic ? topic.nm : storage.subtopic.nm) &&
                      (topic ? topic.nm : storage.subtopic.nm) > 0 ? (
                        <li>{topic ? topic.nm : storage.subtopic.nm} MCQs</li>
                      ) : (
                        <Fragment></Fragment>
                      )}
                  </ul>
                </p>

                {topic && topic.finished && topic.finished > 0 ? (
                  <p className="h4">
                    You have completed {topic.finished} out of {topic.total}{" "}
                    items in this subtopic.
                  </p>
                ) : (
                    <p className="h4">
                      You have completed {topic.finished} out of {topic.total}{" "}
                    items in this subtopic.
                    </p>
                  )}
              </div>

              <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                <img
                  alt="image"
                  className="img-fluid"
                  src="/imgs/draws/collections.svg"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <section className="fdb-block" style={{ paddingTop: "20px" }}></section>
      </div>
    );
  } else if (asset.type == "mcqs" && asset.premium == false) {
    if (!(mcqs && mcqs.length > 0)) {
      asset &&
        asset.assetData &&
        asset.assetData.mcq &&
        asset.assetData.mcq.forEach((mcq) => {
          var array = mcq.chC.concat(mcq.chW);
          var cOrwMapping = [];
          var styleMap = [];
          mcq.chC.forEach((item) => {
            cOrwMapping.push("c");
            styleMap.push(dummyStyle);
          });
          mcq.chW.forEach((item) => {
            cOrwMapping.push("w");
            styleMap.push(dummyStyle);
          });
          var ctr = array.length,
            temp,
            tempMap,
            tempCorW,
            index;

          var correct;
          while (ctr > 0) {
            index = Math.floor(Math.random() * ctr);
            ctr--;
            temp = array[ctr];
            tempCorW = cOrwMapping[ctr];
            array[ctr] = array[index];
            cOrwMapping[ctr] = cOrwMapping[index];

            array[index] = temp;
            cOrwMapping[index] = tempCorW;
          }

          array = array;

          mcqs.push({
            ...mcq,
            cOrwMapping,
            mapping: array,
            correct: correct,
            styleMap,
            markerMap: [],
          });
        });
    }
    return (
      <div className="bodyz1">
        <Fab

          mainButtonStyles={{ backgroundColor: "#E55D3D" }}
          actionButtonStyles={{ backgroundColor: "#E55D3D" }}
          position={{ bottom: 0, right: 0 }}
          icon={<i class="fas fa-question"></i>}
          //event={""}
          alwaysShowTitle={true}
        //onClick={}
        >
          <Action
            style={{ backgroundColor: "#E55D3Dbb" }}
            text="Discuss"
            onClick={() => setOpen(true)}
          >
            <i class="far fa-comments"></i>
          </Action>
        </Fab>
        <CommentsModel
          show={open}
          onHide={() => {
            setOpen(false);

            clearComments();
          }}
          assetId={asset.assetId}
        />
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />

                <p className="h2">Review Quiz</p>
                <p className="h3">
                  Answer these questions to test your course knowledge
                </p>
              </div>
            </div>
          </div>
        </div>

        <section
          className="fdb-block"
          style={{ paddingTop: "0px", paddingBottom: "20px" }}
        >
          <Fragment>
            {mcqs ? (
              mcqs.map((mcq) =>
                mcq &&
                  ((!asset.premium && asset.owned) ||
                    !mcq.premium ||
                    asset.owned) == true ? (
                    <div className="container" style={{ paddingTop: "30px" }}>
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-8 text-center">
                          <p className="h2" style={{ paddingBotton: "20px" }}>
                            {Parser(
                              "<pre style='white-space:pre-wrap;text-align: left;font-size: 20px'>" +
                              mcq.qn.replace("<", "&lt;").replace(">", "&gt;").replace("<<", '&lt;&lt;').replace(">>", "&gt;&gt;") +
                              "</pre>"
                            )}
                          </p>

                          {mcq.mapping &&
                            mcq.mapping.map((choice, index) => (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  width: "105%",
                                  margin: "0 auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingBottom: "10px",
                                }}
                                className="h3"
                              >
                                <button
                                  className="btn btn-light"
                                  style={{
                                    ...mcq.styleMap[index],
                                    width: "110%",
                                    fontWeight: "normal",
                                  }}
                                  onClick={() => onClickAnswer(mcq.ref, index)}
                                >
                                  {Parser(
                                    "<pre style='font-size: 18px;margin-bottom:0px'>" +
                                    choice.replace("<", "&lt;").replace(">", "&gt;").replace("<<", '&lt;&lt;').replace(">>", "&gt;&gt;") +
                                    "</pre>"
                                  )}
                                </button>
                                {mcq &&
                                  mcq.markerMap &&
                                  mcq.markerMap.length > 0 &&
                                  mcq.markerMap[index] === "w" ? (
                                    <div
                                      style={{
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                        paddingTop: "6px",
                                      }}
                                    >
                                      <i
                                        style={{
                                          color: "#ff0000",
                                          alignSelf: "center",
                                          paddingLeft: "20px",
                                        }}
                                        class="fas fa-times"
                                      ></i>
                                    </div>
                                  ) : mcq.markerMap[index] === "c" ? (
                                    <i
                                      style={{
                                        color: "#51a423",
                                        alignSelf: "center",
                                        paddingLeft: "20px",
                                      }}
                                      class="fas fa-check"
                                    ></i>
                                  ) : (
                                      <i
                                        style={{
                                          color: "#ffffff00",
                                          alignSelf: "center",
                                          paddingLeft: "20px",
                                        }}
                                        class="fas fa-check"
                                      ></i>
                                    )}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container" style={{ paddingTop: "30px" }}>
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-8 text-center">
                          <p className="h3" style={{ paddingBotton: "20px" }}>
                            <i
                              style={{
                                color: "#000000",
                                alignSelf: "center",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                              }}
                              class="fas fa-lock"
                            ></i>
                          Premium Question
                        </p>
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : (
                <h3>No MCQ Question Found</h3>
              )}
          </Fragment>
        </section>
        <div>
          {!check ? (
            asset && asset.finished ? (
              <section>
                <div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                      <h3 className="lead">Ready to submit your answers?</h3>

                      <p className="mt-5 mt-sm-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => onClickSubmit()}
                        >
                          Check Answers
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
                <div>
                  <div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-8 text-center">
                        <h3 className="lead">Ready to submit your answers?</h3>

                        <p className="mt-5 mt-sm-4">
                          <button
                            className="btn btn-primary"
                            onClick={() => onClickSubmit()}
                          >
                            Submit and Check Answers
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
          ) : (
              <section>
                <div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                      <h3 className="lead">
                        You have answered {corrects} out of {totals} quizzes
                      correctly!
                    </h3>

                      <p className="mt-5 mt-sm-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => onClickReset()}
                        >
                          Reset
                      </button>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
        </div>
      </div>
    );
  } else if (asset.type == "video" && asset.premium == false) {
    // we have an asset to render
    return (
      <div className="bodyz1">
        <Fab
          mainButtonStyles={{ backgroundColor: "#E55D3D" }}
          actionButtonStyles={{ backgroundColor: "#E55D3D" }}
          position={{ bottom: 0, right: 0 }}
          icon={<i class="fas fa-question"></i>}
          //event={""}
          alwaysShowTitle={true}
        //onClick={}
        >
          <Action
            style={{ backgroundColor: "#E55D3Dbb" }}
            text="Discuss"
            onClick={() => setOpen(true)}
          >
            <i class="far fa-comments"></i>
          </Action>
        </Fab>
        <CommentsModel
          show={open}
          onHide={() => {
            setOpen(false);

            clearComments();
          }}
          assetId={asset.assetId}
        />
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />
                <p className="h2">{asset.assetData.title}</p>
              </div>
            </div>
            <br />
            <div
              className="player-wrapper loader"
              style={{ backgroundColor: "#444444" }}
            >
              <ReactPlayer
                className="react-player"
                url={asset.assetData.url}
                width="100%"
                height="100%"
                playing={true}
                controls={true}
                config={{
                  youtube: {
                    playerVars: { loop: 0 },
                  },
                  vimeo: {
                    loop: "false",
                  },
                }}
                onEnded={() => {
                  console.log("VIDEO ENDED");
                  if (!asset.finished) {
                    updateProgress(
                      asset.topicId,
                      asset.subtopicId,
                      asset.assetId
                    );
                  }
                }}
              />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <br />
                <br />

                {asset && asset.assetData && asset.assetData.desc ? (
                  <h3>{Parser("<div>" + asset.assetData.desc + "</div>")}</h3>
                ) : (
                    <Fragment></Fragment>
                  )}
              </div>
            </div>

            <br />
            <br />
          </div>
        </div>
        <section
          className="fdb-block container"
          style={{
            paddingTop: "20px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={() => {
                setFormData({
                  ...formData,
                  activeIndex: activeIndex == 0 ? 1 : 0,
                });
              }}
            >
              <Icon name="dropdown" />
              Try code here
            </Accordion.Title>
            <Accordion.Content
              style={{ paddingBottom: "0px" }}
              active={activeIndex === 0}
            >
              <Card.Body style={{ paddingTop: "0px", zIndex: 0 }}>
                <TryCode />
              </Card.Body>
            </Accordion.Content>
          </Accordion>
        </section>
      </div>
    );
  } else if (asset.type == "text" && asset.premium == false) {
    // we have an asset to render
    return (
      <div className="bodyz1">
        <Fab
          mainButtonStyles={{ backgroundColor: "#E55D3D" }}
          actionButtonStyles={{ backgroundColor: "#E55D3D" }}
          position={{ bottom: 0, right: 0 }}
          icon={<i class="fas fa-question"></i>}
          //event={""}
          alwaysShowTitle={true}
        //onClick={}
        >
          <Action
            style={{ backgroundColor: "#E55D3Dbb" }}
            text="Discuss"
            onClick={() => setOpen(true)}
          >
            <i class="far fa-comments"></i>
          </Action>
        </Fab>
        <CommentsModel
          show={open}
          onHide={() => {
            setOpen(false);

            clearComments();
          }}
          assetId={asset.assetId}
        />
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />
                <p className="h2">{asset.assetData.title}</p>
              </div>
            </div>
            <br />
            <h3>{Parser("<div>" + asset.assetData.text + "</div>")}</h3>

            <div>
              {asset && !asset.finished ? (
                <p className="mt-5 mt-sm-4">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      onClickMarkComplete(
                        asset.topicId,
                        asset.subtopicId,
                        asset.assetId
                      )
                    }
                  >
                    Mark as Complete
                  </button>
                </p>
              ) : (
                  <div></div>
                )}
            </div>
          </div>
        </div>
        <section className="fdb-block" style={{ paddingTop: "20px" }}></section>
      </div>
    );
  } else if (asset.type == "problem" && asset.premium == false) {
    // we have an asset to render
    return (
      <div className="bodyz1">
        <Fab
          mainButtonStyles={{ backgroundColor: "#E55D3D" }}
          actionButtonStyles={{ backgroundColor: "#E55D3D" }}
          position={{ bottom: 0, right: 0 }}
          icon={<i class="fas fa-question"></i>}
          //event={""}
          alwaysShowTitle={true}
        //onClick={}
        >
          <Action
            style={{ backgroundColor: "#E55D3Dbb" }}
            text="Discuss"
            onClick={() => setOpen(true)}
          >
            <i class="far fa-comments"></i>
          </Action>
        </Fab>
        <CommentsModel
          show={open}
          onHide={() => {
            setOpen(false);

            clearComments();
          }}
          assetId={asset.assetId}
        />
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />
                <p className="h2">{asset.assetData.title}</p>
              </div>
            </div>
            <br />
            <h3>{Parser("<div>" + asset.assetData.desc + "</div>")}</h3>
            <p className="mt-5 mt-sm-4">
              <RouteLink
                className="btn btn-primary"
                to={"/practise/2/" + asset.assetId}
              >
                Solve this
              </RouteLink>
            </p>
          </div>
        </div>

        <section className="fdb-block" style={{ paddingTop: "20px" }}></section>
      </div>
    );
  } else if (asset.premium == true) {
    return (
      <div className="bodyz1">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />

                <p className="h2">Premium Content</p>
                <h3>
                  {asset && asset.assetData && asset.assetData.title
                    ? asset.assetData.title
                    : asset.type == "mcqs"
                      ? "Review Quiz"
                      : ""}
                </h3>
              </div>
            </div>

            <div className="row align-items-center pt-2 pt-lg-5">
              <div className="col-12 col-md-8 col-lg-7">
                <p className="h4">
                  Please purchase a course with the following subject to access
                  this item.
                  <h2>{subtopic ? subtopic.hTopic : ""}</h2>
                </p>
                <div style={{ paddingTop: "20px" }}>
                  <RouteLink to={"/payment"}>
                    <Button variant="outline-secondary">
                      Manage Course Subscriptions
                    </Button>{" "}
                  </RouteLink>
                </div>
              </div>

              <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                <img
                  alt="image"
                  className="img-fluid"
                  src="/imgs/draws/addToCart.svg"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <section className="fdb-block" style={{ paddingTop: "20px" }}></section>
      </div>
    );
  } else {
    // we have an asset to render
    return (
      <div className="bodyz1">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">

                <br />
                <p className="h2">{asset.title}</p>
              </div>
            </div>
          </div>
        </div>
        <section className="fdb-block" style={{ paddingTop: "20px" }}></section>
      </div>
    );
  }
};

AssetPane.defaultProps = {
  showActions: true,
};

AssetPane.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  saveSubtopic: PropTypes.func.isRequired,
  saveAsset: PropTypes.func.isRequired,
  updateProgress: PropTypes.func.isRequired,
  inProgress: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  schema: state.schema,
  storage: state.storage,
});

export default connect(mapStateToProps, {
  updateProgress,
  inProgress,
  saveSubtopic,
  saveAsset,
  clearComments,
})(AssetPane);
