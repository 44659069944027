import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { Progress } from "reactstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Nav,
  NavDropdown,
  Navbar,
  Container,
  Row,
  Column,
} from "react-bootstrap";

import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";

const TagRibbon = ({ string }) => {
  return string && string.length > 0 ? (
    <RightCornerLargeRibbon
      color="#ffffff"
      backgroundColor={string == "Coming Soon" ? "#014599" : "#cc4400"}
    >
      {string}
    </RightCornerLargeRibbon>
  ) : (
      <Fragment></Fragment>
    );
};

const OwnedRibbon = () => {
  return (
    <RightCornerLargeRibbon backgroundColor="#51a423" color="#ffffff">
      Purchased
    </RightCornerLargeRibbon>
  );
};

function StatusModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.content}</p>
        {props.expiryDate ? (
          <Fragment>
            <Row style={{ paddingLeft: "15px" }}>
              <p
                style={{
                  paddingTop: "1px",
                  marginBottom: "0px",
                  paddingRight: "5px",
                }}
              >
                Purchased on{" "}
              </p>
              <Moment format="DD/MM/YYYY">
                {moment.utc(props.purchasedOn)}
              </Moment>
            </Row>
            <Row style={{ paddingLeft: "15px" }}>
              <p
                style={{
                  paddingTop: "1px",
                  marginBottom: "0px",
                  paddingRight: "5px",
                }}
              >
                This course will expire on{" "}
              </p>
              <Moment format="DD/MM/YYYY">
                {moment.utc(props.expiryDate)}
              </Moment>
            </Row>{" "}
          </Fragment>
        ) : (
            <Fragment></Fragment>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

/*
if (user && user.activePlans && user.activePlans.map(activePlan => {
    if (activePlan.planId == pricingPlan.planId) {
      owned = true;
    }
  }))
*/
const PricingPlanItem = ({ pricingPlan, large, xlarge, user, ownedPlans }) => {
  const [formData, setFormData] = useState({
    showStatus: false,
    showStatusContentsOverlap: false,
    showStatusComingSoon: false,
  });

  const {
    showStatus,
    showStatusContentsOverlap,
    showStatusComingSoon,
  } = formData;

  var owned = false;
  var expiryDate = null;
  var purchasedOn = null;
  if (user && user.activePlans) {
    user.activePlans.forEach((activePlan) => {
      var activePlanPlanId = activePlan.planId;
      var parsedArray = activePlanPlanId.split("-");
      if (parsedArray.length > 0) {
        activePlanPlanId = parsedArray[0];
      }

      if (activePlanPlanId == pricingPlan.planId) {
        owned = true;
        if (expiryDate != null) {
          if (Date.parse(activePlan.expiry) > Date.parse(expiryDate)) {
            expiryDate = activePlan.expiry;
            purchasedOn = activePlan.purchasedOn;
          }
        } else {
          expiryDate = activePlan.expiry;
          purchasedOn = activePlan.purchasedOn;
        }
      }
    });
  }

  var contentsOverlap = false;
  var contentsMatchCount = 0;
  if (
    ownedPlans &&
    ownedPlans.length > 0 &&
    pricingPlan &&
    pricingPlan.contents
  ) {
    pricingPlan.contents.forEach((content) => {
      if (ownedPlans.includes(content)) {
        contentsMatchCount++;
      }
    });

    if (
      contentsMatchCount != 0 &&
      contentsMatchCount == pricingPlan.contents.length
    ) {
      contentsOverlap = true;
    }
  }

  const Content = () => (
    <Fragment>
      <div
        className="fdb-box fdb-touch"
        style={
          large
            ? { height: "auto", paddingTop: "30px", paddingBottom: "35px", display: "flex", flexDirection: "column", justifyContent: "space-between", borderTop: "solid 0.3125rem #E55D3D" }
            : { height: "auto", paddingTop: "30px", paddingBottom: "35px", display: "flex", flexDirection: "column", justifyContent: "space-between", borderTop: "solid 0.3125rem #E55D3D" }
        }
      >
        {pricingPlan && pricingPlan.img ? (
          <img
            alt="image"
            className="fdb-icon"
            src={"/imgs/img/" + pricingPlan.img}
            style={{ height: "100px", width: "auto" }}
          />
        ) : (
            <Fragment></Fragment>
          )}
        <h2 style={{ marginTop: "20px" }}>{pricingPlan.title}</h2>
        {pricingPlan &&
          pricingPlan.contents &&
          pricingPlan.contents.length > 0 ? (
            <div style={{ textAlign: "left", paddingBottom: "10px" }}>
              <h3>This includes</h3>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
        <div style={{ textAlign: "left" }}>
          {pricingPlan &&
            pricingPlan.contents &&
            pricingPlan.contents.length > 0 &&
            pricingPlan.contents.map((content) => <p>{content}</p>)}
        </div>
        <div style={{ paddingTop: "20px" }}>
          <h3>{pricingPlan && pricingPlan.validityString}</h3>
        </div>
      </div>
      <div
        className="shadow"
        style={{
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          backgroundColor: "#E55D3D22",
          width: "100%",
          height: "75px",
          zIndex: 1000,
        }}
      >
        {owned ? (
          <div style={{ paddingTop: "9px" }}>
            <p style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Expires on
            </p>
            <p
              style={{
                paddingTop: "0px",
                color: "#000000",
                fontSize: "25px",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              <Moment format="DD/MM/YYYY">{moment.utc(expiryDate)}</Moment>
            </p>
          </div>
        ) : (
            <div style={{ paddingTop: "7px" }}>
              <p
                style={{
                  color: "#000000",
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                ₹{pricingPlan.price}
              </p>
              <p>inclusive of GST</p>
            </div>
          )}
      </div>
    </Fragment>
  );

  return (
    <div
      className="containerxx"
      style={{
        paddingBottom: "50px",
        marginRight: "50px",
        marginLeft: "30px",
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "100%",
        alignItems: "center",
      }}
    >
      <StatusModal
        title="Attention"
        content="You have already purchased this course."
        expiryDate={expiryDate}
        purchasedOn={purchasedOn}
        show={showStatus}
        onHide={() => setFormData({ ...formData, showStatus: false })}
      />

      <StatusModal
        title="Attention"
        content="Your purchased courses already have all of this course's contents!"
        show={showStatusContentsOverlap}
        onHide={() =>
          setFormData({
            ...formData,
            showStatusContentsOverlap: false,
          })
        }
      />

      <StatusModal
        title="Stay Tuned"
        content="This course will be available soon."
        show={showStatusComingSoon}
        onHide={() =>
          setFormData({
            ...formData,
            showStatusComingSoon: false,
          })
        }
      />

      {owned ? <OwnedRibbon /> : <TagRibbon string={pricingPlan.planType} />}
      {pricingPlan &&
        pricingPlan.planType &&
        pricingPlan.planType == "Coming Soon" ? (
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();

              setFormData({ ...formData, showStatusComingSoon: true });
            }}
          >
            <Content />
          </RouteLink>
        ) : owned ? (
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();

              setFormData({ ...formData, showStatus: true });
            }}
          >
            <Content />
          </RouteLink>
        ) : contentsOverlap ? (
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();

              setFormData({ ...formData, showStatusContentsOverlap: true });
            }}
          >
            <Content />
          </RouteLink>
        ) : (
              <RouteLink
                style={{ textDecoration: "none", color: "#000000" }}
                to={{
                  pathname: "/checkout/" + pricingPlan.planId,
                }}
              >
                <Content />
              </RouteLink>
            )}
    </div>
  );
};

PricingPlanItem.defaultProps = {
  showActions: true,
};

PricingPlanItem.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  pricingPlan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  schema: state.schema,
});

export default connect(mapStateToProps, {})(PricingPlanItem);
