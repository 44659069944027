import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getProblemContent, updateProblemContent, resetCode } from "../../actions/schema";
import "./css/dsl.css";
import { useHistory } from "react-router-dom";
import Sidebar from "react-sidebar";
import MaterialTitlePanel from "./material_title_panel";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import "codemirror/lib/codemirror.css";
import ReactPlayer from "react-player";
import Parser from "html-react-parser";
import { executeCode, clearRunner } from "../../actions/runner";
import Avatar from 'react-avatar';
import { useMediaQuery } from 'react-responsive'

// themes
import "codemirror/theme/material.css";
import "codemirror/theme/3024-day.css";
import "codemirror/theme/ambiance.css";
import "codemirror/theme/cobalt.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/theme/eclipse.css";
import "codemirror/theme/erlang-dark.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/ttcn.css";

// addons
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/matchbrackets";

import { Controlled as CodeMirror } from "react-codemirror2";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Modal from "react-bootstrap/Modal";

import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";
import { makeStyles } from "@material-ui/core/styles";

import ReactDOM from "react-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Loader from "../layout/Loader";
import NewLoader from "../layout/NewLoader";

import { startLoader, stopLoader } from "../../actions/loader";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import CommentsModel from "../support/CommentsModel";
import { clearComments } from "../../actions/comment";
import { Container, Row, Col, Badge } from "react-bootstrap";
import ResizePanel from "react-resize-panel";
import style from './css/resizeHelper.css';
import classNames from 'classnames/bind';
let cx = classNames.bind(style);

// languages
require("codemirror/mode/clike/clike");
require("codemirror/mode/python/python");

var lastUpdatedTime = 0;
const UPDATE_INTERVAL = 1000; // every 30 second once update


const DifficultyLabel = ({ level }) => {
  const items = [];

  var color = "#000000";
  if (level == 1) {
    color = "#80c904";
  }
  else if (level == 2) {
    color = "#fee12b";
  } else if (level == 3) {
    color = "#ff2400";
  } else {
    color = "#000000";
  }

  for (var i = 0; i < level; i++) {
    items.push(<i className="fas fa-circle" style={{ color, paddingRight: "5px" }}></i>);
  }

  return (
    <Fragment>
      {items}
    </Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabContent: {
    flexGrow: 1,
    display: "flex"
  }
}));


function CongratsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Congrats! You have successfully solved this problem!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Go back and continue solving other problems.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function HintModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.hint}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function HintVideoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="player-wrapper loader"
          style={{ backgroundColor: "#444444" }}
        >
          <ReactPlayer
            className="react-player"
            url={props.hintURL}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            loop={false}
            config={{
              youtube: {
                playerVars: { loop: 0 }
              },
              vimeo: {
                loop: "false"
              }
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="Showing Hints">
          <Tab
            style={{
              fontSize: "10px",
              color: "#ffffff",
              backgroundColor: "#2d96f1FF"
            }}
            label="Item Osne"
          />
          <Tab label="Item Two" />
          <Tab label="Item Three" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
  );
}
const styles = {
  sidebar: {
    width: "400px",
    height: "100%",
    backgroundColor: "#263238",
    color: "#ffffff",
    textColor: "#ffffff"
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    padding: "16px",
    height: "100%",
    fontSize: "15px",
    backgroundColor: "#26323800",
    color: "#ffffff",
    textColor: "#ffffff"
  }
};

const listOfThemes = [
  "default",
  "3024-day",
  "duotone-light",
  "eclipse",
  "ttcn",
  "material",
  "ambiance",
  "erlang-dark"
];
const listOfThemeNames = [
  "Default",
  "3024",
  "Duotone",
  "Eclipse",
  "TTCN ",
  "Material Dark",
  "Ambiance ",
  "Erlang "
];

const listOfLanguages = [
  "text/x-csrc",
  "text/x-c++src",
  "text/x-c++src",
  "text/x-c++src",
  "text/x-java",
  "text/x-python"
];


var setRandom = "";

const ProblemsPage = ({
  deleteAccount,
  auth: { user },
  schema: { subtopic, problem, loadingProblem },
  runner: { runner, loadingExecute },
  location,
  storage,
  startLoader,
  stopLoader,
  executeCode,
  getProblemContent,
  updateProblemContent,
  match,
  resetCode,
  history,
  clearRunner,
  clearComments
}) => {

  const [formData, setFormData] = useState({
    valueMap: {},
    valueMapCTC: {},
    closeBrackets: true,
    themeIndex: 0,
    languageIndex: 0,
    showQuestion: true,
    showHint1: false,
    showHint2: false,
    showVideo: false,
    tabValue: 0,
    markedForUpdate: false,
    shownCongrats: false,
    showCongrats: false,
    listOfLanguageNames: [],
    listOfLanguageValues: [],
    openNew: false
  });

  const {
    valueMap,
    valueMapCTC,
    themeIndex,
    languageIndex,
    showQuestion,
    showHint1,
    showHint2,
    showVideo,
    tabValue,
    markedForUpdate,
    shownCongrats,
    showCongrats,
    listOfLanguageNames,
    listOfLanguageValues,
    openNew
  } = formData;


  const handleChange = (event, newValue) => {
    setFormData({ ...formData, tabValue: newValue });
  };

  const [open, setOpen] = React.useState(false)

  var portraitMode = useMediaQuery({ query: '(max-width: 1200px)' });

  useEffect(() => {

    clearRunner();

    if (localStorage) {
      if (localStorage.getItem("notifAssetId") && localStorage.getItem("notifDate")) {
        if ((new Date() - Date.parse(localStorage.getItem("notifDate"))) < 10000) {
          setOpen(true);
          localStorage.removeItem("notifDate");
          localStorage.removeItem("notifAssetId");
        } else {
          localStorage.removeItem("notifDate");
          localStorage.removeItem("notifAssetId");
        }
      }
    }

    getProblemContent({ assetId: match.params.assetId });

    if (!loadingProblem && problem) {
      setRandom = problem.random;

      console.log("Settings IN VALID");

      var themeIndex = 0;
      var languageIndex = 0;
      if (localStorage) {
        themeIndex = localStorage.getItem("favTheme") ? localStorage.getItem("favTheme") : 0;
        languageIndex = localStorage.getItem("favLang") ? localStorage.getItem("favLang") : 0;
      }

      //const listOfLanguageValues = ["c", "cpp", "cpp", "cpp", "java", "py"];
      var listOfLanguageValuesToRender = [];
      var listOfLanguageNamesToRender = [];
      var parsedCode;
      var parsedCTestCase;

      var validLanguageSelection = -1;
      // it is always known that the ctestcase will never be empty
      // and even if it is empty, we can also check for template code for the language
      if (problem && problem.assetData && problem.assetData.code) {
        parsedCode = problem.assetData.code;
      }

      if (problem && problem.assetData && problem.assetData.ctestcase) {
        parsedCTestCase = problem.assetData.ctestcase;
      }

      if ((parsedCode && parsedCode.c && parsedCode.c != "") || (parsedCTestCase && parsedCTestCase.c && parsedCTestCase.c != "")) {
        listOfLanguageNamesToRender.push("C ");
        listOfLanguageValuesToRender.push("c");
        validLanguageSelection = 0;
      }

      if ((parsedCode && parsedCode.cpp && parsedCode.cpp != "") || (parsedCTestCase && parsedCTestCase.cpp && parsedCTestCase.cpp != "")) {
        listOfLanguageNamesToRender.push("C++11 ");
        listOfLanguageValuesToRender.push("cpp");
        if (validLanguageSelection == -1) {
          validLanguageSelection = listOfLanguageNamesToRender.length - 1;
        }
        listOfLanguageNamesToRender.push("C++14 ");
        listOfLanguageValuesToRender.push("cpp");
        listOfLanguageNamesToRender.push("C++17 ");
        listOfLanguageValuesToRender.push("cpp");
        if (validLanguageSelection == -1) {
          validLanguageSelection = 1;
        }
      } else {
        // no C++ support
        if (languageIndex >= 1 && languageIndex <= 3) {
          // past settings 
          validLanguageSelection = listOfLanguageNamesToRender.length - 1;
        }
      }
      if ((parsedCode && parsedCode.java && parsedCode.java != "") || (parsedCTestCase && parsedCTestCase.java && parsedCTestCase.java != "")) {
        listOfLanguageNamesToRender.push("Java ");
        listOfLanguageValuesToRender.push("java");
        if (validLanguageSelection == -1) {
          validLanguageSelection = listOfLanguageNamesToRender.length - 1;
        }
      } else {
        // no java support
        if (languageIndex == 4) {
          // past settings 
          languageIndex = validLanguageSelection;
        }
      }
      if ((parsedCode && parsedCode.py && parsedCode.py != "") || (parsedCTestCase && parsedCTestCase.py && parsedCTestCase.py != "")) {
        listOfLanguageNamesToRender.push("Python ");
        listOfLanguageValuesToRender.push("py");
        if (validLanguageSelection == -1) {
          validLanguageSelection = listOfLanguageNamesToRender.length - 1;
        }
      } else {
        // no python support
        if (languageIndex == 5) {
          // past settings 
          languageIndex = validLanguageSelection;
        }
      }

      setFormData({
        ...formData,
        valueMap: problem.assetData.code,
        valueMapCTC:
          problem.assetData.ctestcase,
        themeIndex: themeIndex,
        languageIndex: languageIndex,
        listOfLanguageNames: listOfLanguageNamesToRender,
        listOfLanguageValues: listOfLanguageValuesToRender
      });
    } else {
      console.log("useEffect ELSE");
    }

    window.scrollTo(0, 0);

    //}, []);
  }, [getProblemContent, loadingProblem]);


  const contentHeader = (
    <span>
      {!portraitMode && (
        <a
          onClick={() => {
            console.log("Wow");
            open ? setFormData({ ...formData, open: false }) : setFormData({ ...formData, open: true })
          }}
          href="#"
          style={styles.contentHeaderMenuLink}
        >
          =
        </a>
      )}
      <span>Menu</span>
    </span>
  );

  const onClickShowSidebar = async e => {
    e.preventDefault();
    console.log("onClickShowSidebar!");

    setFormData({ ...formData, openNew: true });
  };

  const onClickQuestionBar = async e => {
    e.preventDefault();
    console.log("onClickQuestionBar!");
    setFormData({ ...formData, showQuestion: !showQuestion });
  };

  const onClickRun = async e => {
    e.preventDefault();
    console.log("onClickRun!");

    executeCode({
      assetId: match.params.assetId,
      code: valueMap[listOfLanguageValues[languageIndex]],
      language: languageIndex,
      ctestcase: "",
      mode: "run"
    });
  };

  const onClickRunCustomTestCase = async e => {
    e.preventDefault();
    console.log("onClickRunCustomTestCase!");

    executeCode({
      assetId: match.params.assetId,
      code: valueMap[listOfLanguageValues[languageIndex]],
      language: languageIndex,
      ctestcase: valueMapCTC[listOfLanguageValues[languageIndex]],
      mode: "ctestcase"
    });
  };

  const onClickBack = async e => {
    e.preventDefault();
    console.log("onClickBack!");

    history.goBack();
  };

  const onClickResetCode = async e => {
    e.preventDefault();
    console.log("onClickResetCode!");

    resetCode({ assetId: match.params.assetId, language: languageIndex });
  };

  const style = styles.sidebar;

  const onClickSubmit = async e => {
    e.preventDefault();
    console.log("onClickSubmit!");

    executeCode({
      assetId: match.params.assetId,
      code: valueMap[listOfLanguageValues[languageIndex]],
      language: languageIndex,
      ctestcase: "",
      mode: "submit"
    });
  };

  if (problem && problem.random && setRandom != problem.random) {
    console.log("Changing RANDOM");
    setRandom = problem.random;

    setFormData({
      ...formData,
      valueMap: problem.assetData.code,
      valueMapCTC: problem.assetData.ctestcase
    });
  }

  if (!shownCongrats && !showCongrats) {
    if ((runner && runner.done) && (problem && !problem.done)) {
      setFormData({ ...formData, showCongrats: (runner && runner.done) });//|| (problem && problem.done);
    }
  }

  if (!loadingProblem && problem == null) {
    return <Redirect to="/notfound" />;
  }

  return loadingProblem && problem === null ? (
    <div id="home">

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "112" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px"
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">

                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={e => {
                    e.preventDefault();
                    console.log("onClickBack!");

                    if (match.params.from == "2") {
                      if (subtopic && subtopic.subtopicId && match.params.assetId) {
                        history.push("/learnpage/" + subtopic.subtopicId + "/" + match.params.assetId)
                      } else if (subtopic && subtopic.subtopicId) {
                        history.push("/learnpage/" + subtopic.subtopicId + "/root");
                      } else {
                        history.goBack();
                      }
                    } else if (match.params.from == "1") {
                      history.push("/practise")
                    } else {
                      history.goBack();
                    }

                  }} className="far fa-arrow-alt-circle-left fa-2x"
                >

                </i>
              </Navbar.Collapse>

            </Row>
          </Col>

          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <div style={{ height: "5px" }}></div>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>

          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div>
        <section className="p-0 shadow" id="navigator" style={{ zIndex: "112" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px"
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">

                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={e => {
                      e.preventDefault();
                      console.log("onClickBack!");

                      if (match.params.from == "2") {
                        if (subtopic && subtopic.subtopicId && match.params.assetId) {
                          history.push("/learnpage/" + subtopic.subtopicId + "/" + match.params.assetId)
                        } else if (subtopic && subtopic.subtopicId) {
                          history.push("/learnpage/" + subtopic.subtopicId + "/root");
                        } else {
                          history.goBack();
                        }
                      } else if (match.params.from == "1") {
                        history.push("/practise")
                      } else {
                        history.goBack();
                      }

                    }} className="far fa-arrow-alt-circle-left fa-2x"
                  >

                  </i>
                </Navbar.Collapse>

              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center" >
                  {(runner && runner.done) || (problem && problem.done) ? (
                    <i
                      style={{
                        color: "#51a423",
                        alignSelf: "center",
                        paddingLeft: "20px"
                      }}
                      class="fas fa-check"
                    ></i>
                  ) : (
                      <i
                        style={{
                          color: "#51a423",
                          alignSelf: "center",
                          paddingLeft: "20px",
                          color: "#00000000"
                        }}
                        class="fas fa-check"
                      ></i>

                    )}
                  <Nav.Link style={{ color: "#2d96f1", color: "#000000", fontSize: "25px" }}> {problem.assetData.title}

                    {markedForUpdate ? (
                      <i
                        style={{ paddingLeft: "10px" }}
                        className="fas fa-pen-alt"
                      ></i>
                    ) : (
                        <i
                          style={{ paddingLeft: "10px", color: "#00000000" }}
                          className="fas fa-pen-alt"
                        ></i>
                      )}

                  </Nav.Link>

                </Nav>

              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "15px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <div style={{ paddingRight: "10px", width: "40px", }}>
                  <NewLoader style={{ paddingLeft: "10px" }} />{" "}
                </div>


              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >

        <br />

        <br />
        <br />
        <br />

        <Sidebar
          onSetOpen={(val) => {
            setFormData({ ...formData, openNew: val })
          }}
          open={openNew}
          docked={!portraitMode}
          sidebar={
            <MaterialTitlePanel title={contentHeader} style={{ ...style, paddingTop: "25px" }}>
              <div className="bodyz">
                <div style={styles.content} >
                  <CongratsModal
                    title={problem.assetData.title}
                    show={showCongrats}
                    onHide={() => setFormData({ ...formData, showCongrats: false, shownCongrats: true, })}
                  />
                  <HintModal
                    title="Hint 1"
                    hint={problem.assetData.hint1}
                    show={showHint1}
                    onHide={() => setFormData({ ...formData, showHint1: false })}
                  />
                  <HintModal
                    title="Hint 2"
                    hint={problem.assetData.hint2}
                    show={showHint2}
                    onHide={() => setFormData({ ...formData, showHint2: false })}
                  />
                  <HintVideoModal
                    title="Hint Video"
                    hintURL={problem.assetData.hintVideo}
                    show={showVideo}
                    onHide={() => setFormData({ ...formData, showVideo: false })}
                  />
                  <h2 style={{ color: "#E55D3D", fontWeight: "bolder" }}>Problem Statement</h2>
                  <p style={{ color: "#ffffff" }}>
                    {problem && problem.assetData
                      ? problem.assetData.problem
                      : "No problem found"}
                  </p>

                  {problem && problem.assetData && problem.assetData.sample ?
                    <Fragment>
                      <h2 style={{ color: "#E55D3D", fontWeight: "bolder" }}>Sample Input and Output</h2>
                      <p style={{ color: "#ffffff" }}>
                        {problem && problem.assetData && problem.assetData.sample ? (
                          <pre style={{ fontSize: "13px", color: "#ffffff" }} ><p style={{ color: "#ffffff" }}>{problem.assetData.sample}</p></pre>
                        ) : (
                            ""
                          )}
                      </p>
                    </Fragment>
                    : <Fragment></Fragment>}

                  {problem && problem.assetData && problem.assetData.extraDesc ?
                    <Fragment>
                      <p style={{ color: "#ffffff" }}>
                        {problem && problem.assetData && problem.assetData.extraDesc ? (
                          <pre style={{ fontSize: "13px", color: "#ffffff" }} ><p style={{ color: "#ffffff" }}>{problem.assetData.extraDesc}</p></pre>
                        ) : (
                            ""
                          )}
                      </p>
                    </Fragment>
                    : <Fragment></Fragment>}


                  <div
                    style={{
                      paddingLeft: "15px",
                      paddingBottom: "15px"
                    }}
                    className="row"
                  >
                    <div style={{ paddingLeft: "0px", width: "140px" }}>
                      {problem.assetData.hint1 ? (
                        <div style={{ paddingBottom: "10px" }}>
                          <Button
                            onClick={e => {
                              if (showHint1) {
                                setFormData({ ...formData, showHint1: false });
                              } else {
                                setFormData({
                                  ...formData,
                                  showHint1: true
                                });
                              }
                            }}
                            variant={!showHint1 ? "outline-primary" : "primary"}
                          >
                            {!showHint1 ? (
                              <div>Show Hint 1</div>
                            ) : (
                                <div>Hide Hint 1</div>
                              )}
                          </Button>{" "}
                        </div>
                      ) : (
                          <div></div>
                        )}
                      {problem.assetData.hint2 ? (
                        <div style={{ paddingBottom: "10px" }}>
                          <Button
                            onClick={e => {
                              if (showHint2) {
                                setFormData({ ...formData, showHint2: false });
                              } else {
                                setFormData({
                                  ...formData,
                                  showHint2: true
                                });
                              }
                            }}
                            variant={!showHint2 ? "outline-primary" : "primary"}
                          >
                            {!showHint2 ? (
                              <div>Show Hint 2</div>
                            ) : (
                                <div>Hide Hint 2</div>
                              )}
                          </Button>
                        </div>
                      ) : (
                          <div></div>
                        )}
                      {problem.assetData.hintVideo ? (
                        <div style={{ paddingBottom: "10px" }}>
                          <Button
                            onClick={e => {
                              if (showVideo) {
                                setFormData({ ...formData, showVideo: false });
                              } else {
                                setFormData({
                                  ...formData,
                                  showVideo: true
                                });
                              }
                            }}
                            variant={!showVideo ? "outline-primary" : "primary"}
                          >
                            {!showVideo ? (
                              <div>Show Video</div>
                            ) : (
                                <div>Hide Video</div>
                              )}
                          </Button>
                        </div>
                      ) : (
                          <div></div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </MaterialTitlePanel>
          }
        >
          (
          <div
            style={{
              paddingTop: "90px",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#263238",

            }}
          ><Fab

            mainButtonStyles={{ backgroundColor: "#E55D3D" }}
            actionButtonStyles={{ backgroundColor: "#E55D3D" }}
            position={{ bottom: 0, right: 0 }}
            icon={<i class="fas fa-question"></i>}
            //event={""}
            alwaysShowTitle={true}
          //onClick={}
          >


              <Action
                style={{ backgroundColor: "#E55D3Dbb" }}
                text="Discuss"
                onClick={() =>
                  setOpen(true)}
              >
                <i class="far fa-comments"></i>
              </Action>
            </Fab>
            <CommentsModel
              show={open}
              onHide={() => {
                setOpen(false);

                clearComments();
              }}
              assetId={problem.assetId}
            />

            {
              <Fragment><div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  height: "19px",
                  fontWeight: "normal",
                  paddingBottom: "60px"
                }}
              >
                <div
                  style={{
                    paddingLeft: "15px",
                    paddingBottom: "15px"
                  }}
                  className="row"
                >
                  {" "}
                  {portraitMode && <div style={{ paddingLeft: "10px" }}>
                    <Button
                      onClick={e => {
                        onClickShowSidebar(e);
                      }}
                      variant="outline-secondary"
                    >
                      Show Question Bar
                  </Button>
                  </div>}

                </div>
                <div
                  style={{ paddingBottom: "15px" }}
                  className={portraitMode ? "justify-content-end" : "row justify-content-end"}
                >
                  <div className={portraitMode ? "col" : "row"}>
                    <div style={portraitMode ? { paddingBottom: "10px", } : { paddingRight: "10px" }}>
                      <Button
                        onClick={e => {
                          onClickResetCode(e);
                        }}
                        variant="outline-secondary"
                      >
                        Reset your {listOfLanguageNames[languageIndex]} Code
                  </Button>
                    </div>

                    <Dropdown class="align-items-center" style={portraitMode ? { paddingRight: "0px", paddingLeft: "23px" } : { paddingRight: "30px" }}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Language: {listOfLanguageNames[languageIndex]}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {listOfLanguageNames &&
                          listOfLanguageNames.map((langName, index) => (
                            <Dropdown.Item
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  languageIndex: index,
                                });

                                if (localStorage) {
                                  localStorage.setItem("favLang", index);
                                }


                              }}
                            >
                              {langName}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
                <h3 style={{ fontSize: "22px", color: "#E55D3D", fontWeight: "bolder" }}>Code Area</h3>

                <div
                  className="shadow"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "0px",
                    position: "relative",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                >
                  <CodeMirror
                    value={valueMap[listOfLanguageValues[languageIndex]]}
                    options={{
                      mode: listOfLanguages[languageIndex],
                      theme: "material",
                      lineNumbers: true,
                      autoCloseBrackets: true,
                      matchBrackets: true,
                      indentUnit: 4,
                    }}
                    editorDidMount={(editor, value) => {
                      console.log("Mounted");
                      editor.setSize("100%", 500);
                    }}
                    onBeforeChange={(editor, data, value) => {
                      var string = listOfLanguageValues[languageIndex];
                      valueMap[string] = value;

                      if (!markedForUpdate) {
                        console.log("Submitting setTimeout");
                        setTimeout(function () {
                          console.log("Updating code");
                          lastUpdatedTime = new Date().getTime();

                          updateProblemContent({
                            assetId: problem.assetId,
                            code: valueMap
                          });
                          setFormData({ ...formData, markedForUpdate: false });
                        }, UPDATE_INTERVAL);

                        //setFormData({ ...formData, markedForUpdate: tru e });
                      }

                      setFormData({
                        ...formData,
                        valueMap,
                        markedForUpdate: true
                      });
                    }}
                    onChange={(editor, data, value) => {
                      /*
                      if (!markedForUpdate) {
                        setTimeout(function() {
                          console.log("Updating code");
                          lastUpdatedTime = new Date().getTime();
           
                          updateProblemContent({
                            assetId: problem.assetId,
                            code: valueMap
                          });
                          //setFormData({ ...formData, markedForUpdate: false });
                        }, UPDATE_INTERVAL);
           
                        //setFormData({ ...formData, markedForUpdate: tru e });
                      }
                      */
                    }}
                  />

                  <div

                    style={{
                      position: "absolute",
                      zIndex: 100,

                      bottom: -50,

                      width: "100%",
                      color: "#293338",
                      backgroundColor: "#293338",
                      height: "50px"
                    }}
                    onClick={() => { console.log("Running") }}
                  >

                    <Button
                      style={{ width: "100%", height: "50px" }}
                      onClick={e => {
                        onClickSubmit(e);
                      }}
                      variant="outline-secondary"
                    >
                      Run
                  </Button>
                  </div>
                </div>

                <br />
                <br />
                <br />
                <br />
                <h3 style={{ fontSize: "22px", color: "#E55D3D", fontWeight: "bolder" }}>Results and Output</h3>


                <div style={{ backgroundColor: "#263238" }}>
                  <AppBar position="static" color="#000000">
                    <Tabs
                      style={{ backgroundColor: "#263238", color: "#E55D3D", indicatorColor: "#E55D3D" }}
                      value={tabValue}
                      backgroundColor="#263238"
                      onChange={handleChange}
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab
                        style={{ color: "#E55D3D", indicatorColor: "#E55D3D" }}
                        label="Result"
                        {...a11yProps(0)}
                      />
                      <Tab
                        style={{ color: "#E55D3D", indicatorColor: "#E55D3D" }}
                        label="Raw Output"
                        {...a11yProps(1)}
                      />
                      <Tab
                        style={{ color: "#E55D3D" }}
                        label="Custom Testcase"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                    {tabValue == 0 ? (
                      <div
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "15px",
                          paddingBottom: "15px",
                          position: "relative",
                          zIndex: 111,
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "#263238",
                          color: "#ffffff",
                          height: "100%"
                        }}
                      >


                        {runner && runner.msg ? Parser(runner.msg) : (<div>Please RUN the code to check it against our Testcases.</div>)}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : tabValue == 1 ? (<div>

                      <div
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          position: "relative",
                          zIndex: 111,
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          height: "100%"
                        }}
                      >
                        <CodeMirror
                          value={runner && runner.co ? (runner.co) : ("Please RUN the code to get the Raw Test Case Output")}
                          options={{
                            mode: listOfLanguages[languageIndex],
                            theme: "material",
                            lineNumbers: true,
                            autoCloseBrackets: true,
                            matchBrackets: true,
                            readOnly: true,
                            indentUnit: 4,
                          }}
                          editorDidMount={(editor, value) => {
                            console.log("Mounted");
                            editor.setSize("100%", 300);
                          }}
                          onBeforeChange={(editor, data, value) => {
                          }}
                          onChange={(editor, data, value) => { }}
                        />

                        <div
                          style={{
                            position: "absolute",
                            zIndex: 200,
                            top: 0,
                            right: 0
                          }}
                        >

                        </div>
                      </div>



                    </div>) : ((
                      <div
                        style={{
                          paddingTop: "0px",
                          paddingLeft: "0px",
                          position: "relative",
                          zIndex: 111,
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0
                        }}
                      >
                        <CodeMirror
                          value={valueMapCTC[listOfLanguageValues[languageIndex]]}
                          options={{
                            mode: listOfLanguages[languageIndex],
                            theme: "material",
                            lineNumbers: true,
                            autoCloseBrackets: true,
                            matchBrackets: true,
                            indentUnit: 4,
                          }}
                          editorDidMount={(editor, value) => {
                            console.log("Mounted");
                            editor.setSize("100%", 300);
                          }}
                          onBeforeChange={(editor, data, value) => {
                            var string = listOfLanguageValues[languageIndex];
                            valueMapCTC[string] = value;
                            setFormData({
                              ...formData,
                              valueMapCTC,
                            });
                          }}
                          onChange={(editor, data, value) => { }}
                        />

                        <div
                          style={{
                            position: "absolute",
                            zIndex: 200,
                            top: 0,
                            right: 0
                          }}
                        >
                          <div style={{ paddingRight: "20px", paddingTop: "10px" }}>
                            <Button
                              onClick={e => {
                                onClickRunCustomTestCase(e);
                              }}
                              variant="outline-secondary"
                            >
                              Run This
                  </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AppBar>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </Fragment>
            }
          </div>
        </Sidebar >
      </div >
    );
};

ProblemsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  topic: PropTypes.object,
  runner: PropTypes.object,
  storage: PropTypes.object.isRequired,
  getProblemContent: PropTypes.func.isRequired,
  updateProblemContent: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  executeCode: PropTypes.func.isRequired,
  resetCode: PropTypes.func.isRequired,
  clearRunner: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema,
  storage: state.storage,
  settings: state.settings,
  runner: state.runner
});

export default connect(mapStateToProps, {
  getProblemContent,
  updateProblemContent,
  startLoader,
  stopLoader,
  executeCode,
  resetCode,
  clearRunner,
  clearComments

})(ProblemsPage);
