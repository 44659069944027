import { SAVE_TOPIC, SAVE_SUBTOPIC, SAVE_ASSET } from "../actions/types";

const initialState = {
  topic: {},
  subtopic: {},
  asset: { _id: "" },
  loading: true,
  user: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SAVE_TOPIC:
      return {
        ...state,
        loading: false,
        topic: payload
      };
    case SAVE_SUBTOPIC:
      return {
        ...state,
        loading: false,
        subtopic: payload
      };
    case SAVE_ASSET:
      return state;

    default:
      return state;
  }
}
