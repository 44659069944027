import {
  GET_COMMENTS,
  GET_COMMENTS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  CLEAR_COMMENTS,
  UPDATE_COMMENT
} from "../actions/types";

const initialState = {
  comments: [],
  loadingComments: true,
  commentString: "start"
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_COMMENTS:
      return {
        comments: [],
        loadingComments: true,
      };
    case ADD_COMMENT:
      return {
        comments: payload.comments,
      };
    case ADD_COMMENT_ERROR:
      return state;
    case GET_COMMENTS:
      return {
        comments: payload.comments,
      };
    case GET_COMMENTS_ERROR:
      return {
        loadingComments: false,
      };
    default:
      return state;
  }
}
