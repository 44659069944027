import React, { Fragment } from "react";

const EmailConfirmation = () => {
  return (
    <Fragment>
      <div id="home">
        <section
          className=" bodyz"
          style={{ paddingBottom: "0px", paddingTop: "20px" }}
          id="liveassistance"
          data-aos="zoom-in"
          data-aos-offset="220"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div className="container">
            <div className="row justify-content-center pb-xl-5">
              <div className="col-8 col-sm-8 text-center">
                <img
                  alt="image"
                  className="img-fluid mt-5"
                  src="./imgs/web/mailConfirmation.png"
                  style={{ height: "300px", width: "auto", paddingTop: "100px" }}
                />
                <h2>Your email has been successfully verified!</h2>
                <p className="mt-5 mt-sm-4">
                  <a className="btn btn-primary" href="/dashboard">
                    Take me to Byts Homepage
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default EmailConfirmation;
