import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { Progress } from 'reactstrap';
import Modal from "react-bootstrap/Modal";
import { Button, Comment, Form, Header, TextArea } from 'semantic-ui-react'
import { addComment, getComments } from "../../actions/comment";
import Avatar from 'react-avatar';
import AlertPlain from "../layout/AlertPlain";
import CommentsModelFormTextArea from "../support/CommentsModelFormTextArea";
import spinner from '../layout/spinner.gif';
import { Badge } from "react-bootstrap";

const CommentsModel = ({
  onHide,
  show,
  assetId,
  getComments,
  addComment,
  comment:
  {
    comments,
    loadingComments
  },
  alerts
}, ) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("useEffect Comments Model: " + assetId);

    if (show) {
      getComments({ assetId });
    }
  }, [getComments, show]);


  const [formData, setFormData] = useState({
    comment: ""
  });

  const { comment } = formData;

  const onChange = e =>
    setFormData({ ...formData, comment: e });

  var alert = "";
  var alertId = "";
  var alertType = "";
  var msg = "";

  if (alerts !== null &&
    alerts.length > 0) {
    alert = alerts[0];
    alertId = alert.id;
    alertType = alert.alertType;

    if (alertType == "error") {
      msg = alert.msg;
    } else {
      setFormData({ ...formData, comment: "" });
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 style={{ paddingLeft: "2px" }}>Discussion</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Comment.Group style={{ maxWidth: "1000px", marginRight: "7px" }}>

          <Header style={{ marginTop: "0px", marginLeft: "3px" }}>
            <h3>Add a Comment </h3>
          </Header>
          <CommentsModelFormTextArea
            show={show}
            assetId={assetId}
            alerts={alerts}
          />

          {loadingComments ? (
            <Fragment>

              <img
                src={spinner}
                style={{
                  width: '100px',
                  marginLeft: "0px",
                  marginTop: "0px"
                }}
                alt='Loading...'
              />
            </Fragment>
          ) : comments && comments.length == 0 ? <h3 style={{ paddingTop: "5px" }}>No comments have been added yet</h3> : <Fragment></Fragment>}
          {comments &&
            comments.map(comment => (
              <Comment style={{ marginLeft: "3px", }}>

                <div>
                  <Avatar name={comment.by} color={comment.userColor} round={true} size={30}
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "block",
                      width: "2.5em",
                      height: "2.5em",
                      float: "left",
                      margin: ".2em 0 0",
                      boxSizing: "border-box",
                    }}
                  />
                  <Comment.Content style={{ paddingTop: "3px", paddingLeft: "5px" }}>
                    <Comment.Author as='a' style={{ paddingLeft: "5px" }}>{comment.by}</Comment.Author>
                    <Comment.Metadata>
                      {comment.official ? <Badge variant="secondary" style={{ marginRight: "5px" }}>Official</Badge>
                        : <Fragment></Fragment>}
                      <Moment fromNow date={comment.at} />

                    </Comment.Metadata>
                    <Comment.Text style={{ paddingLeft: "35px", textAlign: "justify" }}>{comment.comment}</Comment.Text>

                  </Comment.Content>
                </div>

              </Comment>
            ))}



        </Comment.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

CommentsModel.defaultProps = {
  showActions: true
};

CommentsModel.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  getComments: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired

};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema,
  comment: state.comment,
  alerts: state.alertplain

});

export default connect(mapStateToProps, { getComments, addComment })(CommentsModel);

/*

    <Avatar name={comment.by} color={comment.userColor} round={true} size={30}
                  style={{
                    width: "30px",
                    height: "30px",
                    display: "block",
                    width: "2.5em",
                    height: "auto",
                    float: "left",
                    margin: ".2em 0 0",
                    boxSizing: "border-box"
                  }}
                />

  <Comment style={{ marginLeft: "5px", paddingTop: "10px" }}>
            <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
            <Comment.Content>
              <Comment.Author as='a'>Matt</Comment.Author>
              <Comment.Metadata>
                <div>Today at 5:42PM</div>
              </Comment.Metadata>
              <Comment.Text>How artistic!</Comment.Text>
              <Comment.Actions>
                <Comment.Action>Reply</Comment.Action>
              </Comment.Actions>
            </Comment.Content>
          </Comment>

          <Comment style={{ marginLeft: "5px" }}>
            <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
            <Comment.Content>
              <Comment.Author as='a'>Elliot Fu</Comment.Author>
              <Comment.Metadata>
                <div>Yesterday at 12:30AM</div>
              </Comment.Metadata>
              <Comment.Text>
                <p>This has been very useful for my research. Thanks as well!</p>
              </Comment.Text>
              <Comment.Actions>
                <Comment.Action>Reply</Comment.Action>
              </Comment.Actions>
            </Comment.Content>

          </Comment>

          <Comment style={{ marginLeft: "5px" }}>
            <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
            <Comment.Content>
              <Comment.Author as='a'>Joe Henderson</Comment.Author>
              <Comment.Metadata>
                <div>5 days ago</div>
              </Comment.Metadata>
              <Comment.Text>Dude, this is awesome. Thanks so much</Comment.Text>
              <Comment.Actions>
                <Comment.Action>Reply</Comment.Action>
              </Comment.Actions>
            </Comment.Content>
          </Comment>
*/