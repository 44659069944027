import { START_LOADER, END_LOADER } from "../actions/types";

const initialState = { animating: false };

export default function(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case START_LOADER:
      return { ...state, animating: true };
    case END_LOADER:
      return { ...state, animating: false };
    default:
      return state;
  }
}
