import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { addFeedback } from "../../actions/analytics";
import { setAlert } from "../../actions/alert";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import { Card } from "react-bootstrap";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { Accordion, Icon } from 'semantic-ui-react'

const bytsColor = "#E55D3D";

const SupportPage = ({
  deleteAccount,
  auth: { user },
  schema: { schema, loading },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  addFeedback,
  setAlert,

}) => {
  const [formData, setFormData] = useState({
    showFeedbackModal: false,
    feedback: "",
    testimonial: "",
    request: "",
    key: "0",
    activeIndex: 0
  });




  const { showFeedbackModal, feedback, testimonial, request, key, activeIndex } = formData;

  useEffect(() => {
  }, []);

  const onClickFeedbackSubmit = async e => {
    e.preventDefault();
    console.log("onClickFeedbackSubmit!");

    if (!feedback)
      setAlert("The Feedback field is empty.", "error");
    else {
      addFeedback({ feedback: feedback, mode: 1 });
      setFormData({ ...formData, feedback: "" })
    }
  };

  const onClickTestimonialSubmit = async e => {
    e.preventDefault();
    console.log("onClickTestimonialSubmit!");

    if (!testimonial)
      setAlert("The Testimonial field is empty.", "error");
    else {
      addFeedback({ feedback: testimonial, mode: 3 });
      setFormData({ ...formData, testimonial: "" })
    }
  };

  const onClickRequestSubmit = async e => {
    e.preventDefault();
    console.log("onClickRequestSubmit!");

    if (!request)
      setAlert("The Request field is empty.", "error");
    else {
      addFeedback({ feedback: request, mode: 2 });
      setFormData({ ...formData, request: "" })
    }
  };

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleClick = (activeIndex) => {
    setFormData({ ...formData, activeIndex: activeIndex })
  }

  function FeedbackModal(props) {
    return (
      <Modal
        size="lg"
        centered
      >
        <div className="" closeButton>
          <div className="container" >
            <div className="row justify-content-center" >
              <div className="col-12 col-md-8 col-lg-7 col-md-5 text-center" >
                <div className="fdb-box fdb-touch" style={{ paddingTop: "30px" }}>
                  <div className="row" style={{ paddingTop: "0px", paddingBottom: "20px" }} >
                    <div className="col" style={{ paddingTop: "0px" }}>
                      <h2>Submit your feedback</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  return (
    <div>


      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support" style={{ color: bytsColor }}>Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                    <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                    : <Fragment></Fragment>
                  }
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >



      <br />
      <br />
      <div className="bodyz">
        <div class="">
          <div className="container" >
            <div className="row" >
              <div className="col-12 text-center">
                <p className="h2">Support</p>
              </div>
            </div>
            <div className="fdb-block" style={{ paddingTop: "30px" }}>
              <div className="container" style={{ paddingRight: "30px" }} >

                <Accordion fluid styled>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => {
                      setFormData({ ...formData, activeIndex: 0 })
                    }}
                  >
                    <Icon name='dropdown' />
                    Got a Feedback or Suggestion? Share it with us.
        </Accordion.Title>
                  <Accordion.Content
                    style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "0px" }}
                    active={activeIndex === 0}>
                    <Card.Body style={{ paddingTop: "0px" }}>
                      <div>
                        <div className="row" style={{ paddingTop: "0px" }}>
                          <div className="col">
                            <textarea rows="5" name="feedback" value={feedback} onChange={e => onChange(e)} className="form-control input" placeholder="Feedback" />
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ "paddingTop": "10px", paddingBottom: "0px", marginBottom: "0px" }}>
                          <div className="col">
                            <button onClick={onClickFeedbackSubmit} className="btn btn-secondary" type="button">Submit</button>
                          </div>
                        </div>

                      </div>
                    </Card.Body>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={() => {
                      setFormData({ ...formData, activeIndex: 1 })
                    }}
                  >
                    <Icon name='dropdown' />
                    If you want to see more content from a particular topic or from a new topic, you can request it here.

        </Accordion.Title>
                  <Accordion.Content
                    style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "0px" }}
                    active={activeIndex === 1}>
                    <Card.Body style={{ paddingTop: "0px" }}>
                      <div>

                        <div className="row" style={{ paddingTop: "0px" }}>
                          <div className="col">
                            <textarea rows="5" name="request" value={request} onChange={e => onChange(e)} className="form-control input" placeholder="Request" />
                          </div>
                        </div>

                        <div className="row" style={{ "paddingTop": "10px" }}>
                          <div className="col">
                            <button onClick={onClickRequestSubmit} className="btn btn-secondary" type="button">Submit</button>
                          </div>
                        </div>

                      </div>

                    </Card.Body>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={() => {
                      setFormData({ ...formData, activeIndex: 2 })
                    }}
                  >
                    <Icon name='dropdown' />
                    Do you want your success story to be featured on our website? Write a testimony here.
        </Accordion.Title>
                  <Accordion.Content
                    style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "0px" }}
                    active={activeIndex === 2}>
                    <Card.Body style={{ paddingTop: "0px" }}>
                      <div>

                        <div className="row" style={{
                          paddingTop: "0px"
                        }}>
                          <div className="col">
                            <textarea style={{ outlineColor: "red" }} rows="5" name="testimonial" value={testimonial} onChange={e => onChange(e)} className="form-control input" placeholder="Testimonial" />
                          </div>
                        </div>

                        <div className="row" style={{ "paddingTop": "10px" }}>
                          <div className="col">
                            <button onClick={onClickTestimonialSubmit} className="btn btn-secondary" type="button">Submit</button>
                          </div>
                        </div>

                      </div>

                    </Card.Body>
                  </Accordion.Content>
                </Accordion>


              </div>


            </div>




          </div>
          <br />
          <br />
          <br />
        </div>
      </div>

    </div >

  );
};

SupportPage.propTypes = {
  auth: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  addFeedback: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  schema: state.schema
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  addFeedback,
  setAlert,
  logout
})(SupportPage);