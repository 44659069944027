import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";
import "./css/dsl.css";
import { useHistory, withRouter } from "react-router-dom";
import ProblemItem from "./ProblemItem";
import { getProblems, setTags, inProgress } from "../../actions/schema";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Dropdown } from 'semantic-ui-react'
import { Button } from "react-bootstrap";
import { clearRunner } from "../../actions/runner";
import { getCurrentSchema } from "../../actions/schema";
import { useMediaQuery } from 'react-responsive'
import ScrollUpButton from "react-scroll-up-button";

import Avatar from 'react-avatar';
import { setAlert } from "../../actions/alert";
import { Container, Row, Col, Badge } from "react-bootstrap";

import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";

const bytsColor = "#E55D3D";

const Problems = ({
  getCurrentSchema,
  getProblems,
  setTags,
  storage,
  auth: { user },
  schema: { problems, progress, loadingProblems },
  match,
  logout,
  clearRunner,
  history,
  inProgress
}) => {
  useEffect(() => {
    getProblems({ tags: [], sortOptions: [] });
    getCurrentSchema();

    clearRunner();
    window.scrollTo(0, 0);
  }, []);
  //   }, [match, loadingTopic, getTopicDetails]);

  var smallScreen = useMediaQuery({ query: '(max-width: 850px)' });

  const [formData, setFormData] = useState({
    selectedTags: localStorage && localStorage.getItem("sTags") != null ? JSON.parse(localStorage.getItem("sTags")) : [],
    sortAscending: true
  });

  const { selectedTags, sortAscending } = formData;

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";
  const TagsLabel = ({ }) => {
    var problemsFound = false;

    problems && problems.problemsUnsolved && problems.problemsSolved && problems.rProblemsUnsolved && problems.rProblemsSolved && [...problems.problemsUnsolved, ...problems.problemsSolved, ...problems.rProblemsUnsolved, ...problems.rProblemsSolved].forEach(problem => {
      var problemTags = problem.tags;


      var diffMatch = false;
      var actualTagsCount = 0;
      var diffTagsCount = 0;
      var checkSuccessCount = 0;
      var checkDiffSuccessCount = 0;
      var solvedUnsolvedTagsCount = 0;

      if (problemTags && problemTags.length > 0 && selectedTags && selectedTags.length > 0) {
        var processedLength = selectedTags.length;

        for (var i = 0; i < selectedTags.length; i++) {
          console.log("Checking " + selectedTags[i]);

          if (selectedTags[i] == "Solved" || selectedTags[i] == "Unsolved") {
            processedLength--;
            solvedUnsolvedTagsCount++;

          } else if (selectedTags[i] == "Easy" || selectedTags[i] == "Medium" || selectedTags[i] == "Hard") {
            diffTagsCount++;
            if (problemTags.includes(selectedTags[i])) {
              diffMatch = true;
              checkDiffSuccessCount++;
            }

          } else if (problemTags.includes(selectedTags[i])) {
            checkSuccessCount++;
          }
        }

        if (
          ((processedLength == diffTagsCount) && (checkDiffSuccessCount > 0))
          ||
          ((checkSuccessCount > 0) && (!(diffTagsCount > 0) || diffMatch))
          ||
          ((selectedTags.length == solvedUnsolvedTagsCount))
        ) {

          var done = (problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false);
          if (selectedTags.includes("Solved")) {
            if (done) {
              problemsFound = true;
            } else if (selectedTags.includes("Unsolved")) {
              if (!(done)) {
                problemsFound = true;
              }
            }
          } else if (selectedTags.includes("Unsolved")) {
            if (!(done)) {
              problemsFound = true;
            }
          } else {
            problemsFound = true;
          }

        }
      }


    });

    if (problemsFound || !selectedTags || selectedTags.length == 0) {
      return <Fragment></Fragment>;
    }

    return (
      <h3 style={{ paddingLeft: "45px" }}>
        No problems with the selected tags combination found
      </h3>
    );
  }

  return problems === null && loadingProblems ? (
    <Fragment>

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">

                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={e => {
                    e.preventDefault();
                    console.log("onClickBack!");
                    history.push("/dashboard");
                  }} className="far fa-arrow-alt-circle-left fa-2x"
                >

                </i>
              </Navbar.Collapse>

            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise" style={{ color: bytsColor }}>Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
              </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section ><br />
      <br />
      <Spinner />
    </Fragment>
  ) : (
      <Fragment>

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">

                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={e => {
                      e.preventDefault();
                      console.log("onClickBack!");
                      history.push("/dashboard");
                    }} className="far fa-arrow-alt-circle-left fa-2x"
                  >

                  </i>
                </Navbar.Collapse>

              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" >Learn</Nav.Link>
                  <Nav.Link href="/practise" style={{ color: bytsColor }}>Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >

        <div className="bodyz">
          <div className="">
            <div className="container">


              <div className="row align-items-center pt-2 pt-lg-5">
                <div className="col-12 col-md-8 col-lg-7">
                  {progress && progress.practiseLink != null && progress != null ? (
                    <Fragment>
                      {" "}
                      <h2>Continue solving the problem!</h2>
                      <p className="lead">{progress.metaPractise}</p>
                      <RouteLink
                        to={progress.practiseLink}
                        onClick={() => {
                          console.log("CLICKED");

                          setAlert(
                            "Successfully restored data from your last session.",
                            "success"
                          );
                        }}
                      >
                        <Button variant="outline-secondary">
                          Continue last session
                      </Button>{" "}
                      </RouteLink>
                    </Fragment>
                  ) : (
                      <Fragment>
                        {" "}
                        <h2>Are you ready?</h2>
                        <p className="lead">
                          This is your first time using the Byts tool for Problems. We suggest
                          you to start practising the problems from Easy to Hard.
                    </p>
                      </Fragment>
                    )}
                </div>

                <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                  <img
                    alt="image"
                    className="img-fluid"
                    src="/imgs/draws/git.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />    <br />

        <div className={smallScreen ? "container" : "wrapper"}>
          {<div className={smallScreen ? "" : "sidebar containerTags"} style={{ paddingLeft: "20px", paddingTop: "10px" }}>
            <h3>Difficulty</h3>
            <Button
              onClick={(e) => {
                console.log("Clicked The Sort Ascending!");
                setFormData({ ...formData, sortAscending: true })

              }}
              style={{ marginRight: "5px", marginTop: "5px", borderRadius: "20px", fontSize: "13px" }}
              variant={sortAscending ? "secondary tag-tags" : "outline-tags btn-outline-tags"}>
              Easy to Hard
            </Button>
            <Button
              onClick={(e) => {
                console.log("Clicked The Sort Descending!");
                setFormData({ ...formData, sortAscending: false })
              }}
              style={{ marginRight: "5px", marginTop: "5px", borderRadius: "20px", fontSize: "13px" }}
              variant={!sortAscending ? "secondary tag-tags" : "outline-tags tag-outline-tags"}>
              Hard to Easy
            </Button>

            <div style={{ paddingBottom: "10px" }}></div>
            <h3>Tags</h3>
            {problems &&
              problems.tags &&
              problems.tags.map(tag => (
                <Button key={"B" + tag}
                  onClick={(e) => {
                    console.log("Clicked The Tag!");
                    var tSelectedTags = selectedTags;
                    if (selectedTags.includes(tag)) {
                      // tag already available
                      // remove it
                      tSelectedTags = [];
                      selectedTags.forEach(element => {
                        if (element != tag) {
                          tSelectedTags.push(element);
                        }
                      });

                      if (localStorage) {
                        localStorage.setItem("sTags", JSON.stringify(tSelectedTags));
                      }

                      setFormData({ ...formData, selectedTags: tSelectedTags });
                    } else {
                      tSelectedTags.push(tag);

                      if (localStorage) {
                        localStorage.setItem("sTags", JSON.stringify(tSelectedTags));
                      }

                      setFormData({ ...formData, selectedTags: tSelectedTags });
                    }

                    setTags({ tags: tSelectedTags });
                  }}
                  style={{ marginRight: "5px", marginTop: "5px", borderRadius: "20px", fontSize: "13px", textTransform: "capitalize" }}
                  variant={selectedTags && selectedTags.includes(tag) ? "secondary tag-tags" : "outline-secondary tag-outline-tags"}>
                  {tag}
                </Button>
              ))}

          </div>
          }
          {smallScreen ? <ScrollUpButton StopPosition={250} ShowAtPosition={251} /> : <Fragment></Fragment>}
          <div className={!smallScreen ? "main" : ""}>
            <div className="fdb-block align-items-center" style={smallScreen ? { paddingTop: "20px", } : { paddingTop: "10px" }}>
              <TagsLabel problems={problems} selectedTags={selectedTags} />
              <div className="col align-items-center text-center" style={{ paddingTop: "10px" }}>
                {!sortAscending && problems &&
                  problems.problemsUnsolved &&
                  [...problems.problemsUnsolved].reverse().map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}
                {!sortAscending && problems &&
                  problems.problemsSolved &&
                  [...problems.problemsSolved].reverse().map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}

                {sortAscending && problems &&
                  problems.problemsUnsolved &&
                  problems.problemsUnsolved.map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}
                {sortAscending && problems &&
                  problems.problemsSolved &&
                  problems.problemsSolved.map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}


                {!sortAscending && problems &&
                  problems.rProblemsUnsolved &&
                  [...problems.rProblemsUnsolved].reverse().map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}
                {!sortAscending && problems &&
                  problems.rProblemsSolved &&
                  [...problems.rProblemsSolved].reverse().map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}

                {sortAscending && problems &&
                  problems.rProblemsUnsolved &&
                  problems.rProblemsUnsolved.map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}
                {sortAscending && problems &&
                  problems.rProblemsSolved &&
                  problems.rProblemsSolved.map(problem => (
                    <ProblemItem style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", marginLeft: "0px", marginRight: "0px" }} key={problem.assetId} selectedTags={selectedTags} problem={problem} done={problems && problems.progressProblems && problems.progressProblems.includes(problem.assetId) ? true : false} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Fragment >
    );
};

Problems.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  topic: PropTypes.object,
  storage: PropTypes.object.isRequired,
  getProblems: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  clearRunner: PropTypes.func.isRequired,
  inProgress: PropTypes.func.isRequired,
  getCurrentSchema: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
  storage: state.storage
});

export default connect(mapStateToProps, {
  getProblems,
  logout,
  setTags,
  clearRunner,
  inProgress,
  getCurrentSchema
})(
  withRouter(Problems)
);
