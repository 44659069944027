import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { loadUser } from "../../actions/auth";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import { Card, ListGroup, Container, Row, Col } from "react-bootstrap";
import { Divider, Header, Icon, Table } from 'semantic-ui-react'
import Moment from "react-moment";
import moment from 'moment';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import uuid from "uuid/v4";

const MyAccount = ({
  deleteAccount,
  auth: { user },
  schema: { schema, loading },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  loadUser
}) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const initialData = {
    tasks: {
      'task-1': {
        id: 'task-1',
        content: "Do Task 1"
      },
      'task-2': {
        id: 'task-2',
        content: "Do Task 2"
      }
    },
    columns: {
      'column-1': {
        id: "column-1",
        titla: "To do",
        taskIds: ['task-1', 'task-2'],
      }
    },
    columnOrder: ['column-1'],
  }

  const onClickMyAccount = async e => {
    e.preventDefault();
  };

  const onClickPractise = async e => {
    e.preventDefault();
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";


  const itemsFromBackend1 = [
    { id: "op1", content: "int x = 1;" },
    { id: "op2", content: "int x = -1;" },
    { id: "op3", content: "x>5" },
    { id: "op4", content: "x<5" },
    { id: "op5", content: "x<=5" },
    { id: "op6", content: "x++" },
    { id: "op7", content: "x--" },
    { id: "op8", content: "printf(\"%d\", x)" },
    { id: "op9", content: "printf(\"%x\", d)" },



  ];

  const columnsFromBackend = {
    "qn1": {
      name: "Question 1",
      items: []
    },
    "qn2": {
      name: "Question 2",
      items: []
    },
    "qn3": {
      name: "Question 3",
      items: []
    },
    "qn4": {
      name: "Question 4",
      items: []
    },

    "options1": {
      name: "Options",
      items: itemsFromBackend1
    },
    "options2": {
      name: "Options",
      items: itemsFromBackend1
    },
    "options3": {
      name: "Options",
      items: itemsFromBackend1
    }
  };



  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];


      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };


  function Sample() {
    const [columns, setColumns] = useState(columnsFromBackend);

    return (
      <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >
                <h2>{column.name}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "lightgrey",
                            padding: 4,
                            width: "300px",
                            minHeight: 500
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        padding: 16,
                                        margin: "0 0 8px 0",
                                        minHeight: "50px",
                                        backgroundColor: snapshot.isDragging
                                          ? "#263B4A"
                                          : "#456C86",
                                        color: "white",
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      {item.content}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    );
  }
  const [columns, setColumns] = useState(columnsFromBackend);



  function Qn({ qnId }) {
    const column = columns[qnId];
    const columnId = qnId;

    var isDroppable = column && column.items && column.items.length == 0 ? true : false;
    return (
      <div >
        <div
          style={{

            alignItems: "center"
          }}
          key={columnId}
        >
          <div style={{ margin: 0 }}>
            <Droppable droppableId={columnId} key={columnId} isDropDisabled={!isDroppable}>
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver
                        ? "lightblue"
                        : "lightgrey",
                      padding: 4,
                      width: "300px",
                      height: 58
                    }}
                  >
                    {column.items.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: "none",
                                  padding: 16,
                                  margin: "0 0 0px 0",
                                  height: "50px",
                                  backgroundColor: snapshot.isDragging
                                    ? "#263B4A"
                                    : "#456C86",
                                  color: "white",
                                  ...provided.draggableProps.style
                                }}
                              >
                                {item.content}
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </div>
        </div>

      </div>
    );
  }

  function Options({ optionId }) {
    const column = columns[optionId];
    const columnId = optionId;
    return (
      <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>



        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
          key={columnId}
        >
          <div style={{ margin: 8 }}>
            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver
                        ? "lightblue"
                        : "lightgrey",
                      padding: 4,
                      width: "300px",
                      minHeight: 530
                    }}
                  >
                    {column.items.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: "none",
                                  padding: 16,
                                  margin: "0 0 8px 0",
                                  height: "50px",
                                  backgroundColor: snapshot.isDragging
                                    ? "#263B4A"
                                    : "#456C86",
                                  color: "white",
                                  ...provided.draggableProps.style
                                }}
                              >
                                {item.content}
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </div>
        </div>


      </div>
    );
  }


  var success = false;
  if (columns) {
    var qn1Choice = null;
    var qn2Choice = null;
    var qn3Choice = null;
    var qn4Choice = null;

    if (columns["qn1"] && columns["qn1"].items && columns["qn1"].items.length > 0 && columns["qn1"].items[0]) {
      qn1Choice = columns["qn1"].items[0].id;
    }
    if (columns["qn2"] && columns["qn2"].items && columns["qn2"].items.length > 0 && columns["qn2"].items[0]) {
      qn2Choice = columns["qn2"].items[0].id;
    }
    if (columns["qn3"] && columns["qn3"].items && columns["qn3"].items.length > 0 && columns["qn3"].items[0]) {
      qn3Choice = columns["qn3"].items[0].id;
    }
    if (columns["qn4"] && columns["qn4"].items && columns["qn4"].items.length > 0 && columns["qn4"].items[0]) {
      qn4Choice = columns["qn4"].items[0].id;
    }


    if (qn1Choice == "op1" && qn2Choice == "op5" && qn3Choice == "op8" && qn4Choice == "op6") {
      success = true;
    }

  }

  return (
    <div id="home">
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{ paddingTop: "20px", paddingBottom: "20px" }} >
          <Navbar.Brand href="/">
            <img src="/imgs/bytsLogo.png" height="40" alt="image" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav className="mr-auto justify-content-center" >
              <Nav.Link href="/" >Home</Nav.Link>
              <Nav.Link href="/learn" >Learn</Nav.Link>
              <Nav.Link href="/practise">Practise</Nav.Link>
              <Nav.Link href="/support">Support</Nav.Link>

            </Nav>

            <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

            <Dropdown style={{ marginRight: "10px" }} direction="left" text={userName}>
              <Dropdown.Menu>
                <Dropdown.Item href="/myaccount" style={{ color: "#2d96f1", textDecoration: "none" }}>My Account</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Navbar.Collapse>
        </Navbar></section><br />
      <br />

      <div className="bodyz">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="h2">Drag & Drop Tech Demo</p>
                <h2>Drop the options into the blanks such that the program when run prints "12345"</h2>
              </div>
            </div>
            <br />
            <DragDropContext

              onDragEnd={result => onDragEnd(result, columns, setColumns)}
              onDragUpdate={() => { }
                /*...*/
              }
            >
              <br />
              <Row >
                <Col style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}>
                  <div>
                    <br />

                    <Row>
                      <Qn qnId="qn1" />
                    </Row>
                    <div style={{ height: '10px' }}></div>
                    <Row>
                      <h2 style={{ paddingTop: "5px", paddingRight: "10px" }}>while (</h2>

                      <Qn qnId="qn2" />

                      <h2 style={{ paddingTop: "5px", paddingLeft: "10px" }}>{") {"}</h2>
                    </Row>
                    <div style={{ height: '10px' }}></div>
                    <Row>
                      <div style={{ paddingLeft: "40px" }}></div><Qn qnId="qn3" />
                    </Row>
                    <div style={{ height: '10px' }}></div>
                    <Row>
                      <div style={{ paddingLeft: "40px" }}></div><Qn qnId="qn4" />
                    </Row>
                    <Row>

                      <h2 style={{ paddingTop: "5px" }}>{"}"}</h2>
                    </Row>
                  </div>
                </Col>
                <Col><Options optionId={"options1"} />
                </Col>
              </Row>

              <Divider horizontal>
                <Header as='h4'>
                  Answer Status (Debugging)
                 </Header>
              </Divider>

              <Row style={{ paddingLeft: "15px", paddingRight: '15px' }}>
                {success ? <h3>Awesome! Your answer is correct!</h3> : <h3>Incorrect Answer</h3>}
              </Row>

              <div style={{ paddingTop: "20px" }}>


              </div>
            </DragDropContext>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div >
  );
};

MyAccount.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  loadUser,
  logout
})(MyAccount);

/*
 */

/*
<br />
          <br />
          <Row>

          </Row>
          <br />
*/