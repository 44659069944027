import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
const NotFound = ({
  history
}) => {
  return (
    <Fragment>
      <div id="home">
        <section
          className=" bodyz"
          style={{ paddingBottom: "0px", paddingTop: "20px" }}
          id="liveassistance"
          data-aos="zoom-in"
          data-aos-offset="220"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div className="container">
            <div className="row justify-content-center pb-xl-5">
              <div className="col-8 col-sm-8 text-center">
                <img
                  alt="image"
                  className="img-fluid mt-5"
                  src="/imgs/draws/404.svg"
                  style={{ height: "500px", width: "auto", paddingTop: "100px" }}
                />
                <h3>Sorry. This page does not exist!</h3>
                <p className="mt-5 mt-sm-4">
                  <button className="btn btn-outline-secondary" onClick={e => {
                    e.preventDefault();
                    history.goBack();
                  }}>
                    Go Back
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default NotFound;
