import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Register from "../auth/Register.jsx";
import Login from "../auth/Login.jsx";
import ForgotPassword from "../auth/ForgotPassword";
import DashboardSelection from "../dashboard/DashboardSelection";
import NotFound from "../layout/NotFound";
import EmailConfirmation from "../layout/EmailConfirmation";
import ChangePassword from "../auth/ChangePassword";
import PrivateRoute from "../routing/PrivateRoute";
import Learn from "../learn/Learn";
import LearnTopic from "../learn/LearnTopic";
import LearnPage from "../learn/LearnPage";
import MyAccount from "../account/MyAccount";
import Payment from "../account/Payment";
import PaymentPage from "../account/PaymentPage";
import PaymentSucceeded from "../account/PaymentSucceeded";
import Problems from "../practise/Problems";
import ProblemsPage from "../practise/ProblemsPage";
import SupportPage from "../supportpage/SupportPage";
import NotificationsPage from "../notificationspage/NotificationsPage";
import { ObserverGroup, Observer } from 'react-tweenful';
import ForgotPasswordAccount from "../account/ForgotPasswordAccount";
import PrivacyPolicy from "../supportpage/PrivacyPolicy";

import Dev1 from "../dev/Dev1";

const Routes = () => {
  return (
    <Fragment>
      <ObserverGroup>
        <Observer.div

          className="key-wrapper"
          duration={300}
          style={{ opacity: 0 }}
          mount={{ opacity: 1 }}
          unmount={{ opacity: 0 }}
          easing={'easeOutQuad'}
        >
          <Switch>
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/emailconfirmed" component={EmailConfirmation} />
            <Route exact path="/recover" component={ChangePassword} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />

            <PrivateRoute exact path="/dashboard" component={DashboardSelection} />
            <PrivateRoute exact path="/learn" component={Learn} />
            <PrivateRoute
              exact
              path="/learn/:subtopicId/:assetId"
              component={LearnPage}
            />
            <PrivateRoute path="/learn/:topicId" component={LearnTopic} />

            <PrivateRoute exact path="/practise" component={Problems} />
            <PrivateRoute path="/practise/:from/:assetId" component={ProblemsPage} />


            <PrivateRoute exact path="/myaccount" component={MyAccount} />
            <PrivateRoute exact path="/changepassword" component={ForgotPasswordAccount} />
            <PrivateRoute exact path="/payment" component={Payment} />
            <PrivateRoute exact path="/checkout/:type" component={PaymentPage} />
            <PrivateRoute exact path="/paymentsucceeded" component={PaymentSucceeded} />

            <PrivateRoute exact path="/support" component={SupportPage} />

            <PrivateRoute exact path="/notifications" component={NotificationsPage} />


            <PrivateRoute exact path="/notfound" component={NotFound} />

            <PrivateRoute exact path="/dev" component={Dev1} />

            <Route component={NotFound} />
          </Switch>
        </Observer.div>
      </ObserverGroup>

    </Fragment>
  );
};

export default Routes;
