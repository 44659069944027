import {
  GET_SETTINGS,
  GET_SETTINGS_ERROR,
  SET_SETTINGS,
  SET_SETTINGS_ERROR
} from "../actions/types";

const initialState = {
  settings: {},
  loadingSettings: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SETTINGS:
      return {
        ...state,
        loadingSettings: false,
        settings: payload
      };
    case SET_SETTINGS:
      return {
        ...state,
        loadingSettings: false
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        loadingSettings: false,
        settings: { codeLanguage: 0, codeTheme: 0 }
      };
    case SET_SETTINGS_ERROR:
      return {
        ...state,
        loadingSettings: false
      };

    default:
      return state;
  }
}
