import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Redirect,
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions,
} from "react-alert";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { loginUsingGoogle } from "../../actions/auth";
import { GoogleLogin } from "react-google-login";
import { Divider, Header, Icon, Table } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

const Register = ({
  register,
  isAuthenticated,
  loginUsingGoogle,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  const { name, email, password, password2 } = formData;

  const successResponseGoogle = (response) => {
    if (response && response.tokenId) {
      var tokenId = response.tokenId;
      loginUsingGoogle({ idToken: tokenId });

      setAlert("Logged in successfully.", "info");
    } else {
      console.log("SUCCESS RESPONSE");
      console.log(response);

      if (response && response.details) {
        {
          setAlert(response.details, "error");
        }
      } else if (response && response.message) {
        {
          setAlert(response.message, "error");
        }
      } else {
        setAlert(
          "Retry. There was an issue with Google Signin. Code: 001",
          "error"
        );
      }
    }
  };

  const failureResponseGoogle = (response) => {
    console.log("FAIL RESPONSE");
    console.log(response);

    if (
      response &&
      response.details == "Cookies are not enabled in current environment." &&
      response.error == "idpiframe_initialization_failed"
    ) {
      setAlert(
        "You have disabled 3rd Party Cookies in your browser. Please enable it.",
        "error"
      );
    } else if (response && response.details) {
      {
        setAlert(response.details, "error");
      }
    } else if (response && response.message) {
      {
        setAlert(response.message, "error");
      }
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const alert = useAlert();

  const onSubmit = async (e) => {
    //e.preventDefault();
    console.log("Processing!");
    if (!name || !email || !password || !password2) {
      // dont prevent default
    } else if (password != password2) {
      e.preventDefault();
      alert.show("The passwords do not match", "error");
    } else {
      e.preventDefault();
      register({ name, email, password });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <RouteLink className="btn btn-outline-dark m-1" to="/">
                  Go Back
                </RouteLink>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>
      <div
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
        data-aos-duration="200"
      >
        <section className="fdb-block py-0">
          <div
            className="container py-5 my-5 bodyz"
            style={{ backgroundImage: "url(imgs/shapes/2.svg)" }}
          >
            <div className=" row justify-content-end">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5 text-left">
                <div className="fdb-box">
                  <div className="row">
                    <div className="col">
                      <h1>Register</h1>
                      <p className="lead font-weight-normal">
                        Create a new account to start using Byts.
                      </p>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row align-items-center">
                      <div className="col mt-4">
                        <input
                          type="text"
                          className="form-control  input"
                          style={{ fontWeight: "normal" }}
                          placeholder="Name"
                          name="name"
                          value={name}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col">
                        <input
                          type="email"
                          className="form-control  input"
                          style={{ fontWeight: "normal" }}
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col">
                        <input
                          type="password"
                          className="form-control input"
                          style={{ fontWeight: "normal" }}
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                      <div className="col">
                        <input
                          type="password"
                          className="form-control input"
                          style={{ fontWeight: "normal" }}
                          placeholder="Confirm Password"
                          name="password2"
                          value={password2}
                          onChange={(e) => onChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-start mt-4">
                      <div className="col">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            style={{ fontWeight: "normal" }}
                          >
                            By continuing, you agree to Byts'{" "}
                            <a href="">Terms and Conditions</a> and{" "}
                            <a href="">Privacy Policy</a>.
                          </label>
                        </div>

                        <div style={{ marginTop: "10px" }}></div>

                        <RouteLink to="/login">
                          Already have an account? Log in into your account.
                        </RouteLink>
                        <button
                          className="btn btn-outline-secondary"
                          type="submit"
                          style={{ marginTop: "10px" }}
                          onClick={(e) => onSubmit(e)}
                          onSubmit={(e) => onSubmit(e)}
                        >
                          <h4>Create an Account</h4>
                        </button>
                      </div>
                    </div>
                    <Divider horizontal>
                      <Header as="h4">or</Header>
                    </Divider>

                    <div className="row">
                      <div className="col">
                        <GoogleLogin
                          clientId="189694853652-gsf3qffkv8odbkuhkdb96bsb7ro2rjtl.apps.googleusercontent.com"
                          buttonText="Signup using Google"
                          cookiePolicy={"http://byts.co.in"}
                          onSuccess={successResponseGoogle}
                          onFailure={failureResponseGoogle}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loginUsingGoogle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  loginUsingGoogle,
  setAlert,
  register,
})(Register);
