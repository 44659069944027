import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  RESEND_MAIL,
  RESEND_MAIL_ERROR,
  FP_MAIL,
  FP_MAIL_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  GSIGNIN_SUCCESS,
  GSIGNIN_FAIL,
  CLEAR_PROGRESS,
  GET_NOTIFICATIONS,
  NOTIFICATIONS_ERROR
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { startLoader, stopLoader } from "./loader";
// Load User
export const loadUser = () => async dispatch => {

  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
  } catch (err) {
  }

  try {
    const res = await axios.get("/api/auth");

    if (res.data && res.data.expired) {
      dispatch(setAlert("One of your subscribed courses have expired. Please renew it to access our Premium Content.", "info"));
    } else if (res.data && res.data.oldSession) {
      dispatch(setAlert("Your session has expired. Please login again.", "info"));
      dispatch({
        type: AUTH_ERROR
      });

      return;
    }


    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {


    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register = ({ name, email, password }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ name, email, password });

  try {
    const res = await axios.post("/api/users", body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = ({ email, password }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    } else if (err.response.data) {
      dispatch(setAlert(err.response.data, "error"));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const getNotifications = () => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };


  try {
    const res = await axios.get("/api/auth/notifications", config);

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    } else if (err.response.data) {
      dispatch(setAlert(err.response.data, "error"));
    }

    dispatch({
      type: NOTIFICATIONS_ERROR
    });
  }
};


// Login User
export const loginUsingGoogle = ({ idToken }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ idToken });

  try {


    console.log("loginUsingGoogle");

    // we have to send the id_token 
    const res = await axios.post("/api/auth/gsignin", body, config);

    dispatch({
      type: GSIGNIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    } else if (err.response.data) {
      dispatch(setAlert(err.response.data, "error"));
    }

    dispatch({
      type: GSIGNIN_FAIL
    });
  }
};

// Login User
export const resendMail = () => async dispatch => {
  console.log("resendMail");
  dispatch(startLoader());
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get("/api/users/resend", config);
    dispatch(stopLoader());
    dispatch({
      type: RESEND_MAIL,
      payload: res.data
    });

    dispatch(
      setAlert(
        "Confirmation mail has been sent to your email. Please check it.",
        "success"
      )
    );

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: RESEND_MAIL_ERROR
    });
  }
};

// Login User
export const sendPasswordRecoveryMail = email => async dispatch => {
  console.log("sendPasswordRecoveryMail: " + email);
  dispatch(startLoader());
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    const res = await axios.get(
      "/api/users/recoverymail?email=" + email,
      config
    );
    dispatch(stopLoader());
    dispatch({
      type: FP_MAIL,
      payload: res.data
    });

    dispatch(
      setAlert(
        "Password Recovery mail has been sent to your email. Please check it.",
        "success"
      )
    );

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch(stopLoader());

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: FP_MAIL_ERROR
    });
  }
};

export const changePasswordAccount = ({
  oldpassword,
  password,
  history
}) => async dispatch => {

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ oldpassword, password });

  try {
    const res = await axios.post("/api/users/changepasswordaccount", body, config);

    history.push("/myaccount");
    dispatch(
      setAlert(
        "Your password has been successfully changed.",
        "success"
      )
    );

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log("Errors");
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }
  }
};


// Login User
export const changePassword = ({
  email,
  recKey,
  password,
  history
}) => async dispatch => {
  console.log("changePassword: " + email);
  dispatch(startLoader());
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ recKey, email, password });

  try {
    const res = await axios.post("/api/users/changepassword", body, config);
    dispatch(stopLoader());
    dispatch({
      type: CHANGE_PASSWORD,
      payload: res.data
    });

    history.push("/login");
    dispatch(
      setAlert(
        "Your password has been successfully changed. Try logging in now.",
        "success"
      )
    );

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      console.log("Errors");
      errors.forEach(error => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: CHANGE_PASSWORD_ERROR
    });
  }
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  console.log("LOGOUT");
  if (window.confirm("Are you sure you want to logout?")) {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAR_PROGRESS });

    if (localStorage) {
      localStorage.clear();
    }

    dispatch(setAlert("You have been logged out of Byts.", "info"));
  }
};
