import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AlertPlain = ({ alerts }) => {

  var alert = "";
  var alertId = "";
  var alertType = "";
  var msg = "";

  if (alerts !== null &&
    alerts.length > 0) {
    alert = alerts[0];
    alertId = alert.id;
    alertType = alert.alertType;
    msg = alert.msg;
  }

  return (

    <p key={alertId}>
      {msg}
    </p>
  );
};

AlertPlain.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alertplain
});

export default connect(mapStateToProps)(AlertPlain);
