import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { changePassword } from "../../actions/auth";
import PropTypes from "prop-types";
import { Link, animateScroll as scroll } from "react-scroll";
import {
  Redirect,
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions,
} from "react-alert";
import queryString from "query-string";
import { Navbar, Row, Col, Nav } from "react-bootstrap";

const Register = ({
  history,
  setAlert,
  changePassword,
  isAuthenticated,
  location,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  const { email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const alert = useAlert();

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Processing!");
    if (!password || password.length < 6) {
      alert.show("The password must have a minimum of 6 characters", {
        type: "error",
      });
    } else if (password !== password2) {
      alert.show("The passwords do not match", { type: "error" });
    } else {
      changePassword({
        email: queryString.parse(location.search).email,
        password,
        recKey: queryString.parse(location.search).key,
        history,
      });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <ul className="navbar-nav ml-auto justify-content-end">
                  <li className="nav-item">
                    <RouteLink className="btn btn-outline-dark m-1" to="/login">
                      Already have an account?{" "}
                    </RouteLink>
                  </li>
                </ul>

                <RouteLink className="btn btn-outline-dark m-1" to="/">
                  Go Back
                </RouteLink>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>

      <div
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
        data-aos-duration="200"
      >
        <section className="fdb-block py-0">
          <div
            className="container py-5 my-5 bodyz"
            style={{ backgroundImage: "url(imgs/shapes/2.svg)" }}
          >
            <div className=" row justify-content-end">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5 text-left">
                <div className="fdb-box">
                  <div className="row">
                    <div className="col">
                      <h1>Change Password</h1>
                      <p className="lead font-weight-normal">
                        {"You can change your Byts Account password below."}
                      </p>
                      <h3>{"" + queryString.parse(location.search).email}</h3>
                    </div>
                  </div>

                  <div className="row align-items-center mt-4">
                    <div className="col">
                      <input
                        type="password"
                        className="form-control input"
                        style={{ fontWeight: "normal" }}
                        placeholder="New Password"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="password"
                        className="form-control input"
                        style={{ fontWeight: "normal" }}
                        placeholder="Confirm Password"
                        name="password2"
                        value={password2}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-start mt-4">
                    <div className="col">
                      <button
                        className="btn btn-outline-secondary mt-4"
                        type="button"
                        onClick={(e) => onSubmit(e)}
                        onSubmit={(e) => onSubmit(e)}
                      >
                        <h4>Change Password</h4>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, changePassword })(Register);
