import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions
} from "react-alert";

const Alert = ({ alerts }) => {
  const alert = useAlert();

  if (alerts && alerts.valid == true) {
    alert.show(alerts.msg, {
      type: alerts.alertType,

    });
  }

  return <div></div>;
};

Alert.propTypes = {
  alerts: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
