import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_SETTINGS,
  GET_SETTINGS_ERROR,
  SET_SETTINGS,
  SET_SETTINGS_ERROR
} from "./types";
import setAuthToken from "../utils/setAuthToken";

export const getSettings = () => async dispatch => {
  try {
    const res = await axios.get("/api/settings/getsettings");

    dispatch({
      type: GET_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const setSettings = ({ codeLanguage, codeTheme }) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const body = JSON.stringify({ codeLanguage, codeTheme });

    const res = await axios.post("/api/settings/setsettings", body, config);

    dispatch({
      type: SET_SETTINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_SETTINGS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
