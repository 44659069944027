import {
  GET_ORDER,
  GET_ORDER_ERROR,
  GET_PRICINGPLANS,
  GET_PRICINGPLANS_ERROR
} from "../actions/types";

const initialState = {
  order: {},
  pricingPlans: {},
  loadingOrder: true,
  loadingPricingPlans: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ORDER:
      return {
        ...state,
        loadingOrder: false,
        order: payload
      };
    case GET_PRICINGPLANS:
      return {
        ...state,
        loadingPricingPlans: false,
        pricingPlans: payload,
        order: null,
        loadingOrder: true,
      }


    default:
      return state;
  }
}
