import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";
import "./css/dsl.css";
import { useHistory } from "react-router-dom";
import TopicItem from "./TopicItem";
import Sidebar from "react-sidebar";
import MaterialTitlePanel from "./material_title_panel";
import AssetPane from "./AssetPane";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Dropdown } from 'semantic-ui-react'
import { clearRunner } from "../../actions/runner";
import Avatar from 'react-avatar';
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'
import Button from "react-bootstrap/Button";

import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import { getSubtopicDetails, inProgress } from "../../actions/schema";

import { saveSubtopic, saveAsset } from "../../actions/storage";

const bytsColor = "#E55D3D";


const styles = {
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },

  sidebar: {
    width: 256
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "white"
  }
};

const LearnPage = ({
  getSubtopicDetails,
  auth: { user },
  schema: { topic, subtopic, loadingSubtopic },
  location,
  saveSubtopic,
  saveAsset,
  storage,
  match,
  inProgress,
  logout,
  clearRunner,
  history,

}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("useEffect Learn Page");

    getSubtopicDetails(match.params.subtopicId);

    clearRunner();
  }, []);

  var dockedFlag = useMediaQuery({ query: '(max-width: 800px)' });

  const [formData, setFormData] = useState({
    open: false,

  });

  const { open } = formData;

  const contentHeader = (
    <span>
      {!dockedFlag && (
        <a
          onClick={() => {
            console.log("Wow");
            open ? setFormData({ open: false }) : setFormData({ open: true })
          }}
          href="#"
          style={styles.contentHeaderMenuLink}
        >
          =
        </a>
      )}
      <span>Menu</span>
    </span>
  );

  const onClickShowSidebar = async e => {
    e.preventDefault();
    console.log("onClickShowSidebar!");

    setFormData({ open: true });
  };
  const onClickLearn = async e => {
    e.preventDefault();
    console.log("onClickLearn!");
  };

  const style = styles.sidebar;

  const onClickPractise = async e => {
    e.preventDefault();
    console.log("onClickPractise!");
  };




  var assetToShow;
  var assetFinishedCount = 0;
  if (match.params.assetId) {
    assetToShow = { _id: "" };
  }

  if (subtopic) {
    subtopic.assets.map(asset => {
      if (asset.assetId === match.params.assetId) {
        assetToShow = asset;
      }

      if (asset.finished) {
        assetFinishedCount++;
      }
    });

    console.log("assetFinishedCount: " + assetFinishedCount)
  }

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";
  if (!loadingSubtopic && subtopic == null) {
    return <Redirect to="/notfound" />
  }

  return subtopic === null && loadingSubtopic ? (
    <div>

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">

                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={e => {
                    e.preventDefault();
                    console.log("onClickBack!");

                    if (topic && topic.topicId) {
                      history.push("/learn/" + topic.topicId)
                    } else {
                      history.goBack();
                    }


                  }} className="far fa-arrow-alt-circle-left fa-2x"
                >

                </i>
              </Navbar.Collapse>

            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" style={{ color: bytsColor }}>Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >


      <br />
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div>

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">

                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={e => {
                      e.preventDefault();
                      console.log("onClickBack!");

                      if (topic && topic.topicId) {
                        history.push("/learn/" + topic.topicId)
                      } else {
                        history.goBack();
                      }


                    }} className="far fa-arrow-alt-circle-left fa-2x"
                  >

                  </i>
                </Navbar.Collapse>

              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" style={{ color: bytsColor }}>Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none" }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >

        <br />
        <br />
        <br />

        <Sidebar
          onSetOpen={(val) => {
            setFormData({ open: val })
          }}
          open={open}
          sidebar={
            <MaterialTitlePanel title={contentHeader} style={{ ...style, paddingTop: "0px" }}>

              <div style={styles.content}>
                <RouteLink
                  style={styles.sidebarLink}
                >

                </RouteLink>
                <RouteLink
                  style={styles.sidebarLink}
                  onClick={() => {
                    if (dockedFlag) {
                      setFormData({ open: false });
                    }
                    inProgress({
                      learnLink:
                        "/learn/" + match.params.subtopicId + "/root",
                      subtopicTitle: subtopic.topic,
                      topicTitle: subtopic.hTopic,
                      assetId: "root",
                    });
                  }}
                  to={{
                    pathname: "/learn/" + match.params.subtopicId + "/root",
                    topic: {
                      title: subtopic.topic,
                      nv: subtopic.nv,
                      np: subtopic.np,
                      nt: subtopic.nt,
                      nm: subtopic.nm
                    },
                    asset: { _id: "" },
                    mcqs: null
                  }}
                >
                  {match.params.assetId == "root" ? (
                    <div
                      style={{
                        color: bytsColor
                      }}
                    >
                      <h4>{"Overview"} </h4>
                    </div>
                  ) : (
                      <div>
                        <div>
                          <h4>{"Overview"} </h4>
                        </div>
                      </div>
                    )}
                </RouteLink>
                {subtopic && subtopic.assets && subtopic.assets.length > 0 ? (
                  subtopic.assets.map(asset =>
                    asset.type != "mcqs" ? (
                      <RouteLink
                        style={styles.sidebarLink}
                        onClick={() => {
                          if (dockedFlag) {
                            setFormData({ open: false });
                          }
                          if (!asset.premium) {
                            inProgress({
                              learnLink:
                                "/learn/" +
                                match.params.subtopicId +
                                "/" +
                                asset.assetId,
                              topicTitle: subtopic.hTopic,
                              subtopicTitle: subtopic.topic,

                              assetId: asset.assetId
                            });
                          }
                        }}
                        to={{
                          pathname:
                            "/learn/" +
                            match.params.subtopicId +
                            "/" +
                            asset.assetId,
                          topic: {
                            title: subtopic.topic,
                            nv: subtopic.nv,
                            np: subtopic.np,
                            nt: subtopic.nt,
                            nm: subtopic.nm
                          },
                          asset: asset,
                          mcqs: null
                        }}
                      >
                        {asset.assetId == match.params.assetId ? (
                          <div
                            style={{
                              color: bytsColor
                            }}
                          >
                            <h4
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                height: "auto",
                              }}
                            >
                              {asset.assetData.title}{" "}
                              {asset.premium ? <i
                                style={{
                                  color: bytsColor,
                                  alignSelf: "center",
                                  paddingLeft: "20px"
                                }}
                                class="fas fa-lock"
                              ></i> : asset.finished ? (
                                <i
                                  style={{
                                    color: "#51a423",
                                    alignSelf: "center",
                                    paddingLeft: "20px"
                                  }}
                                  class="fas fa-check"
                                ></i>
                              ) : (
                                    <div></div>
                                  )}
                            </h4>{" "}
                            <div style={{ fontSize: "10px" }}>
                              {asset.type.toUpperCase()}
                            </div>
                          </div>
                        ) : (
                            <div>
                              <h4
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  height: "auto"
                                }}
                              >
                                {asset.assetData.title}{" "}

                                {asset.premium ? <i
                                  style={{
                                    color: "#000000",
                                    alignSelf: "center",
                                    paddingLeft: "20px"
                                  }}
                                  class="fas fa-lock"
                                ></i> : asset.finished ? (
                                  <i
                                    style={{
                                      color: "#51a423",
                                      alignSelf: "center",
                                      paddingLeft: "20px"
                                    }}
                                    class="fas fa-check"
                                  ></i>
                                ) : (
                                      <div></div>
                                    )}

                              </h4>

                              <div style={{ fontSize: "10px" }}>
                                {asset && asset.type ? (
                                  asset.type.toUpperCase()
                                ) : (
                                    <div></div>
                                  )}
                              </div>
                            </div>
                          )}
                      </RouteLink>
                    ) : asset.type == "mcqs" ? (
                      <RouteLink
                        style={styles.sidebarLink}

                        onClick={() => {
                          if (dockedFlag) {
                            setFormData({ open: false });
                          }
                          if (!asset.premium) {
                            inProgress({
                              learnLink:
                                "/learn/" +
                                match.params.subtopicId +
                                "/" +
                                asset.assetId,
                              topicTitle: subtopic.hTopic,
                              subtopicTitle: subtopic.topic,
                              assetId: asset.assetId
                            });
                          }
                        }}
                        to={{
                          pathname:
                            "/learn/" +
                            match.params.subtopicId +
                            "/" +
                            asset.assetId,
                          topic: {
                            title: subtopic.topic,
                            nv: subtopic.nv,
                            np: subtopic.np,
                            nt: subtopic.nt,
                            nm: subtopic.nm
                          },
                          asset: asset
                        }}
                      >
                        {asset.assetId == match.params.assetId ? (
                          <div>
                            <div
                              style={{
                                ...styles.divider
                              }}
                            />
                            <div
                              style={{ ...styles.sidebarLink, color: bytsColor }}
                            >
                              {" "}
                              <h4
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  height: "19px"
                                }}
                              >
                                {" "}
                              Review Quiz
                              {asset.premium ? <i
                                  style={{
                                    color: bytsColor,
                                    alignSelf: "center",
                                    paddingLeft: "20px"
                                  }}
                                  class="fas fa-lock"
                                ></i> : asset.finished ? (
                                  <i
                                    style={{
                                      color: "#51a423",
                                      alignSelf: "center",
                                      paddingLeft: "20px"
                                    }}
                                    class="fas fa-check"
                                  ></i>
                                ) : (
                                      <div></div>
                                    )}
                              </h4>
                            </div>
                          </div>
                        ) : (
                            <div>
                              <div style={styles.divider} />
                              <div style={{ ...styles.sidebarLink }}>
                                <h4
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    height: "19px"
                                  }}
                                >
                                  Review Quiz
                                  {asset.premium ? <i
                                    style={{
                                      color: "#000000",
                                      alignSelf: "center",
                                      paddingLeft: "20px"
                                    }}
                                    class="fas fa-lock"
                                  ></i> : asset.finished ? (
                                    <i
                                      style={{
                                        color: "#51a423",
                                        alignSelf: "center",
                                        paddingLeft: "20px"
                                      }}
                                      class="fas fa-check"
                                    ></i>
                                  ) : (
                                        <div></div>
                                      )}
                                </h4>
                              </div>
                            </div>
                          )}
                      </RouteLink>
                    ) : (
                          <div></div>
                        )
                  )
                ) : (
                    <div style={styles.sidebarLink}>No Asset Found</div>
                  )}
              </div>
            </MaterialTitlePanel>
          }
          docked={!dockedFlag}

        >
          <div style={{ paddingTop: "80px" }} >
            <br />

            {dockedFlag && <div style={{ paddingLeft: "10px" }}>
              <Button
                onClick={e => {
                  onClickShowSidebar(e);
                }}
                variant="outline-secondary"
              >
                Show Sidebar
                  </Button>
            </div>}
            <AssetPane
              asset={assetToShow}
              topic={{
                title: subtopic.topic,
                nv: subtopic.nv,
                np: subtopic.np,
                nt: subtopic.nt,
                nm: subtopic.nm,
                finished: assetFinishedCount,
                total: (subtopic.nm == "0") ? parseInt(subtopic.nv) + parseInt(subtopic.nt) + 0 + parseInt(subtopic.np) : parseInt(subtopic.nv) + parseInt(subtopic.nt) + 1 + parseInt(subtopic.np)
              }
              }
            />
            <br />
            <br />
          </div>
        </Sidebar >
      </div >
    );
};

LearnPage.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  topic: PropTypes.object,
  storage: PropTypes.object.isRequired,
  saveSubtopic: PropTypes.func.isRequired,
  saveAsset: PropTypes.func.isRequired,
  getSubtopicDetails: PropTypes.func.isRequired,
  inProgress: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearRunner: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
  storage: state.storage
});

export default connect(mapStateToProps, {
  getSubtopicDetails,
  saveSubtopic,
  saveAsset,
  inProgress,
  logout,
  clearRunner,
})(LearnPage);
