import { Link, animateScroll as scroll } from "react-scroll";

import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, changePasswordAccount } from "../../actions/auth";
import Loader from "../layout/Loader";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";

import {
  Link as RouteLink,
  Redirect,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions
} from "react-alert";
import { Nav, NavDropdown, Navbar, Row, Col, Badge } from "react-bootstrap";

const bytsColor = "#E55D3D";

const ChangePasswordAccount = ({

  auth: { user },
  isAuthenticated,
  changePasswordAccount,
  loadUser,
  logout,
  history
}) => {

  const [formData, setFormData] = useState({

    password: "",
    password2: "",
    oldpassword: ""
  });

  const { password, password2, oldpassword } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const alert = useAlert();

  const onSubmit = async e => {
    e.preventDefault();
    console.log("Processing!");
    if (!oldpassword || !password || !password2) {
      alert.show("Please fill all the fields", { type: "error" });
    } else if (password != password2) {
      alert.show("The Confirm New Password field does not match with the New Password.", { type: "error" });
    } else {
      changePasswordAccount({ ...formData, history });
    }
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  return user == null ? (
    <div id="home">

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                    <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                    : <Fragment></Fragment>
                  }
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div id="home">

        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }} >
            <Col >
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav" >

              <Col style={{ paddingLeft: "0px" }}>

                <Nav className="justify-content-center">
                  <Nav.Link href="/" >Home</Nav.Link>
                  <Nav.Link href="/learn" >Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>

                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingTop: "0px" }} >
                  <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                      <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                      : <Fragment></Fragment>
                    }
                  </Nav.Link>

                  <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                  <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

          </Navbar>

        </section >
        <br />
        <br />



        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <section className="fdb-block" style={{ paddingTop: "0px" }}>
            <div
              className="container py-5 my-5 bodyz"
            >
              <div className=" ">
                <div className="">
                  <div className="fdb-box">
                    <div className="row">
                      <div className="col">
                        <h1>Change Password</h1>
                        <h2 className="h3">
                          {"You can change your Byts Account's password below."}
                        </h2>
                      </div>
                    </div>

                    <div className="row align-items-center mt-4">
                      <div className="col">
                        <input
                          type="password"
                          className="form-control input"
                          style={{ fontWeight: "bolder" }}
                          placeholder="Current Password"
                          name="oldpassword"
                          value={oldpassword}
                          onChange={e => onChange(e)}
                        />
                      </div>

                    </div>

                    <div className="row align-items-center mt-2">
                      <div className="col">
                        <input
                          type="password"
                          className="form-control input"
                          style={{ fontWeight: "bolder" }}
                          placeholder="New Password"
                          name="password"
                          value={password}
                          onChange={e => onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-2">
                      <div className="col">
                        <input
                          type="password"
                          className="form-control input"
                          style={{ fontWeight: "bolder" }}
                          placeholder="Confirm New Password"
                          name="password2"
                          value={password2}
                          onChange={e => onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-start mt-4">
                      <div className="col">
                        <button
                          className="btn btn-secondary mt-4"
                          type="button"
                          onClick={e => onSubmit(e)}
                          onSubmit={e => onSubmit(e)}
                        >
                          <h4>Change Password</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div >
    );
};

ChangePasswordAccount.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  changePasswordAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login, changePasswordAccount })(
  ChangePasswordAccount
);
