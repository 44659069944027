import { SET_ALERT, REMOVE_ALERT, CLEAR_ALL_ALERTS } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return payload;
    case REMOVE_ALERT:
      return {};
    case CLEAR_ALL_ALERTS:
      console.log("In REDUCER");
      return {};
    default:
      return state;
  }
}
