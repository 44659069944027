//import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "froala-design-blocks/dist/css/froala_blocks.css";
import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";
import React, { Fragment, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./components/layout/Landing";
import Routes from "./components/routing/Routes";
import Alert from "./components/layout/Alert";

// Redux
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { loadUser } from "./actions/auth";
import { setAlert } from "./actions/alert";

import setAuthToken from "./utils/setAuthToken";
import { PersistGate } from "redux-persist/integration/react";

import { ObserverGroup, Observer } from 'react-tweenful';

import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions
} from "react-alert";
import AlertTemplate from "./components/support/react_alert/AlertTemplate";

const { detect } = require('detect-browser');
const browser = detect();

const alertOptions = {
  offset: "10px",
  timeout: 3000,
  position: "bottom center",
  transition: "fade",
  type: "error"
};

try {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
} catch (err) {
  console.log("localStorage err");
  console.log(err.message);
  store.dispatch(setAlert("The cookies have been disabled on your browser. Please enable it.", "error"));
}

class App extends Component {
  componentDidMount() {
    AOS.init({
      duration: 2000
    });
    store.dispatch(loadUser());
  }

  render() {
    if(browser && browser.name == "ie") {
      return (
        <Fragment>
      <div id="home">
        <section
          className=" bodyz"
          style={{ paddingBottom: "0px", paddingTop: "20px" }}
          id="liveassistance"
          data-aos="zoom-in"
          data-aos-offset="220"
          data-aos-easing="ease-in-sine"
          data-aos-once="true"
          data-aos-duration="200"
        >
          <div className="container">
            <div className="row justify-content-center pb-xl-5">
              <div className="col-8 col-sm-8 text-center">
               
                <p className="mt-5 mt-sm-4">
                  Internet Explorer does not support the Byts Platform. Please use other web browsers like Microsoft Edge, Google Chrome, Mozilla Firefox, Opera or Safari.
                  </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
      )
    }
    return (
      <AlertProvider template={AlertTemplate}>
        <AlertProvider
          template={AlertTemplate}
          position={positions.TOP_RIGHT} //default position for all alerts without individual position
          {...alertOptions}
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                <Fragment>
                  <ObserverGroup>
                    <Observer.div

                      className="key-wrapper"
                      duration={300}
                      style={{ opacity: 0 }}
                      mount={{ opacity: 1 }}
                      unmount={{ opacity: 0 }}
                      easing={'easeOutQuad'}
                    >
                      <Switch>
                        <Route exact path="/" component={Landing} />
                        <Route component={Routes} />
                      </Switch>
                    </Observer.div>
                  </ObserverGroup>
                  <Alert />
                </Fragment>
              </Router>
            </PersistGate>
          </Provider>
        </AlertProvider>
      </AlertProvider>
    );
  }
}

export default App;
