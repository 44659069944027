import uuid from "uuid";
import { SET_ALERT, REMOVE_ALERT, CLEAR_ALL_ALERTS } from "./types";

export const setAlert = (msg, alertType, timeout = 500) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id, valid: true }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const clearAllAlerts = () => dispatch => {
  dispatch({
    type: CLEAR_ALL_ALERTS,
    payload: { id: "" }
  });
};
