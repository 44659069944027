import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Provider as AlertProvider,
  useAlert,
  positions,
  transitions
} from "react-alert";
import React, { useState } from "react";
import { BatteryLoader } from "react-loaders-kit";

const NewLoader = ({ loader: { animating } }) => {
  console.log("ANimating: " + animating);

  const loaderProps = {
    loading: animating,
    size: 35,
    duration: 1,
    color: "#E55D3D"
  };

  return <BatteryLoader {...loaderProps} />;
};

NewLoader.propTypes = {
  loader: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  loader: state.loader
});

export default connect(mapStateToProps)(NewLoader);
