import {
  GET_SCHEMA,
  SCHEMA_ERROR,
  GET_TOPIC,
  TOPIC_ERROR,
  GET_SUBTOPIC,
  SUBTOPIC_ERROR,
  UPDATE_PROGRESS,
  UPDATE_PROGRESS_ERROR,
  GET_PROGRESS,
  GET_PROGRESS_ERROR,
  GET_PROBLEM,
  GET_PROBLEM_ERROR,
  UPDATE_PROBLEM,
  UPDATE_PROBLEM_ERROR,
  RESET_CODE,
  RESET_CODE_ERROR,
  GET_PROBLEMS,
  GET_PROBLEMS_ERROR,
  PROBLEMS_TAGS,
  CLEAR_PROGRESS
} from "../actions/types";

const initialState = {
  schema: null,
  topic: null,
  subtopic: null,
  loading: true,
  loadingTopic: true,
  loadingSubtopic: true,
  loadingProgress: true,
  loadingProblem: true,
  problem: null,
  progress: null,
  problems: null,
  loadingProblems: false,
  error: {}
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_PROGRESS:
      return {
        ...state,
        loadingProgress: true,
        progress: null
      }
    case GET_SCHEMA:
      return {
        ...state,
        schema: { topics: payload.topics },
        progress: { ...payload.progress },
        loading: false
      };

    case SCHEMA_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case GET_TOPIC:
      return {
        ...state,
        topic: payload,
        loadingTopic: false
      };

    case TOPIC_ERROR:
      return {
        ...state,
        error: payload,
        loadingTopic: false
      };

    case GET_PROBLEMS:
      return {
        ...state,
        problems: payload,
        loadingProblems: false,
        problem: null,
        loadingProblem: true
      };

    case GET_PROBLEMS_ERROR:
      return {
        ...state,
        loadingProblems: false
      };
    case PROBLEMS_TAGS:
      return {
        ...state,
        problems: { ...state.problems, selectedTags: payload }
      }
    case GET_SUBTOPIC:
      return {
        ...state,
        subtopic: payload,
        loadingSubtopic: false
      };

    case SUBTOPIC_ERROR:
      return {
        ...state,
        error: payload,
        loadingSubtopic: false
      };

    case UPDATE_PROGRESS:
      // get the state's subtopic
      var assetsToSend;

      if (state.subtopic && state.subtopic.assets) {
        assetsToSend = [];

        state.subtopic.assets.forEach(asset => {
          if (asset.assetId == payload.assetId) {
            assetsToSend.push({ ...asset, finished: true });
          } else {
            assetsToSend.push({ ...asset });
          }
        });
      } else {
        assetsToSend = state.subtopic.assets;
      }

      return {
        ...state,
        subtopic: { ...state.subtopic, assets: assetsToSend }
      };

    case UPDATE_PROGRESS_ERROR:
      return {
        ...state
      };

    case GET_PROGRESS:
      return {
        ...state,
        progress: payload,
        loadingProgress: false
      };

    case GET_PROGRESS_ERROR:
      return {
        ...state,
        error: payload,
        loadingProgress: false
      };

    case GET_PROBLEM:
      return {
        ...state,
        problem: payload,
        loadingProblem: false
      };

    case GET_PROBLEM_ERROR:
      return {
        ...state,
        error: payload,
        loadingProblem: false
      };

    case RESET_CODE:
      return {
        ...state,
        problem: payload,
        loadingProblem: false
      };

    case RESET_CODE_ERROR:
      return {
        ...state,
        error: payload,
        loadingProblem: false
      };

    default:
      return state;
  }
}
