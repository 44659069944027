import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  RESEND_MAIL,
  RESEND_MAIL_ERROR,
  GSIGNIN_SUCCESS,
  GSIGNIN_FAIL,
  GET_NOTIFICATIONS,
  NOTIFICATIONS_ERROR
} from "../actions/types";

var initialState = {
}
try {
  initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    notif: {},
    loadingNotif: true
  };
} catch (err) {
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notif: payload,
        loadingNotif: false
      }
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: payload,
        loadingNotif: false
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
    case GSIGNIN_SUCCESS:
      try {
        localStorage.setItem("token", payload.token);
        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false
        };
      } catch {
        return state;
      }
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
    case GSIGNIN_FAIL:

      console.log("LOGOUT");
      try {
        localStorage.removeItem("token");
      } catch {

      }
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false
      };
    default:
      return state;
  }
}
