import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { Progress } from "reactstrap";
import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";
import {
  Nav,
  NavDropdown,
  Navbar,
  Container,
  Row,
  Column,
} from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CompletedRibbon = () => (
  <RightCornerLargeRibbon backgroundColor="#51a423" color="#f0f0f0">
    Completed
  </RightCornerLargeRibbon>
);

const LockedForRolloutRibbon = () => (
  <RightCornerLargeRibbon backgroundColor="#51a423" color="#f0f0f0">
    Locked
  </RightCornerLargeRibbon>
);

function StatusModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.content}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const SubtopicItem = ({ subtopic }) => {
  const [formData, setFormData] = useState({
    showStatus: false,
  });

  const { showStatus } = formData;

  var progressValue = 0;
  if (subtopic && subtopic.progressCount) {
    progressValue = Math.floor(subtopic.progressCount);
  }

  return (
    <div
      className="containerxx"
      style={{
        paddingBottom: "50px",
        marginRight: "50px",
        marginLeft: "30px",
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "100%",
        alignItems: "center",
        height: "100%",
      }}
    >
      <StatusModal
        title="Attention"
        content="This module will be unlocked after the live classroom course."
        show={showStatus}
        onHide={() => setFormData({ ...formData, showStatus: false })}
      />

      {progressValue == 100 ? (
        <CompletedRibbon />
      ) : subtopic && subtopic.rollout ? (
        <Fragment></Fragment>
      ) : (
            <Fragment></Fragment>
          )}

      {subtopic && subtopic.rollout ? (
        <RouteLink
          style={{ textDecoration: "none", color: "#000000" }}
          to={{
            pathname: "/learn/" + subtopic.subtopicId + "/root",
            asset: { _id: "" },
          }}
        >
          <div
            className="fdb-box fdb-touch"
            style={{
              height: "100%",
              paddingTop: "30px",
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", borderTop: "solid 0.3125rem #E55D3D"
            }}
          >
            <h2>{subtopic.title}</h2>
            <div>
              <div style={{ height: "10px" }}></div>
              {subtopic.nv && subtopic.nv > 0 ? (
                <h3>{subtopic.nv} Videos</h3>
              ) : (
                  <Fragment></Fragment>
                )}
              {subtopic.nt && subtopic.nt > 0 ? (
                <h3>{subtopic.nt} Textual Content</h3>
              ) : (
                  <Fragment></Fragment>
                )}
              {subtopic.np && subtopic.np > 0 ? (
                <h3>{subtopic.np} Problems</h3>
              ) : (
                  <Fragment></Fragment>
                )}
              {subtopic.nm && subtopic.nm > 0 ? (
                <h3>{subtopic.nm} MCQs</h3>
              ) : (
                  <Fragment></Fragment>
                )}

              <Progress color="danger" value={progressValue}></Progress>
            </div>
          </div>
        </RouteLink>
      ) : (
          <RouteLink
            style={{ textDecoration: "none", color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();

              setFormData({ ...formData, showStatus: true });
            }}
          >
            <div
              className="fdb-box fdb-touch"
              style={{
                height: "100%",
                paddingTop: "30px",
                paddingBottom: "35px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2>{subtopic.title}</h2>
              <div>
                <div style={{ height: "10px" }}></div>
                {subtopic.nv && subtopic.nv > 0 ? (
                  <h3>{subtopic.nv} Videos</h3>
                ) : (
                    <Fragment></Fragment>
                  )}
                {subtopic.nt && subtopic.nt > 0 ? (
                  <h3>{subtopic.nt} Textual Content</h3>
                ) : (
                    <Fragment></Fragment>
                  )}
                {subtopic.np && subtopic.np > 0 ? (
                  <h3>{subtopic.np} Problems</h3>
                ) : (
                    <Fragment></Fragment>
                  )}
                {subtopic.nm && subtopic.nm > 0 ? (
                  <h3>{subtopic.nm} MCQs</h3>
                ) : (
                    <Fragment></Fragment>
                  )}

                <Progress value={progressValue}></Progress>
              </div>
            </div>
          </RouteLink>
        )
      }
    </div >
  );
};

SubtopicItem.defaultProps = {
  showActions: true,
};

SubtopicItem.propTypes = {
  schema: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  subtopic: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  schema: state.schema,
});

export default connect(mapStateToProps, {})(SubtopicItem);
