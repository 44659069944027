import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { loadUser } from "../../actions/auth";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";
import { Nav, NavDropdown, Navbar, Card, ListGroup } from "react-bootstrap";
import { setAlert } from "../../actions/alert";
import { Dropdown } from 'semantic-ui-react'
import Avatar from 'react-avatar';
import { Container, Row, Col, Badge } from "react-bootstrap";

const bytsColor = "#E55D3D";

const PaymentSucceeded = ({
  deleteAccount,
  auth: { user },
  payment: { order_id },
  schema: { schema, loading },
  payment: { order, loadingOrder },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  loadUser,
  match,
  setAlert
}) => {


  const [formData, setFormData] = useState({
    payment_amount: 0,
    refund_id: "",
    selectedPlan: -1,
    activePlan: 0,
    paymentSucceeded: false

  });

  const { payment_amount, refund_id, selectedPlan,
    activePlan, paymentSucceeded } = formData;

  useEffect(() => {
    loadUser();


  }, [loadUser]);

  const handleBasicClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 1 });
  }

  const handleIntensiveClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 2 });

  }




  var basicStyle = { backgroundColor: "#f2f6ff", height: "600px" };
  var intensiveStyle = { backgroundColor: "#f2f6ff", height: "600px" };
  if (selectedPlan === 1) {
    basicStyle = { backgroundColor: "#D9E4FF", height: "600px" };
  } else if (selectedPlan === 2) {
    intensiveStyle = { backgroundColor: "#D9E4FF", height: "600px" };
  }

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  var planTitle = "";
  var planContents = [];

  if (order && order.planInfo && order.planInfo.title && order.planInfo.contents) {
    planTitle = order.planInfo.title;
    planContents = order.planInfo.contents;
  }

  return !order ? <Redirect to='/dashboard' /> : (
    <div id="home">

      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{
          paddingTop: "20px",
          paddingBottom: "20px",
        }} >
          <Col >
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>


            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav" >

            <Col style={{ paddingLeft: "0px" }}>

              <Nav className="justify-content-center">
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="/learn" >Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>

              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav style={{ paddingTop: "0px" }} >
                <Nav.Link style={{ marginRight: "5px", paddingTop: "4px" }} href="/notifications">Notifications
                  {user && user.notif ?
                    <Badge style={{ marginLeft: "5px" }} variant="danger">New</Badge>
                    : <Fragment></Fragment>
                  }
                </Nav.Link>

                <Avatar name={userName} color={userColour} round={true} size={30} style={{ marginRight: "10px" }} />

                <Dropdown style={{ marginRight: "0px", paddingTop: "4px" }} direction="left" text={userName}>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/myaccount" style={{ textDecoration: "none", color: bytsColor }}>My Account</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle style={{ marginRight: "0px" }} aria-controls="responsive-navbar-nav" />

        </Navbar>

      </section >


      <br />
      <br />

      <section
        className=" bodyz"
        style={{ paddingBottom: "0px", paddingTop: "20px", paddingRight: "40px" }}

      >
        <div className="container">
          <div className="row justify-content-center pb-xl-5">
            <div className="col-8 col-sm-8 text-center">
              <img
                alt="image"
                className="img-fluid mt-5"
                src="/imgs/draws/payment_successful.svg"
                style={{ height: "300px", width: "auto", paddingBottom: "20px" }}
              />

              {planTitle && planTitle.length > 0 && planContents && planContents.length > 0
                ?
                (
                  <div>
                    <h2>Your purchase was successful!</h2>
                    <p className="mt-auto">
                      <Card style={{ width: '100%' }}>
                        <Card.Header>{planTitle}</Card.Header>
                        <ListGroup variant="flush">
                          {planContents.map(planContent => (
                            <ListGroup.Item>{planContent}</ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Card>
                    </p>
                  </div>
                )
                :
                (
                  <h2>You have successfully purchased the plan!</h2>
                )
              }

              <p className="mt-5 mt-sm-4">
                <a className="btn btn-secondary" href="/">
                  Start Enjoying Byts
                  </a>
              </p>
            </div>
          </div>
        </div>
      </section><br />
    </div>
  );
};

PaymentSucceeded.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
  payment: state.payment
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  loadUser,
  setAlert,
  logout
})(PaymentSucceeded);
