import React, { Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout } from "../../actions/auth";
import "./css/dsl.css";
import { useHistory } from "react-router-dom";
import TopicItem from "./TopicItem";
import { getCurrentSchema } from "../../actions/schema";
import Button from "react-bootstrap/Button";
import { setAlert } from "../../actions/alert";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Avatar from "react-avatar";
import { Container, Row, Col, Badge } from "react-bootstrap";

const bytsColor = "#E55D3D";
const Learn = ({
  getCurrentSchema,
  auth: { user },
  schema: { schema, progress, loading },
  setAlert,
  logout,
  history,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentSchema();
  }, [getCurrentSchema]);

  const onClickLearn = async (e) => {
    e.preventDefault();
    console.log("onClickLearn!");
  };

  const onClickPractise = async (e) => {
    e.preventDefault();
    console.log("onClickPractise!");
  };

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  return loading && schema === null ? (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("onClickBack!");

                    history.push("/dashboard");
                  }}
                  className="far fa-arrow-alt-circle-left fa-2x"
                ></i>
              </Navbar.Collapse>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Col style={{ paddingLeft: "0px" }}>
              <Nav className="justify-content-center">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/learn" style={{ color: bytsColor }}>
                  Learn
                </Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>
              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav style={{ paddingTop: "0px" }}>
                <Nav.Link
                  style={{ marginRight: "5px", paddingTop: "4px" }}
                  href="/notifications"
                >
                  Notifications
                </Nav.Link>

                <Avatar
                  name={userName}
                  color={userColour}
                  round={true}
                  size={30}
                  style={{ marginRight: "10px" }}
                />

                <Dropdown
                  style={{ marginRight: "0px", paddingTop: "4px" }}
                  direction="left"
                  text={userName}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/myaccount"
                      style={{ textDecoration: "none" }}
                    >
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>
      <br />
      <br />
      <Spinner />
    </div>
  ) : (
      <div>
        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="light"
            variant="light"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Col>
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("onClickBack!");

                      history.push("/dashboard");
                    }}
                    className="far fa-arrow-alt-circle-left fa-2x"
                  ></i>
                </Navbar.Collapse>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Col style={{ paddingLeft: "0px" }}>
                <Nav className="justify-content-center">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/learn" style={{ color: bytsColor }}>
                    Learn
                </Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>
                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav style={{ paddingTop: "0px" }}>
                  <Nav.Link
                    style={{ marginRight: "5px", paddingTop: "4px" }}
                    href="/notifications"
                  >
                    Notifications
                  {user && user.notif ? (
                      <Badge style={{ marginLeft: "5px" }} variant="danger">
                        New
                      </Badge>
                    ) : (
                        <Fragment></Fragment>
                      )}
                  </Nav.Link>

                  <Avatar
                    name={userName}
                    color={userColour}
                    round={true}
                    size={30}
                    style={{ marginRight: "10px" }}
                  />

                  <Dropdown
                    style={{ marginRight: "0px", paddingTop: "4px" }}
                    direction="left"
                    text={userName}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="/myaccount"
                        style={{ textDecoration: "none" }}
                      >
                        My Account
                    </Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle
              style={{ marginRight: "0px" }}
              aria-controls="responsive-navbar-nav"
            />
          </Navbar>
        </section>

        <br />
        <br />

        <div className="bodyz">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="h2">Select a topic to continue learning</p>
                </div>
              </div>

              <div className="row align-items-center pt-2 pt-lg-5">
                <div className="col-12 col-md-8 col-lg-7">
                  {progress && progress.learnLink != null && progress != null ? (
                    <Fragment>
                      {" "}
                      <h2>Continue with what you were learning before!</h2>
                      <p className="lead">{progress.metaLearn}</p>
                      <RouteLink
                        to={progress.learnLink}
                        onClick={() => {
                          console.log("CLICKED");

                          setAlert(
                            "Successfully restored data from your last session.",
                            "success"
                          );
                        }}
                      >
                        <Button variant="outline-secondary">
                          Continue last session
                      </Button>{" "}
                      </RouteLink>
                    </Fragment>
                  ) : (
                      <Fragment>
                        {" "}
                        <h2>Are you ready?</h2>
                        <p className="lead">
                          This is your first time using the Byts tool. We suggest
                          you to start learning with our expert made content and
                          then start practising problems.
                    </p>
                      </Fragment>
                    )}
                </div>

                <div className="col-8 col-md-4 m-auto m-md-0 ml-md-auto pt-5">
                  <img
                    alt="image"
                    className="img-fluid"
                    src="/imgs/draws/git.svg"
                  />
                </div>
              </div>
              <br />
            </div>

            <br />
            <br />
            <br />
          </div>
          <section className="fdb-block" style={{ paddingTop: "20px" }}>
            <div className="col text-center">
              <Container fluid>
                <Row style={{ paddingRight: "65px", paddingLeft: "0px" }}>
                  {schema &&
                    schema.topics &&
                    schema.topics.map((topic) => (
                      <Fragment>
                        <Col
                          className="justify-content-md-center"
                          style={{
                            paddingRight: "15px",
                            paddingLeft: "15px",
                            paddingTop: "3px",
                          }}
                        >
                          <TopicItem key={topic.topicId} topic={topic} />
                        </Col>
                      </Fragment>
                    ))}
                </Row>
              </Container>
            </div>
          </section>
        </div>
      </div>
    );
};

Learn.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  getCurrentSchema: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  schema: state.schema,
});

export default connect(mapStateToProps, {
  getCurrentSchema,
  setAlert,
  logout,
})(withRouter(Learn));
