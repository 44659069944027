import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { logout, resendMail } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import Loader from "../layout/Loader";
import { startLoader, stopLoader } from "../../actions/loader";
import { loadUser } from "../../actions/auth";
import { getOrderId, getPricingPlans } from "../../actions/payment";
import {
  Link as RouteLink,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { Nav, NavDropdown, Navbar, Row, Col, Badge } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import Avatar from "react-avatar";
import PricingPlanItem from "./PricingPlanItem";
import { Container } from "reactstrap";

import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";

const bytsColor = "#E55D3D";

const Ribbon = () => (
  <RightCornerLargeRibbon backgroundColor="#51a423" color="#ffffff">
    Current Plan
  </RightCornerLargeRibbon>
);

const Payment = ({
  deleteAccount,
  auth: { user },
  payment: { order_id },
  schema: { schema, loading },
  payment: { order, loadingOrder, pricingPlans, loadingPricingPlans },
  logout,
  startLoader,
  stopLoader,
  resendMail,
  loadUser,
  history,
  getPricingPlans,
}) => {
  const [formData, setFormData] = useState({
    payment_amount: 0,
    refund_id: "",
    selectedPlan: -1,
    activePlan: 0,
  });

  const { payment_amount, refund_id, selectedPlan, activePlan } = formData;

  useEffect(() => {
    //loadUser();
    getPricingPlans();
  }, [getPricingPlans]);

  const handleBasicClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 1 });
  };

  const handleIntensiveClick = (e) => {
    e.preventDefault();

    setFormData({ ...formData, selectedPlan: 2 });
  };

  const refundHandler = (e) => {
    e.preventDefault();

    const { refund_id } = this.state;
    const url = process.env.URL + "/api/v1/rzp_refunds/" + refund_id;

    // Using my server endpoints to initiate the refund
    fetch(url, {
      method: "get",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("Request succeeded with JSON response", data);
        alert("Refund Succeeded");
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const onClickMyAccount = async (e) => {
    e.preventDefault();
    console.log("onClickMyAccount!");
  };

  const onClickPractise = async (e) => {
    e.preventDefault();
    console.log("onClickPractise!");
  };

  var basicStyle = { backgroundColor: "#f2f6ff", height: "600px" };
  var intensiveStyle = { backgroundColor: "#f2f6ff", height: "600px" };
  if (selectedPlan === 1) {
    basicStyle = { backgroundColor: "#D9E4FF", height: "600px" };
  } else if (selectedPlan === 2) {
    intensiveStyle = { backgroundColor: "#D9E4FF", height: "600px" };
  }

  var userName = user && user.sUserName ? user.sUserName : "My Account  ";
  var userColour = user && user.userColor ? user.userColor : "#007bff";

  var plan1Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan2Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan3Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan4Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan5Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan6Data = { planId: "NA", contents: [], title: "NA", price: "NA" };
  var plan7Data = { planId: "NA", contents: [], title: "NA", price: "NA" };

  var ownedPlans = [];

  if (
    !loadingPricingPlans &&
    pricingPlans &&
    pricingPlans.plans != null &&
    pricingPlans.plans !== {}
  ) {
    plan1Data = pricingPlans.plans["plan1"];
    plan2Data = pricingPlans.plans["plan2"];
    plan3Data = pricingPlans.plans["plan3"];
    plan4Data = pricingPlans.plans["plan4"];
    plan5Data = pricingPlans.plans["plan5"];
    plan6Data = pricingPlans.plans["plan6"];
    plan7Data = pricingPlans.plans["plan7"];

    if (user && user.activePlans) {
      user.activePlans.forEach((activePlan) => {
        var reqPlanData = pricingPlans.plans[activePlan.planId];
        if (reqPlanData) {
          if (reqPlanData.contents) {
            reqPlanData.contents.forEach((content) => {
              ownedPlans.push(content);
            });
          }
        }
      });
    }
  }

  return loadingPricingPlans || pricingPlans == null ? (
    <div>
      <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <Row style={{ paddingLeft: "0px" }}>
              <Navbar.Brand href="/">
                <img src="/imgs/bytsLogo.png" height="40" alt="image" />
              </Navbar.Brand>
              <Navbar.Collapse id="responsive-navbar-nav">
                <i
                  style={{ paddingTop: "3px", paddingLeft: "0px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("onClickBack!");
                    history.push("/myaccount");
                  }}
                  className="far fa-arrow-alt-circle-left fa-2x"
                ></i>
              </Navbar.Collapse>
            </Row>
          </Col>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Col style={{ paddingLeft: "0px" }}>
              <Nav className="justify-content-center">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/learn">Learn</Nav.Link>
                <Nav.Link href="/practise">Practise</Nav.Link>
                <Nav.Link href="/support">Support</Nav.Link>
              </Nav>
            </Col>
          </Navbar.Collapse>

          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav style={{ paddingTop: "0px" }}>
                <Nav.Link
                  style={{ marginRight: "5px", paddingTop: "4px" }}
                  href="/notifications"
                >
                  Notifications
                </Nav.Link>

                <Avatar
                  name={userName}
                  color={userColour}
                  round={true}
                  size={30}
                  style={{ marginRight: "10px" }}
                />

                <Dropdown
                  style={{ marginRight: "0px", paddingTop: "4px" }}
                  direction="left"
                  text={userName}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/myaccount"
                      style={{ textDecoration: "none", color: bytsColor }}
                    >
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Col>

          <Navbar.Toggle
            style={{ marginRight: "0px" }}
            aria-controls="responsive-navbar-nav"
          />
        </Navbar>
      </section>

      <br />
      <br />
      <div className="bodyz">
        <div class="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="h2">Pricing Plans</p>
              </div>
            </div>
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  ) : (
      <div>
        <section className="p-0 shadow" id="navigator" style={{ zIndex: "100" }}>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="light"
            variant="light"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Col>
              <Row style={{ paddingLeft: "0px" }}>
                <Navbar.Brand href="/">
                  <img src="/imgs/bytsLogo.png" height="40" alt="image" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <i
                    style={{ paddingTop: "3px", paddingLeft: "0px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("onClickBack!");
                      history.push("/myaccount");
                    }}
                    className="far fa-arrow-alt-circle-left fa-2x"
                  ></i>
                </Navbar.Collapse>
              </Row>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Col style={{ paddingLeft: "0px" }}>
                <Nav className="justify-content-center">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/learn">Learn</Nav.Link>
                  <Nav.Link href="/practise">Practise</Nav.Link>
                  <Nav.Link href="/support">Support</Nav.Link>
                </Nav>
              </Col>
            </Navbar.Collapse>

            <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav style={{ paddingTop: "0px" }}>
                  <Nav.Link
                    style={{ marginRight: "5px", paddingTop: "4px" }}
                    href="/notifications"
                  >
                    Notifications
                  {user && user.notif ? (
                      <Badge style={{ marginLeft: "5px" }} variant="danger">
                        New
                      </Badge>
                    ) : (
                        <Fragment></Fragment>
                      )}
                  </Nav.Link>

                  <Avatar
                    name={userName}
                    color={userColour}
                    round={true}
                    size={30}
                    style={{ marginRight: "10px" }}
                  />

                  <Dropdown
                    style={{ marginRight: "0px", paddingTop: "4px" }}
                    direction="left"
                    text={userName}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="/myaccount"
                        style={{ textDecoration: "none", color: bytsColor }}
                      >
                        My Account
                    </Dropdown.Item>
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Col>

            <Navbar.Toggle
              style={{ marginRight: "0px" }}
              aria-controls="responsive-navbar-nav"
            />
          </Navbar>
        </section>

        <br />
        <br />

        <div className="">
          <div class="">
            <div className="">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="h2">Pricing Plans</p>
                </div>
              </div>
              <section className="fdb-block" style={{ paddingTop: "60px" }}>
                <div className="col text-center">
                  <Container>
                    <Row style={{ paddingRight: "65px", paddingLeft: "0px" }}>
                      <Col className="justify-content-md-center">
                        <div style={{ height: "33px" }} />

                        <PricingPlanItem
                          key={"6"}
                          largeL={true}
                          pricingPlan={plan6Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                      <Col className="justify-content-md-center">
                        <PricingPlanItem
                          key={"7"}
                          largeM={true}
                          pricingPlan={plan7Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                      <Col className="justify-content-md-center">
                        <div style={{ height: "50px" }} />

                        <PricingPlanItem
                          key={"5"}
                          largeR={true}
                          pricingPlan={plan5Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                    </Row>
                  </Container>

                  <Container fluid>
                    <Row style={{ paddingRight: "65px", paddingLeft: "0px" }}>
                      <Col className="justify-content-md-center">
                        <PricingPlanItem
                          key={"1"}
                          pricingPlan={plan1Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                      <Col className="justify-content-md-center">
                        <PricingPlanItem
                          key={"2"}
                          pricingPlan={plan2Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                      <Col className="justify-content-md-center">
                        <PricingPlanItem
                          key={"3"}
                          pricingPlan={plan3Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                      <Col className="justify-content-md-center">
                        <PricingPlanItem
                          key={"4"}
                          pricingPlan={plan4Data}
                          user={user}
                          ownedPlans={ownedPlans}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </section>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
};

Payment.propTypes = {
  auth: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
  resendMail: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  getPricingPlans: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  schema: state.schema,
  payment: state.payment,
});

export default connect(mapStateToProps, {
  resendMail,
  logout,
  startLoader,
  stopLoader,
  loadUser,
  logout,
  getPricingPlans,
})(Payment);
/*

                <div className="row py-5 align-items-top">

                  <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-left pt-4 pt-lg-0" style={{ paddingRight: "0px", height: "600px" }}>

                    <div className="bg-gray pb-5 pt-5 pl-4 pr-4 rounded shadow" style={{ height: "600px" }}>

                      <h1 className="font-weight-light">Free</h1>
                      <hr />

                      <h1 className="h2">
                        <strong>₹0</strong>
                      </h1>
                      <p className="h5">
                        Access to sample videos and problems to test our product out.
                </p>
                      <hr />
                      <h5 className="text-right">
                        <em>5 Videos</em>
                      </h5>
                      <h5 className="text-right">
                        <em>5 Problems</em>
                      </h5>
                      <p>
                        <br />
                      </p>
                      <p>
                        <br />
                      </p>

                      <div style={{ paddingTop: "45px" }}>
                      </div>
                      {activePlan == 0 ? <Ribbon /> : <p className="text-center pt-4">
                        <a href="" className="btn btn-primary">
                          <b>Downgrade Account</b>{" "}
                        </a>
                      </p>}

                    </div>
                  </div>
                  <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-left pt-4 pt-lg-0" style={{ paddingRight: "0px", height: "600px" }}>


                    <div className=" pb-5 pt-5 pl-4 pr-4 rounded shadow" style={basicStyle}>
                      <h1 className="font-weight-light">Basic</h1>
                      <hr />
                      <p className="h2">
                        <strong>₹200/month</strong>
                      </p>
                      <p className="h5">
                        Our bestselling plan used by most of our students
                </p>
                      <hr />
                      <h5 className="text-right">
                        <em>All Videos</em>
                      </h5>
                      <h5 className="text-right">
                        <em>All Problems</em>
                      </h5>
                      <h5 className="text-right">
                        <em>Unlimited Access to Code Playground</em>
                      </h5>
                      <p>
                        <br />
                      </p>
                      <p>
                        <br />
                      </p>
                      <p style={{ paddingTop: "4px" }}>

                      </p>
                      {activePlan == 1 ? <div> <Ribbon />
                        <p className="text-center pt-3">


                          {selectedPlan === 1 ? (<div>

                          </div>) : (<h4> Expires on<br />
                            <Moment format="DD/MM/YYYY">{moment.utc(user.expiry)}</Moment>
                          </h4>)}
                        </p>
                      </div> : <p className="text-center pt-4">

                          {selectedPlan === 1 ? (<RouteLink className="btn btn-primary" to={{
                            pathname: "/checkout/1",
                          }}>
                            <b>Proceed</b>
                          </RouteLink>) : (<RouteLink className="btn btn-primary" onClick={handleBasicClick}>
                            <b>Choose Plan</b>
                          </RouteLink>)}

                        </p>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-left pt-4 pt-lg-0" style={{ paddingRight: "0px", height: "600px" }}>
                    <div className="pb-5 pt-5 pl-4 pr-4 rounded shadow" style={intensiveStyle}>
                      <h1 className="font-weight-light">Intensive</h1>
                      <hr />
                      <p className="h2">
                        <strong>₹300/month</strong>
                      </p>
                      <p className="h5">
                        For students who wish to get placed in a product based company
                        within 3 months
                </p>
                      <hr />
                      <h5 className="text-right">
                        <em>All Videos</em>
                      </h5>
                      <h5 className="text-right">
                        <em>All Problems</em>
                      </h5>
                      <h5 className="text-right">
                        <em>Unlimited Access to Code Playground</em>
                      </h5>
                      <h5 className="text-right">
                        <em>Courses on latest technologies</em>
                      </h5>
                      <h5 className="text-right">
                        <em>Live Assistance</em>
                      </h5>
                      <p>
                        <br />
                      </p>
                      {activePlan == 2 ? <div> <Ribbon />
                        <p className="text-center pt-3">

                          {selectedPlan === 2 ? (<div>

                          </div>) : (<h4> Expires on<br />
                            <Moment format="DD/MM/YYYY">{moment.utc(user.expiry)}</Moment>
                          </h4>)}
                        </p>
                      </div> : <p className="text-center pt-4">

                          {selectedPlan === 2 ? (<RouteLink className="btn btn-primary" to={{
                            pathname: "/checkout/2",
                          }}>
                            <b>Proceed</b>
                          </RouteLink>) : (<RouteLink className="btn btn-primary" onClick={handleIntensiveClick}>
                            <b>Choose Plan</b>
                          </RouteLink>)}

                        </p>}
                    </div>
                  </div>{" "}
                </div>
                <div className="col-12 col-sm-10 col-md-8 m-auto col-lg-4 text-left pt-4 pt-lg-0" style={{ paddingRight: "0px", height: "600px" }}>
                  <div className="pb-5 pt-5 pl-4 pr-4 rounded shadow" style={intensiveStyle}>
                    <h1 className="font-weight-light">Intensive</h1>
                    <hr />
                    <p className="h2">
                      <strong>₹300/month</strong>
                    </p>
                    <p className="h5">
                      For students who wish to get placed in a product based company
                      within 3 months
                </p>
                    <hr />
                    <h5 className="text-right">
                      <em>All Videos</em>
                    </h5>
                    <h5 className="text-right">
                      <em>All Problems</em>
                    </h5>
                    <h5 className="text-right">
                      <em>Unlimited Access to Code Playground</em>
                    </h5>
                    <h5 className="text-right">
                      <em>Courses on latest technologies</em>
                    </h5>
                    <h5 className="text-right">
                      <em>Live Assistance</em>
                    </h5>
                    <p>
                      <br />
                    </p>
                    {activePlan == 2 ? <div> <Ribbon />
                      <p className="text-center pt-3">

                        {selectedPlan === 2 ? (<div>

                        </div>) : (<h4> Expires on<br />
                          <Moment format="DD/MM/YYYY">{moment.utc(user.expiry)}</Moment>
                        </h4>)}
                      </p>
                    </div> : <p className="text-center pt-4">

                        {selectedPlan === 2 ? (<RouteLink className="btn btn-primary" to={{
                          pathname: "/checkout/2",
                        }}>
                          <b>Proceed</b>
                        </RouteLink>) : (<RouteLink className="btn btn-primary" onClick={handleIntensiveClick}>
                          <b>Choose Plan</b>
                        </RouteLink>)}

                      </p>}
                  </div>
                </div>{" "}


*/
